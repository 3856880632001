import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from "./messageSlice";

export const getauditNamesList = (pageindex, pagesize) => async dispatch => {

    await axios.get(process.env.REACT_APP_URL.concat(`getAuditNamesList/${pageindex}/${pagesize}`), { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data.data;
            if (res.data.status === true) {
                dispatch(setTotalauditNamesCount(x.TotalCount));
                return dispatch(auditNamesListSuccess(x.AuditNamesList));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
            return dispatch(ListError([]));
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            return dispatch(ListError([]));
        });

};

export const getAuditTypesList = () => async dispatch => {

    await axios.get(process.env.REACT_APP_URL.concat("getAuditTypesList"), { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data;
            if (x.status === true) {
                return dispatch(auditTypeSuccess(x.audittypeslist));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            return dispatch(auditTypeListError([]));
        });

};



export const saveauditNames = (data) => async dispatch => {

    var URL = "";
    //console.log("in save ques");
    //console.log(data);
    if (data._id === "0" || data._id === "") {
        URL = process.env.REACT_APP_URL.concat('auditcreate');
    }
    else {
        URL = process.env.REACT_APP_URL.concat("auditnameedit");
    }
    await axios.post(URL, data, { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(res => {
            let x = res.data


            if (x.status === true) {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'success'
                }));
                // getauditNamesList();
                return dispatch(auditNameDataSuccess(x.savedauditNames));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
        }
        ).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            return dispatch(auditNameDataSuccess([]));
        });;
};
export const searchauditNames = (data) => async dispatch => {

    await axios.post(process.env.REACT_APP_URL.concat('searchAuditName'), data, { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {
            let x = await res.data.data;
            if (res.data.status === true) {
                dispatch(setTotalauditNamesCount(x.TotalCount));
                return dispatch(auditNamesListSuccess(x.AuditNamesList));
            }
            else {
                dispatch(showMessage({
                    message: res.data.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
            return dispatch(ListError([]));
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            return dispatch(ListError([]));
        });
};

export const deleteauditNames = (id) => async dispatch => {

    await axios.get(process.env.REACT_APP_URL.concat(`auditnamedelete/${id}`), { withCredentials: true })
        .then(res => {
            let x = res.data;    // 
            if (x.status === true) {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'success'
                }));
                // getauditNamesList();
                return dispatch(auditNameDataSuccess(x.savedauditNames));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
        }
        );
};

const initialState = {
    auditNamessuccess: false,
    audittypesuccess: false,
    auditNamessData: [],
    auditTypeData: [],
    auditNames: [],
};

const auditNameSlice = createSlice({
    name: 'auditnames',
    initialState,
    reducers: {
        auditNamesListSuccess: (state, action) => {
            state.auditNamessuccess = true;
            state.auditNamessData = action.payload;
        },
        ListError: (state, action) => {
            state.auditNamessuccess = false;
            state.auditNamessData = [];
        },
        auditTypeSuccess: (state, action) => {
            state.audittypesuccess = true;
            state.auditTypeData = action.payload;
        },
        auditTypeListError: (state, action) => {
            state.audittypesuccess = false;
            state.auditTypeData = [];
        },
        auditNameDataSuccess: (state, action) => {
            state.auditNamessuccess = true;
            state.auditNames = action.payload;
        },
        setTotalauditNamesCount: (state, action) => {
            state.auditNamessuccess = true;
            state.TotalCount = action.payload;
        }
    }
})
export const {
    auditNamesListSuccess,
    ListError,
    auditTypeSuccess,
    auditTypeListError,
    auditNameDataSuccess,
    setTotalauditNamesCount
} = auditNameSlice.actions;


export default auditNameSlice.reducer;