import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from "../store/messageSlice";

export const getSummaryReport = (data) => async dispatch => {
    dispatch(setLoading(true));
    debugger;
    await axios.post(process.env.REACT_APP_URL.concat('getSummaryReportData'), data, { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {
            let x = await res.data.data;
            if (res.data.status === true) {
                dispatch(setTotalCount(x.TotalCount));
                // Calculate percentages for each survey
                let surveyListWithPercentages = {};

                if (x.reportData) {
                    surveyListWithPercentages = x.reportData.map(survey => {
                        const sectionQuestionLists = survey.sectionquestionlist;
                        const surveyWithPercentages = { ...survey, sectionPercentages: [] };

                        let alltotalEarnedPoints = 0; // Track total earned points across all sections
                        let alltotalPossiblePoints = 0; // Track total possible points across all sections

                        sectionQuestionLists.forEach(sectionQuestionList => {
                            const sectionName = sectionQuestionList.sectionname;
                            const score = sectionQuestionList.score;
                            const sectionQuestions = sectionQuestionList.questions;

                            // Calculate earned points and total possible points for the section
                            let totalPossiblePoints = 0;
                            let earnedPoints = 0;


                            sectionQuestions.forEach(question => {
                                if (question.meettype !== "na") {
                                    totalPossiblePoints += parseFloat(question.points);
                                    if (question.meettype === "yes") {

                                        earnedPoints += parseFloat(question.points);
                                    }
                                    if (question.meettype === "partiallymeets") {
                                        earnedPoints += (parseInt(question.points, 10) / 2);
                                    }
                                }
                                // if (question.meettype === "yes") {

                                //     earnedPoints += parseFloat(question.points);
                                // }
                                // if (question.meettype === "partiallymeets") {
                                //     earnedPoints += (parseInt(question.points, 10) / 2);
                                // }
                            });

                            // Calculate section percentage
                            //if()
                            const sectionPercentage = (earnedPoints / totalPossiblePoints) * 100 || 0;
                            // if (score != 0) {  // this condition added for to avoid calculate section having all 'na' questions
                            surveyWithPercentages.sectionPercentages.push({ sectionName, sectionPercentage });
                            //}
                            // Add section points to the total points
                            alltotalEarnedPoints += earnedPoints;
                            alltotalPossiblePoints += totalPossiblePoints;
                        });

                        // Calculate Overall Percentage
                        const overallPercentage = (alltotalEarnedPoints / alltotalPossiblePoints) * 100 || 0;
                        surveyWithPercentages.sectionPercentages.push({ sectionName: 'Overall Percentage', sectionPercentage: overallPercentage });


                        return surveyWithPercentages;
                    });


                }
                const sectionarray = await getReportSectionsList(x.reportData);

                dispatch(reportSectionList(sectionarray));
                dispatch(setLoading(false));
                return dispatch(summaryReportSuccess(surveyListWithPercentages));
            }
            else {
                dispatch(showMessage({
                    message: res.data.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
            dispatch(setLoading(false));
            return dispatch(summaryReportError([]));
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            dispatch(setLoading(false));
            return dispatch(summaryReportError([]));
        });
};
export const getOverallAverage = (data) => async dispatch => {
    dispatch(setLoading(true));
    await axios.post(process.env.REACT_APP_URL.concat('getOverallAverageReportData'), data, { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {
            let x = await res.data.data;
            if (res.data.status === true) {
                dispatch(setTotalCount(x.TotalCount));
                // Calculate percentages for each survey
                let sectionPointList = {};
                let totalsectionwisepoints = {};
                let TotalCriticalsections = 0;
                let processedData = {};
                if (x.reportData) {
                    sectionPointList = SurveyAnalysis(x.reportData);
                    totalsectionwisepoints = calculateSectionTotalPoints(sectionPointList);
                    processedData = totalsectionwisepoints.map((dataPoint) => {
                        let isCoverage = false;
                        if (dataPoint.sectionname === 'Overall') {
                            isCoverage = true;
                        }
                        console.log(`Section: ${dataPoint.sectionname}, isCoverage: ${isCoverage}`);
                        return {
                            ...dataPoint,
                            fill: isCoverage ? 'red' : '#1976d2',
                        };
                    });

                    TotalCriticalsections = getTotalCriticalSections(x.reportData);
                }

                dispatch(setLoading(false));
                dispatch(setTotalCriticalSections(TotalCriticalsections));
                debugger;
                return dispatch(overallAvarageReportSuccess(processedData));
            }
            else {
                dispatch(showMessage({
                    message: res.data.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
            dispatch(setLoading(false));
            return dispatch(overallAvarageReportError([]));
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            dispatch(setLoading(false));
            return dispatch(overallAvarageReportError([]));
        });
};

export const getExaminernames = () => async dispatch => {

    await axios.get(process.env.REACT_APP_URL.concat('getExaminernames'), { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {
            let x = await res.data.data;
            if (res.data.status === true) {
                if (x.list) {
                    return dispatch(adjusterListSuccess(x.list));
                }
            }
            else {
                dispatch(showMessage({
                    message: res.data.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            return dispatch(adjusterListError([]));
        });
};
// Function to calculate percentage for summary report
// function calculatePercentage(sectionQuestions) {
//     const totalQuestions = sectionQuestions.length;
//     const totalYesScore = sectionQuestions.reduce((total, question) => {
//         if (question.meettype === 'yes') {
//             return total + parseFloat(question.point);
//         }
//         return total;
//     }, 0);

//     const percentage = parseFloat((totalYesScore / (totalQuestions * 100)) * 100);
//     return percentage;
// }
/**functions for overall average report */

function calculateSectionPoints(questions) {
    let earnedPoints = 0;
    let possiblePoints = 0;

    questions.forEach((question) => {
        if (question.meettype === "yes") {
            earnedPoints += parseInt(question.points, 10);
        }
        if (question.meettype === "partiallymeets") {
            earnedPoints += (parseInt(question.points, 10) / 2);
        }
        if (question.meettype !== "na") {
            possiblePoints += parseInt(question.points, 10);
        }
    });

    return { earnedPoints, possiblePoints };
}

function SurveyAnalysis(surveyArray) {
    const sectionPointList = surveyArray.map((survey) => {
        const sectionPoints = survey.sectionquestionlist.map((section) => {
            const { earnedPoints, possiblePoints } = calculateSectionPoints(section.questions);
            return {
                sectionname: section.sectionname,
                earnedPoints,
                possiblePoints,
            };
        });

        return {
            _id: survey._id,
            sectionPoints,
        };
    });
    return sectionPointList;
}
function getTotalCriticalSections(surveyArray) {
    let total = 0;
    surveyArray.forEach((survey, surveyIndex) => {
        survey.sectionquestionlist.forEach((section, sectionIndex) => {
            if (section.criticalstatus === true) {
                // console.log(`Found critical section in survey ${surveyIndex}, section ${sectionIndex}`);
                total = total + 1;
            }
        });
    });
    return total;
}

function calculateSectionTotalPoints(sectionPointList) {
    const totalEarnedPointsSectionWise = {};
    const totalPossiblePointsSectionWise = {};


    sectionPointList.forEach((survey) => {
        survey.sectionPoints.forEach((section) => {
            totalEarnedPointsSectionWise[section.sectionname] = (totalEarnedPointsSectionWise[section.sectionname] || 0) + section.earnedPoints;
            totalPossiblePointsSectionWise[section.sectionname] = (totalPossiblePointsSectionWise[section.sectionname] || 0) + section.possiblePoints;
        });
    });

    // return Object.keys(totalEarnedPointsSectionWise).map((sectionname) => ({
    //     sectionname,
    //     totalEarnedPoints: totalEarnedPointsSectionWise[sectionname],
    //     totalPossiblePoints: totalPossiblePointsSectionWise[sectionname],
    //     percentage: (totalEarnedPointsSectionWise[sectionname] / totalPossiblePointsSectionWise[sectionname]) * 100,

    // }));
    const sectionData = Object.keys(totalEarnedPointsSectionWise).map((sectionname) => ({
        sectionname,
        totalEarnedPoints: totalEarnedPointsSectionWise[sectionname],
        totalPossiblePoints: totalPossiblePointsSectionWise[sectionname],
        percentage: (totalEarnedPointsSectionWise[sectionname] / totalPossiblePointsSectionWise[sectionname]) * 100,
    }));

    // Calculate the overall total earned and total possible points
    const overallTotalEarned = sectionData.reduce((total, section) => total + section.totalEarnedPoints, 0);
    const overallTotalPossible = sectionData.reduce((total, section) => total + section.totalPossiblePoints, 0);

    // Calculate the overall percentage
    const overallPercentage = (overallTotalEarned / overallTotalPossible) * 100;
    // Sort the sectionData array by sectionname in ascending order
    sectionData.sort((a, b) => {
        // Use localeCompare for string comparison to ensure correct ordering
        return a.sectionname.localeCompare(b.sectionname);
    });
    // Add the 'Overall' section to the array
    const processedData = [{
        sectionname: 'Overall',
        totalEarnedPoints: overallTotalEarned,
        totalPossiblePoints: overallTotalPossible,
        percentage: overallPercentage,
    }, ...sectionData,];

    return processedData;

    // Now you can use processedData to render your chart

}
const getReportSectionsList = async (surveys) => {
    const distinctSections = [];
    // Add "Overall Percentage" section with the generated ID to the distinctSections array
    distinctSections.push({
        sectionid: "overallPercentageSectionId",
        section: "Overall Percentage"
    });
    surveys.forEach(survey => {
        survey.sectionquestionlist.forEach(section => {
            const existingSection = distinctSections.find(item => item.section === section.sectionname);
            if (!existingSection) {
                distinctSections.push({
                    sectionid: section._id,
                    section: section.sectionname
                });
            }
        });
    });
    // Sort the sectionArray by section name
    //  distinctSections.sort((a, b) => a.section.localeCompare(b.section));

    return distinctSections;
}

const initialState = {
    getReportData: false,
    summaryReportData: [],
    overallAvarageReportData: [],
    adjusterlist: [],
    isloading: false,
    reportSectionlist: [],
    TotalCriticalSections: 0
};

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        summaryReportSuccess: (state, action) => {
            state.getReportData = true;

            state.summaryReportData = action.payload;
        },
        summaryReportError: (state, action) => {
            state.getReportData = false;
            state.summaryReportData = [];
        },
        overallAvarageReportSuccess: (state, action) => {
            state.getReportData = true;
            state.summaryReportData = action.payload;
        },
        overallAvarageReportError: (state, action) => {
            state.getReportData = false;
            state.summaryReportData = [];
        },
        setTotalCount: (state, action) => {
            state.TotalCount = action.payload;
        },
        setTotalCriticalSections: (state, action) => {
            state.TotalCriticalSections = action.payload;
        },
        adjusterListSuccess: (state, action) => {
            state.adjusterlist = action.payload;
        },
        adjusterListError: (state, action) => {
            state.adjusterlist = [];
        },
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
        reportSectionList: (state, action) => {
            state.reportSectionlist = action.payload;
        }
    }
})
export const {
    summaryReportSuccess,
    setTotalCount,
    summaryReportError,
    overallAvarageReportSuccess,
    overallAvarageReportError,
    adjusterListSuccess,
    adjusterListError,
    setLoading,
    reportSectionList,
    setTotalCriticalSections
} = reportsSlice.actions;


export default reportsSlice.reducer;