import { combineReducers } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit'
import users from './userSlice';
import questions from './questionSlice';
import sections from './sectionSlice';
import surveys from './surveySlice';
import loginuser from './loginSlice';
import message from './messageSlice';
import auditsection from './auditSectionSlice';
import audittypewithSections from './auditTypeSlice1';
import reports from './reportsSlice';
import auditnames from './auditNameSlice';

// const dispatchCallsReducers = combineReducers({
//     users

// });
// export default dispatchCallsReducers;


// const createReducer = asyncReducers => (state, action) => {
//     const combinedReducer = combineReducers({
//         users,
//         ...asyncReducers
//     });

//     return combinedReducer(state, action);
// };

// export default createReducer;

const reducer = combineReducers({
    users,
    sections,
    surveys,
    questions,
    loginuser,
    message,
    auditsection,
    audittypewithSections,
    reports,
    auditnames
    // here we will be adding reducers
})
const store = configureStore({
    reducer,
})
export default store;