import React, { useEffect, useState } from 'react';
import Dashboard from "../../Dashboard"
import axios from "axios"
import { Select, MenuItem, InputLabel, FormControl, FormControlLabel, Checkbox } from '@mui/material';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom"
import { useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { getUser, getRoles, saveUser } from '../../store/userSlice';
import Navbar from "../../Navigation/Navbar";

function UserCreate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams()
  const id = params.userid.toString();
  const userdata = useSelector(({ users }) => users.usersData);
  const userRoles = useSelector(({ users }) => users.userRoles);
  const [isDisabled, setPassdisabled] = React.useState(false)
  const [defaultChecked, setdefaultChecked] = React.useState(true)
  const [formErrors, setFormErrors] = useState({});
  //const [editUserActive, seteditUserActive] = React.useState(false)
  const [roles, setRoles] = useState(userRoles);
  const [user, setUser] = useState({
    _id: "0",
    name: "",
    email: "",
    role: "",
    active: "",
    password: "",
    cpassword: "",
    heading: "Add User",
    userActivechk: "none",
  })
  function updateForm(value) {

    return setUser((prev) => {
      return { ...prev, ...value };
    });
  }



  useEffect(() => {

    dispatch(getRoles());
    editUserdata();
  }, []);

  async function editUserdata() {

    if (id !== "0") {
      setPassdisabled({ isDisabled: true })
      let userrecord = userdata.filter(userdata => userdata._id === id);
      setUser({
        _id: userrecord[0]._id,
        name: userrecord[0].name,
        email: userrecord[0].email,
        role: userrecord[0].roleId ? userrecord[0].roleId._id : "",
        heading: "Edit User",
        userActivechk: "visible",
      });
      setdefaultChecked(userrecord[0].Active);
      //seteditUserActive('true');
    }
  }

  async function editUser() {

    user.active = defaultChecked;
    if (user.name && user.email && user.role) {
      dispatch(saveUser(user));
    }
  }
  const validateRequiredFields = (values, requiredFields) => {
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!values[field] || values[field].trim() === '') {
        newErrors[field] = 'This field is required';
      }
      if (field == "email") {
        if (!validateEmail(values[field].trim())) {
          newErrors[field] = 'Invalid Email';
        }
      }
    });

    return newErrors;
  };
  const validateEmail = (email) => {
    // Regex pattern for email validation
    const regexPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regexPattern.test(email);
  };
  async function saveUserHandle(e) {

    e.preventDefault();
    if (id === "0") {
      const requiredFields = ["name", "email", "password", "cpassword", "role"];
      const errors = validateRequiredFields(user, requiredFields);
      setFormErrors(errors);
      if (Object.keys(errors).length === 0) {
        setFormErrors({});
        saveUserData();
        navigate("/administarion/users");
      }
    } else {
      const requiredFields = ["name", "email", "role"];
      const errors = validateRequiredFields(user, requiredFields);
      setFormErrors(errors);
      if (Object.keys(errors).length === 0) {
        setFormErrors({});
        editUser();
        navigate("/administarion/users");
      }
    }
  }
  async function saveUserData() {

    if ((user.password === user.cpassword)) {
      dispatch(saveUser(user));
    }
  }

  const handleChange = (event) => {
    setdefaultChecked(event.target.checked);
  };
  return (

    <div>
      {/*<Dashboard />
      <InputLabel id="User Management"
        sx={{
          ml: 20,
          mt: 10,
          mb: 1,
          mr: 1,
          color: 'Black',
          fontFamily: 'Arial',
          fontStyle: 'Bold',
          fontSize: '1.5rem'
        }}
      ></InputLabel>*/}
      <Navbar />

      <Box
        sx={{
          marginTop: 1,
          marginLeft: '15%',
          width: '70vw',
          height: '100%',
          backgroundColor: 'white',
          borderStyle: 'solid',
          borderColor: '#213555',
          borderWidth: 0.5
        }}
      >
        <Box
          sx={{
            mt: 0,
            width: '100%',
            height: 40,
            backgroundColor: '#22A699',
            borderStyle: '',
          }}
        >
          <Grid container>
            <Grid item>
              {/* <SearchOutlinedIcon fontSize="inherit"
                sx={{
                  mt: 1,
                }}
              /> */}
            </Grid>
            <Grid item><InputLabel id="adduser"
              sx={{
                ml: 1,
                mt: 1,
                color: 'white',
              }}
            >{user.heading}</InputLabel>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            marginTop: 0,
            width: '100%',
            height: '100%',
            borderBlockColor: 'black',
            backgroundColor: 'white',
            display: "flex",
            flexDirection: "column",
          }}

        >
          <form autoComplete="off" noValidate="true">
            <Grid container sx={{ mt: 2, ml: 1 }}>
              <Grid item xs lg>
                <TextField
                  label="Name"
                  placeholder='Enter Name'
                  required
                  variant="outlined"
                  color="primary"
                  type="text"
                  name="name"
                  value={user.name}
                  onChange={(e) => updateForm({ name: e.target.value })}
                  sx={{
                    mb: 3,
                    width: '33vw',
                    height: 50
                  }}
                  error={!!formErrors.name}
                />
              </Grid>
              <Grid item xs lg sx={{ mr: 1 }}>

                <TextField
                  label="Email"
                  placeholder='Enter Email'
                  required
                  variant="outlined"
                  color="primary"
                  type="text"
                  name="email"
                  value={user.email}
                  onChange={(e) => updateForm({ email: e.target.value })}
                  sx={{
                    mb: 3,
                    width: '33vw',
                    height: 50
                  }}
                  error={!!formErrors.email}
                  helperText={formErrors.email || ''}
                />
              </Grid>
            </Grid>
            { id === "0" && (
            <Grid container sx={{ mt: 2, ml: 1 }}>
              <Grid item xs lg>
                <TextField
                  label="Password"
                  placeholder='Enter Password'
                  required
                  variant="outlined"
                  color="primary"
                  type="password"
                  disabled={isDisabled}
                  value={user.password}
                  onChange={(e) => updateForm({ password: e.target.value })}
                  inputProps={{
                    minLength: 8, // Set the minimum length to 8 characters
                    maxLength: 10, // Set the maximum length to 10 characters
                  }}
                  sx={{
                    mb: 3,
                    width: '33vw',
                    height: 50
                  }}
                  error={!!formErrors.password}
                />
              </Grid>
              <Grid item xs lg sx={{ mr: 1 }}>
                <TextField
                  label="Confirm Password"
                  placeholder='Re-Enter Password'
                  required
                  variant="outlined"
                  color="primary"
                  type="password"
                  disabled={isDisabled}
                  value={user.cpassword}
                  onChange={(e) => updateForm({ cpassword: e.target.value })}
                  inputProps={{
                    minLength: 8, // Set the minimum length to 8 characters
                    maxLength: 10, // Set the maximum length to 10 characters
                  }}
                  sx={{
                    mb: 3,
                    width: '33vw',
                    height: 50
                  }}
                  error={!!formErrors.cpassword}
                />
              </Grid>
            </Grid>
             )}
            <Grid container sx={{ mt: 2, ml: 1 }}>
              <Grid item>
                <FormControl
                  required
                  error={!!formErrors.role}
                  sx={{
                    mb: 3,
                    width: '33vw',
                    height: 50
                  }}>
                  <InputLabel id="Role">Select Role</InputLabel>
                  <Select
                    labelId="roleid"
                    id="role"
                    label="Select Role"
                    name="role"
                    value={user.role}
                    onChange={(e) => updateForm({ role: e.target.value })}
                  >
                    {roles.map(role => (
                      <MenuItem value={role._id} key={role._id} >{role.UserRole}</MenuItem>
                    ))
                    }
                  </Select>
                  {/* {formErrors.role && <FormHelperText>{formErrors.role}</FormHelperText>} */}
                </FormControl>
              </Grid>

              <Grid item sx={{ display: user.userActivechk }}>
                <FormControlLabel
                  sx={{
                    mb: 3, mr: 8, ml: 4, mt: 2,

                  }}
                  control={<Checkbox
                    checked={defaultChecked}
                    name="active"
                    value={user.active}
                    onChange={handleChange} />} label="Active" />
              </Grid>

            </Grid>
            <Box
              sx={{
                mt: 2,
                width: '100%',
                height: 50,
                backgroundColor: '#f5f5f5',
                borderStyle: '',
              }}
            >
              <Grid container justifyContent={'flex-end'}>
                <Grid item>
                  <Button variant="contained"
                    type='submit'
                    sx={{
                      mt: 1,
                      mb: -1,
                      backgroundColor: ' #213555 !important',
                    }}
                    onClick={saveUserHandle}
                  >Save</Button>&nbsp;&nbsp;
                  <Button variant="contained"
                    type='submit'
                    sx={{
                      mt: 1,
                      mr: 1,
                      mb: -1,
                      backgroundColor: ' #CFD2CF !important',
                      color: "black"
                    }}
                    onClick={() => navigate("/administarion/users")}
                  >Cancel</Button>
                </Grid>
              </Grid>

            </Box>
          </form>
        </Box>
      </Box>
    </div>

  )
}
export default UserCreate