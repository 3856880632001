import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Select, MenuItem, InputLabel, FormControl, FormControlLabel, Checkbox, Paper, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Navbar from "../Navigation/Navbar";
import DescriptionIcon from '@mui/icons-material/Description';
import { getAuditTypesList, getAuditNamesList, getReviewerList } from '../../src/store/surveySlice';
import { getSummaryReport, getExaminernames } from '../../src/store/reportsSlice';
import { getSections } from '../../src/store/questionSlice';
import Message from '../../src/commonui/snackbar';
import Autocomplete from '@mui/material/Autocomplete';
import Papa from 'papaparse';
function AveragaesByExaminar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auditTypeList = useSelector(({ surveys }) => surveys.auditTypeData);
    const reportData1 = useSelector(({ reports }) => reports.summaryReportData);
    const TotalCount = useSelector(({ reports }) => reports.TotalCount);
    const sectionlist1 = useSelector(({ reports }) => reports.reportSectionlist);
    const auditnameslist = useSelector(({ surveys }) => surveys.auditnamelist);
    const [reportData, setReportData] = React.useState(reportData1);
    const [sectionlist, setSectionlist] = React.useState(sectionlist1);
    const examinerlist = useSelector(({ reports }) => reports.adjusterlist);
    const [examinernames, setExaminerlist] = useState(examinerlist);
    const reviewerslist = useSelector(({ surveys }) => surveys.reviewers);
    const [selectedExaminer, setExaminer] = useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    useEffect(() => {

        dispatch(getAuditTypesList());
        dispatch(getSections());
        dispatch(getExaminernames());
        dispatch(getAuditNamesList());
        dispatch(getReviewerList());
    }, []);
    useEffect(() => {

        setReportData(reportData1);
        setSectionlist(sectionlist1);
    }, [reportData1, sectionlist1]);
    useEffect(() => {

        setExaminerlist(examinerlist);
    }, [examinerlist]);
    const [searchData, setSearch] = useState({
        searchClaimnumber: "",
        searchExaminer: "",
        //  searchAudittypeId: "All",
        searchAudittypeId: [],
        searchEvaluationDate: "",
        // searchAuditNameId: "All",
        searchAuditNameId: [],
        searchLossCause: "",
        searchClaimant: "",
        searchMajorPeril: "",
        searchLossdateFrom: "",
        searchLossdateTo: "",
        searchReviewerId: [],
        rowsPerPage: 100,
        page: 0,
    })
    /**default functions */
    function updateForm(value) {

        return setSearch((prev) => {
            return { ...prev, ...value };
        });
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
    };
    const CovertDateFormat = (mongoDate) => {
        const lossDateObject = new Date(mongoDate);

        // Format the date as "MM/dd/yyyy"
        const formattedLossDate = `${(lossDateObject.getMonth() + 1).toString().padStart(2, '0')}/${lossDateObject.getDate().toString().padStart(2, '0')}/${lossDateObject.getFullYear()}`;
        return formattedLossDate;
    };
    function clearSearch() {

        setPage(0);
        setRowsPerPage(100);
        setReportData([]);
        // setSectionlist([]);
        setSearch({
            searchClaimnumber: "",
            searchExaminer: "",
            // searchAudittypeId: "All",
            searchAudittypeId: [],
            searchEvaluationDate: "",
            //searchAuditNameId: "All",
            searchAuditNameId: [],
            searchLossCause: "",
            searchClaimant: "",
            searchMajorPeril: "",
            searchLossdateFrom: "",
            searchLossdateTo: "",
            searchReviewerId: [],
            rowsPerPage: 100,
            page: 0,
        })
    }
    const handleAutocompleteChange = (event, newValue) => {
        setExaminer(newValue); // Update the selected value in state
    };
    const handleInputChange = (event, newInputValue) => {

        // setExaminer(newInputValue);
        searchData.searchExaminer = newInputValue;
    };
    /**default functions */
    function generateReport() {

        if (selectedExaminer) { searchData.searchExaminer = selectedExaminer.name; }
        dispatch(getSummaryReport(searchData));
    }
    const handleDownload = () => {
        setSearch.page = 1 * 99990;
        setSearch.rowsPerPage = 99990;
        setSearch(searchData);
        dispatch(getSummaryReport(searchData));
        const tableElement = document.getElementById('SummaryreporttblExcel');
        const tableData = extractTableData(tableElement);
        const csvData = Papa.unparse(tableData);

        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'AveragesByExaminar.csv';
        a.click();
        URL.revokeObjectURL(url);
    };

    const extractTableData = (tableElement) => {
        const tableData = [];
        const headers = [];

        // Extract headers from the first row
        const headerCells = tableElement.querySelectorAll('thead th');
        for (let j = 0; j < headerCells.length; j++) {
            headers.push(headerCells[j].innerText);
        }
        tableData.push(headers);

        const rows = tableElement.querySelectorAll('tbody tr');
        for (let i = 0; i < rows.length; i++) {
            const cells = rows[i].getElementsByTagName('td');
            const rowData = [];

            for (let j = 0; j < cells.length; j++) {
                rowData.push(cells[j].innerText);
            }

            tableData.push(rowData);
        }

        return tableData;
    };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const handleAuditNameDropdownChange = (event) => {

        if (event.target.value === "All") {
            updateForm({ searchAudittypeId: "All" });
            updateForm({ searchAuditNameId: event.target.value });
        }
        else {
            let auditnamereocrd = auditnameslist.filter(auditnamedata => auditnamedata._id === event.target.value);
            updateForm({ searchAudittypeId: auditnamereocrd[0].audittypeId._id });
            updateForm({ searchAuditNameId: event.target.value });
        }
    };
    return (
        <div>
            <Navbar /><Message></Message>
            <Box
                sx={{
                    marginTop: 1,
                    marginLeft: '5%',
                    width: '90vw',
                    height: '100%',
                    backgroundColor: 'whitesmoke',
                    borderStyle: 'solid',
                    borderColor: '#213555',
                    borderWidth: 0.5
                }}
            >
                <Box
                    sx={{
                        mt: 0,
                        width: '100%',
                        height: '2em',
                        backgroundColor: '#22A699',
                        borderBottom: '1px solid #213555',
                        display: 'flex',
                        alignItems: 'center',
                        // paddingLeft: '1rem',
                        color: 'white',
                        fontSize: '1.2rem',
                        // fontWeight: 'bold',
                    }}
                >
                    <DescriptionIcon />
                    PHYS DAM AUDITS - AVERAGES BY EXAMINER
                </Box>
                <Grid container spacing={1} alignItems="center" marginLeft='6%' className='no-print'>
                    <Grid item sx={{
                        mr: 2,
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                    }} >
                        <FormControl
                            size='small'
                            sx={{
                                mr: 2,
                                mt: 1,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                            }}
                        >
                            <InputLabel id="auditName">Select Audit Name</InputLabel>
                            <Select
                                labelId="auditname"
                                required
                                multiple
                                id="searchAuditNameId"
                                label="Select Audit Type"
                                name="searchAuditNameId"
                                value={searchData.searchAuditNameId}
                                // onChange={handleAuditNameDropdownChange}
                                onChange={(e) => updateForm({ searchAuditNameId: e.target.value })}
                                MenuProps={MenuProps}
                            >
                                {/* <MenuItem value="All" key="All">All</MenuItem> */}
                                {auditnameslist.map((row) => (
                                    <MenuItem
                                        value={row._id}
                                        key={row._id}
                                        sx={{ padding: '0.1rem', width: '30%', maxWidth: '20vm' }}
                                    >
                                        {row.auditname}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }}>
                        <FormControl
                            size='small'
                            sx={{
                                mt: 1,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '16rem', // Set a maximum width to limit the siz
                            }}
                        >
                            <InputLabel id="auditType" >Select Audit Type</InputLabel>
                            <Select
                                labelId="audittype"
                                required
                                multiple
                                id="searchAudittypeId"
                                label="Select Audit Type"
                                name="searchAudittypeId"
                                value={searchData.searchAudittypeId}
                                sx={{
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                }}
                                onChange={(e) => updateForm({ searchAudittypeId: e.target.value })}
                                MenuProps={MenuProps}
                            >
                                {/* <MenuItem value="All" key="All">All</MenuItem> */}
                                {auditTypeList.map((row) => (
                                    <MenuItem
                                        value={row._id}
                                        key={row._id}
                                        sx={{ padding: '0.1rem', width: '30%', maxWidth: '20vm' }}
                                    >
                                        {row.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }}>
                        <TextField
                            label="Loss Cause"
                            placeholder='Search'
                            variant="outlined"
                            color="primary"
                            type="text"
                            name="searchLossCause"
                            size="small"
                            sx={{
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                                mt: 1, mr: 2
                            }}
                            value={searchData.searchLossCause}
                            onChange={(e) => updateForm({ searchLossCause: e.target.value })}
                        />
                    </Grid>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }}>
                        <Autocomplete
                            size="small"
                            freeSolo
                            options={examinernames}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Examiner"
                                    placeholder='Search'
                                    variant="outlined"
                                    color="primary"
                                    type="text"
                                    name="searchExaminer"
                                    size="small"
                                //   value={searchData.searchExaminer}
                                // onChange={(e) => updateForm({ searchExaminer: e.target.value })}
                                />
                            )}

                            onChange={handleAutocompleteChange} // Handle selection change
                            onInputChange={handleInputChange}
                            sx={{
                                width: '100%',
                                maxWidth: '15rem',
                                mt: 1,
                                //mr: 3,
                            }}
                        />
                    </Grid>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }} >
                        <TextField
                            label="Claimant"
                            placeholder='Claimant'
                            variant="outlined"
                            color="primary"
                            type="text"
                            name="searchClaimant"
                            size="small"
                            sx={{
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                                mt: 1,
                            }}
                            value={searchData.searchClaimant}
                            onChange={(e) => updateForm({ searchClaimant: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center" marginLeft='6%' className='no-print'>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }} >
                        <TextField
                            label="Evaluation date"
                            placeholder=''
                            type="date"
                            // required
                            variant="outlined"
                            color="primary"
                            name="searchEvaluationDate"
                            value={searchData.searchEvaluationDate}
                            size="small"
                            onChange={(e) => updateForm({ searchEvaluationDate: e.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{
                                mt: 2,
                                mr: 3,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                            }}
                        />
                    </Grid>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }} >
                        <TextField
                            label="Claim Number"
                            placeholder='Search'
                            variant="outlined"
                            color="primary"
                            type="text"
                            name="searchClaimnumber"
                            size="small"
                            sx={{
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '14.5rem', // Set a maximum width to limit the size
                                mt: 2,
                                mr: 3
                            }}
                            value={searchData.searchClaimnumber}
                            onChange={(e) => updateForm({ searchClaimnumber: e.target.value })}
                        />
                    </Grid>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }} >
                        <TextField
                            label="Major peril"
                            placeholder='Major Peril'
                            variant="outlined"
                            color="primary"
                            type="text"
                            name="searchMajorPeril"
                            size="small"
                            sx={{
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                                mt: 2,
                                mr: 3
                            }}
                            value={searchData.searchMajorPeril}
                            onChange={(e) => updateForm({ searchMajorPeril: e.target.value })}
                        />
                    </Grid>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }} >
                        <TextField
                            label="Loss Date From"
                            placeholder=''
                            type="date"
                            // required
                            variant="outlined"
                            color="primary"
                            name="searchLossdateFrom"
                            value={searchData.searchLossdateFrom}
                            size="small"
                            onChange={(e) => updateForm({ searchLossdateFrom: e.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{
                                mt: 2,
                                mr: 2,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                            }}
                        />
                    </Grid>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }} >
                        <TextField
                            label="Loss Date To"
                            placeholder=''
                            type="date"
                            // required
                            variant="outlined"
                            color="primary"
                            name="searchLossdateTo"
                            value={searchData.searchLossdateTo}
                            size="small"
                            onChange={(e) => updateForm({ searchLossdateTo: e.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{
                                mt: 2,
                                mr: 2,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center" marginLeft='6%' className='no-print'>
                    <Grid item xs={12} sm={12} md={12}>

                        <FormControl

                            size='small'
                            sx={{
                                mr: 2,
                                mt: 2,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                            }}
                        >
                            <InputLabel id="reviewer">Select Reviewer</InputLabel>
                            <Select
                                labelId="reviewer"
                                required
                                multiple
                                id="searchReviewerId"
                                label="Select Reviewer"
                                name="searchReviewerId"
                                value={searchData.searchReviewerId}
                                onChange={(e) => updateForm({ searchReviewerId: e.target.value })}
                                MenuProps={MenuProps}
                            >
                                {/* <MenuItem value="All" key="All">All</MenuItem> */}
                                {reviewerslist.map((row) => (
                                    <MenuItem
                                        value={row._id}
                                        key={row._id}
                                        sx={{ padding: '0.1rem', width: '30%', maxWidth: '20vm' }}
                                    >
                                        {row.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            sx={{
                                mt: 2,
                                mr: 1,
                                backgroundColor: '#213555 !important',
                                fontColor: 'black',
                                textTransform: 'none',
                                lineHeight: 1.8,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '5rem', // Set a maximum width to limit the size
                            }}
                            onClick={() => generateReport()}
                        >
                            Generate
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                mt: 2,
                                mr: 1,
                                backgroundColor: '#557A46',
                                fontColor: 'black',
                                textTransform: 'none',
                                lineHeight: 1.8,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '5rem', // Set a maximum width to limit the size
                            }}
                            onClick={handleDownload}
                        >
                            Excel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                mt: 2,
                                //ml: 1,
                                backgroundColor: 'Gray !important',
                                fontColor: 'black',
                                textTransform: 'none',
                                lineHeight: 1.8,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '5rem', // Set a maximum width to limit the size
                            }}
                            onClick={() => clearSearch()}
                        >
                            Clear
                        </Button>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        marginTop: 2,
                        marginLeft: 1,
                        marginRight: 1,
                        height: '65%',
                        backgroundColor: 'white',
                    }}
                >
                    <TableContainer component={Paper} sx={{
                        maxWidth: '100%',
                        overflowX: 'auto',
                    }}>
                        <Table sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold' }} size='small' aria-label="sticky table" id="Summaryreporttbl">
                            <TableHead sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold', fontSize: '0.975rem !important', backgroundColor: '#898B8A', color: 'white' }}>
                                <TableRow>
                                    <TableCell sx={{ color: 'white', width: '10%', whiteSpace: 'nowrap' }}>Audit Name</TableCell>
                                    <TableCell sx={{ color: 'white', width: '10%', whiteSpace: 'nowrap' }}>Claim Number</TableCell>
                                    <TableCell sx={{ color: 'white', width: '10%', whiteSpace: 'nowrap' }}>Business Unit</TableCell>
                                    <TableCell sx={{ color: 'white', width: '15%', whiteSpace: 'nowrap' }}>Audit Type</TableCell>
                                    <TableCell sx={{ color: 'white', width: '10%', whiteSpace: 'nowrap' }}>Evaluation Date</TableCell>
                                    <TableCell sx={{ color: 'white', width: '15%', whiteSpace: 'nowrap' }}>Examiner</TableCell>
                                    <TableCell sx={{ color: 'white', width: '10%', whiteSpace: 'nowrap' }}>Reviewer</TableCell>
                                    {sectionlist.map((row) => (
                                        <TableCell sx={{ color: 'white', width: '3%' }}>{row.section}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportData.map((row) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell width="10%" sx={{ whiteSpace: "nowrap" }}>{row.auditnameId ? row.auditnameId.auditname : ""}</TableCell>
                                        <TableCell width="5%">{row.claimnumber}</TableCell>
                                        <TableCell width="5%">{row.businessunit}</TableCell>
                                        <TableCell width="10%" sx={{ whiteSpace: "nowrap" }}>{row.audittypeId ? row.audittypeId.name : ""}</TableCell>
                                        <TableCell width="5%">{row.CreatedDate ? CovertDateFormat(row.CreatedDate) : ""}</TableCell>
                                        <TableCell width="10%" sx={{ whiteSpace: "nowrap" }}>{row.examiner}</TableCell>
                                        <TableCell width="10%" sx={{ whiteSpace: "nowrap" }}>{row.reviewerId ? row.reviewerId.name : ""}</TableCell>
                                        {sectionlist.map((sectionrow) => {
                                            let percentage = undefined;
                                            for (const sectionPercentage of row.sectionPercentages) {
                                                if (sectionPercentage.sectionName === sectionrow.section) {
                                                    percentage = sectionPercentage.sectionPercentage;
                                                    break;
                                                }
                                            }
                                            return (
                                                <Tooltip title={sectionrow.section}>
                                                    <TableCell align='center' key={sectionrow.section} sx={{
                                                        width: '3%',
                                                        //   backgroundColor: percentage !== undefined ? '' : '#CCEEBC', 
                                                    }}>
                                                        {percentage !== undefined ? `${Math.round(percentage.toFixed(2))}%` : '--'}
                                                    </TableCell></Tooltip>
                                            );
                                        })}

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={TotalCount ? TotalCount : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Box>
            <TableContainer component={Paper} sx={{
                maxWidth: '100%',
                overflowX: 'auto',
                display: 'none'
            }}>
                <Table sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold' }} size='small' aria-label="sticky table" id="SummaryreporttblExcel">
                    <TableHead sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold', fontSize: '0.975rem !important', backgroundColor: '#898B8A', color: 'white' }}>
                        <TableRow>
                            <TableCell sx={{ color: 'white', width: '10%', whiteSpace: 'nowrap' }}>Audit Name</TableCell>
                            <TableCell sx={{ color: 'white', width: '10%', whiteSpace: 'nowrap' }}>Claim Number</TableCell>
                            <TableCell sx={{ color: 'white', width: '10%', whiteSpace: 'nowrap' }}>Business Unit</TableCell>
                            <TableCell sx={{ color: 'white', width: '15%', whiteSpace: 'nowrap' }}>Audit Type</TableCell>
                            <TableCell sx={{ color: 'white', width: '10%', whiteSpace: 'nowrap' }}>Evaluation Date</TableCell>
                            <TableCell sx={{ color: 'white', width: '15%', whiteSpace: 'nowrap' }}>Examiner</TableCell>
                            <TableCell sx={{ color: 'white', width: '10%', whiteSpace: 'nowrap' }}>Reviewer</TableCell>
                            {sectionlist.map((row) => (
                                <TableCell sx={{ color: 'white', width: '3%' }}>{row.section}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData.map((row) => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell width="10%" sx={{ whiteSpace: "nowrap" }}>{row.auditnameId ? row.auditnameId.auditname : ""}</TableCell>
                                <TableCell width="5%">{row.claimnumber}</TableCell>
                                <TableCell width="5%">{row.businessunit}</TableCell>
                                <TableCell width="10%" sx={{ whiteSpace: "nowrap" }}>{row.audittypeId ? row.audittypeId.name : ""}</TableCell>
                                <TableCell width="5%">{row.CreatedDate ? CovertDateFormat(row.CreatedDate) : ""}</TableCell>
                                <TableCell width="10%" sx={{ whiteSpace: "nowrap" }}>{row.examiner}</TableCell>
                                <TableCell width="10%" sx={{ whiteSpace: "nowrap" }}>{row.reviewerId ? row.reviewerId.name : ""}</TableCell>
                                {sectionlist.map((sectionrow) => {
                                    let percentage = undefined;
                                    for (const sectionPercentage of row.sectionPercentages) {
                                        if (sectionPercentage.sectionName === sectionrow.section) {
                                            percentage = sectionPercentage.sectionPercentage;
                                            break;
                                        }
                                    }
                                    return (
                                        <Tooltip title={sectionrow.section}>
                                            <TableCell align='center' key={sectionrow.section} sx={{
                                                width: '3%',
                                                //   backgroundColor: percentage !== undefined ? '' : '#CCEEBC', 
                                            }}>
                                                {percentage !== undefined ? `${Math.round(percentage.toFixed(2))}%` : '--'}
                                            </TableCell></Tooltip>
                                    );
                                })}

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    );
}

export default AveragaesByExaminar;
