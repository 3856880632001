import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from "../store/messageSlice";



export const search = (data) => async dispatch => {

    await axios.post('http://localhost:9002/searchauditsection', data, { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data;
            if (res.data.status === true) {
                dispatch(setTotalCount(x.data.TotalCount));
                return dispatch(auditTypyListSuccess(x.data.List));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
            return dispatch(auditSectionListError([]));
        }).catch(error => {
            dispatch(showMessage({
                message: error, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            return dispatch(auditSectionListError([]));
        });

};
/***to get active section and sections for reference audit type */
export const GetSections = () => async dispatch => {

    await axios.get('http://localhost:9002/getsectionlist', { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data;
            if (res.data.status === true) {
                return dispatch(SectionListSuccess(x.list));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            return dispatch(SectionListError([]));
        });

};
export const GetSectionsQuestions = (section) => async dispatch => {

    await axios.post('http://localhost:9002/getsectionQtnList', { section }, { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data;
            if (res.data.status === true) {
                return dispatch(SectionQtnListSuccess(x.list));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            return dispatch(SectionQtnListError([]));
        });

};
const initialState = {
    auditsectionsuccess: false,
    auditsectionData: [],
    auditsection: [],
    sectionList: [],
    sectionQtnList: [],
};

const auditsectionSlice = createSlice({
    name: 'auditsection',
    initialState,
    reducers: {
        auditTypyListSuccess: (state, action) => {

            state.auditsectionsuccess = true;
            state.auditsectionData = action.payload;
        },
        auditSectionListError: (state, action) => {
            state.auditsectionsuccess = false;
            state.auditsectionData = [];
        },
        auditTypyDataSuccess: (state, action) => {
            state.auditsectionsuccess = true;
            state.auditsection = action.payload;
        },
        setTotalCount: (state, action) => {
            state.auditsectionsuccess = true;
            state.TotalCount = action.payload;
        },
        SectionListSuccess: (state, action) => {
            state.sectionList = action.payload;
        },
        SectionListError: (state, action) => {
            state.sectionList = [];
        },
        SectionQtnListSuccess: (state, action) => {
            state.sectionQtnList = action.payload;
        },
        SectionQtnListError: (state, action) => {
            state.sectionQtnList = [];
        }


    }
})
export const {
    auditTypyListSuccess,
    auditSectionListError,
    setTotalCount,
    auditTypyDataSuccess,
    SectionListSuccess,
    SectionListError,
    SectionQtnListSuccess,
    SectionQtnListError
} = auditsectionSlice.actions;


export default auditsectionSlice.reducer;