import React, { useEffect, useState } from 'react';
import Dashboard from "../../Dashboard"
//import axios from "axios";
import { InputLabel } from '@mui/material';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { getSectionsList, saveSection } from '../../store/sectionSlice.js';
import Navbar from "../../Navigation/Navbar";
import Message from '../../commonui/snackbar';
function AddSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.sectionid;
  const sectiondata = useSelector(({ sections }) => sections.sectionsData);
  const [sections, setSections] = useState({
    _id: "",
    section: "",
    // sectioncode: "",
    description: "",
    Heading: "Add Section",
  });

  function updateForm(value) {

    return setSections((prev) => {
      return { ...prev, ...value };
    });
  }

  useEffect(() => {

    // dispatch(getSectionsList(0, 100));
    editSecdata();
  }, []);

  async function editSecdata() {

    if (id !== "0") {
      let secrecord = sectiondata.filter(sectiondata => sectiondata._id === id);
      if (secrecord) {
        setSections({
          _id: secrecord[0]._id,
          section: secrecord[0].section,
          //  sectioncode: secrecord[0].sectioncode,
          description: secrecord[0].description,
          Heading: 'Edit Section',
        });
      }
    }

  }

  async function saveSecHandle() {

    if (sections.section) {
      if (id === "0") {
        saveSecData();
      } else {
        editSection();
      }
    }
  }

  async function saveSecData() {

    dispatch(saveSection(sections));
    navigate("/administarion/sections")
  }
  async function editSection() {

    dispatch(saveSection(sections));
    navigate("/administarion/sections")
  }

  return (

    <div>
      {/*<Dashboard />
      <InputLabel id="Sections Management"
        sx={{
          ml: 20,
          mt: 10,
          mb: 1,
          mr: 1,
          color: 'Black',
          fontFamily: 'Arial',
          fontStyle: 'Bold',
          fontSize: '1.5rem'
        }}
      ></InputLabel>*/}
      <Navbar />
      <Box
        sx={{
          marginTop: 1,
          marginLeft: '20%',
          width: '60vw',
          height: '100%',
          backgroundColor: 'white',
          borderStyle: 'solid',
          borderColor: '#213555',
          borderWidth: 0.5
        }}
      >
        <Box
          sx={{
            mt: 0,
            width: '100%',
            height: 40,
            backgroundColor: '#22A699',
            borderStyle: '',
          }}
        >
          <Grid container>

            <Grid item><InputLabel id="addsection"
              sx={{
                ml: 1,
                mt: 1,
                color: 'white',
              }}
            >{sections.Heading}</InputLabel>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            marginTop: 0,
            width: '99%',
            height: "100%",
            borderBlockColor: 'black',
            backgroundColor: 'white',
            display: "flex",
            flexDirection: "column",
          }}

        >
          <form autoComplete="off">
            <Grid container sx={{ mt: 2, ml: 1, mr: 1 }}>
              {/* <Grid item>
                <TextField
                  label="Section Code"
                  placeholder='Enter Section Code'
                  required
                  variant="outlined"
                  color="primary"
                  type="text"
                  value={sections.sectioncode}
                  onChange={(e) => updateForm({ sectioncode: e.target.value })}
                  sx={{
                    mb: 3,
                    width: '57dvw',
                    height: 50
                  }}
                />
              </Grid> */}
              <Grid item>
                <TextField
                  label="Section Name"
                  placeholder='Enter Section Name'
                  required
                  variant="outlined"
                  color="primary"
                  type="text"
                  value={sections.section}
                  onChange={(e) => updateForm({ section: e.target.value })}
                  sx={{
                    mb: 3,
                    width: '57dvw',
                    height: 50
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Decscription"
                  placeholder='Enter Description'
                  // required
                  variant="outlined"
                  color="primary"
                  multiline
                  rows={3}
                  value={sections.description}
                  onChange={(e) => updateForm({ description: e.target.value })}
                  sx={{
                    mb: 3,
                    width: '57dvw',
                    height: 50
                  }}
                />
              </Grid>
            </Grid>
            <br /><br /><br />
            <Box
              sx={{
                width: '100%',
                height: 50,
                backgroundColor: '#f5f5f5',
                borderStyle: '',
              }}
            >
              <Grid container justifyContent={'flex-end'}>
                <Grid item>
                  <Button variant="contained"
                    type='submit'
                    sx={{
                      mt: 1,
                      mb: -1,
                      backgroundColor: ' #213555 !important',
                    }}
                    onClick={saveSecHandle}
                  >Save</Button>&nbsp;&nbsp;
                  <Button variant="contained"
                    type='submit'
                    sx={{
                      mt: 1,
                      mb: -1,
                      mr: 1,
                      backgroundColor: ' #CFD2CF !important',
                      color: "black"
                    }}
                    onClick={() => navigate("/administarion/sections")}
                  >Cancel</Button>
                </Grid>
              </Grid>

            </Box>
          </form>
        </Box>
      </Box>
    </div>

  )
}
export default AddSection;