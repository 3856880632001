import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TableViewIcon from '@mui/icons-material/TableView';
import PollIcon from '@mui/icons-material/Poll';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
function MainScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginuser = useSelector(({ loginuser }) => loginuser.loginData);
    const [accessid, setAccessid] = useState(0);
    const [isRotated, setRotated] = useState(false);
    useEffect(() => {

        const id = localStorage.getItem('UserId')
        if (id === undefined || id === 0 || id === null) {
            navigate("/");
        }
        else {
            const Aid = localStorage.getItem('AccessId')
            setAccessid(Aid);
        }
    });

    const handleMouseEnter = () => {
        setRotated(true);
    };

    const handleMouseLeave = () => {
        setRotated(false);
    };
    const [anchorEl, setAnchorEl] = useState(null);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleBoxFocus = () => {
        setIsDropdownOpen(true);
    };
                 
    const handleBoxBlur = () => {
        setIsDropdownOpen(false);
    };


    const admindropdownItems = [
        { path: "/administarion/sections", label: "Sections" },
        { path: "/administarion/questions", label: "Questions" },
        { path: "/audit/audittypes", label: "Audit Types" },
        { path: "/audit/auditnames", label: "Audit Names" },
    ];
    const reverseText = (text) => {
        return text.split("").reverse().join("");
    };
    return (
        <>
            {accessid !== "5e8fe1e9fb38c9ce40de0009" ?
                (
                    <>
                        <Grid container justifyContent={'center'}>
                            <Grid item>
                                <Box onClick={() => navigate("/administarion/users")}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    sx={{
                                        width: "150px",
                                        height: "150px",
                                        backgroundColor: "#8062D6",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "white",
                                        fontSize: "1rem",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                        margin: "40px",
                                        transition: "transform 8.0s ease",
                                        //   transform: isRotated ? "rotateY(180deg)" : "rotateY(0)",
                                        backfaceVisibility: "hidden",
                                    }}>
                                    <Grid container justifyContent={'center'} alignItems={'center'}>
                                        <Grid item xs={12} justifyContent={'center'} marginLeft='40%'>
                                            <PeopleAltIcon />
                                        </Grid>
                                        <Grid item xs={12} justifyContent={'center'} marginLeft='35%'>
                                            {/* <span>{isRotated ? "Click here to open User list " : "Users"}</span> */}
                                            <span>{"Users"}</span>
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Grid>
                            <Grid item>
                                <Box
                                    onMouseEnter={handleBoxFocus}
                                    //  onMouseLeave={handleBoxBlur}
                                    tabIndex={0} // Allow the box to receive focus
                                    sx={{
                                        width: "150px",
                                        height: "150px",
                                        backgroundColor: "#1B6B93",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "white",
                                        fontSize: "1rem",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                        margin: "40px",
                                        transition: "transform 5.0s ease",
                                        //  transform: isRotated ? "rotateY(180deg)" : "rotateY(0)",
                                        backfaceVisibility: "hidden",
                                    }}>

                                    <Grid container justifyContent={'center'} alignItems={'center'}>
                                        <Grid item xs={12} justifyContent={'center'} marginLeft='40%'>
                                            <TableViewIcon />
                                        </Grid>
                                        <Grid item xs={12} justifyContent={'center'} marginLeft='30%'>
                                            {/* <span>{isRotated ? "Click here to open Masters " : " Admin Masters"}</span> */}
                                            <span>{"Admin Masters"}</span>
                                        </Grid>

                                    </Grid>

                                </Box>
                                <Grid container justifyContent={'center'} alignItems={'center'} marginTop='-20%'
                                    onMouseLeave={handleBoxBlur}
                                >
                                    {isDropdownOpen && (
                                        <div
                                            style={{
                                                position: "relative",
                                                top: "100%",
                                                left: 0,
                                                backgroundColor: "#fff",
                                                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                                                borderRadius: "5px",
                                                zIndex: 2,
                                            }}
                                        >
                                            {admindropdownItems.map((item) => (
                                                <div
                                                    key={item.label}
                                                    style={{
                                                        padding: "8px 16px",
                                                        borderBottom: "1px solid #ccc",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <Link
                                                        to={item.path}
                                                        style={{ textDecoration: "none", color: "Black" }}
                                                    >
                                                        {item.label}
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </Grid>


                            </Grid>
                            {/* </Grid> */}
                            {/* <Grid container> */}
                            <Grid item>
                                <Box onClick={() => navigate("/reports/averagesbyexaminar")}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    sx={{
                                        width: "150px",
                                        height: "150px",
                                        backgroundColor: "#1F8A70",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "white",
                                        fontSize: "1rem",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                        margin: "40px",
                                        transition: "transform 8.0s ease",
                                        //  transform: isRotated ? "rotateY(180deg)" : "rotateY(0)",
                                        backfaceVisibility: "hidden",
                                    }}>
                                    <Grid container justifyContent={'center'} alignItems={'center'}>
                                        <Grid item xs={12} justifyContent={'center'} marginLeft='40%'>
                                            <SummarizeIcon />
                                        </Grid>
                                        <Grid item xs={12} justifyContent={'center'} marginLeft='30%'>
                                            {/* <span>{isRotated ? "Click here to open Averages By Examiner" : "Averages By Examiner"}</span> */}
                                          <span>{"Averages By Examiner"}</span> 
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Grid>
                            <Grid item>
                                <Box onClick={() => navigate("/reports/overallaverages")}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    sx={{
                                        width: "150px",
                                        height: "150px",
                                        backgroundColor: "#4D3C77",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "white",
                                        fontSize: "1rem",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                        margin: "40px",
                                        transition: "transform 5.0s ease",
                                        // transform: isRotated ? "rotateY(180deg)" : "rotateY(0)",
                                        backfaceVisibility: "hidden",
                                    }}>
                                    <Grid container justifyContent={'center'} alignItems={'center'}>
                                        <Grid item xs={12} justifyContent={'center'} marginLeft='40%'>
                                            <PollIcon />
                                        </Grid>
                                        <Grid item xs={12} justifyContent={'center'} marginLeft='30%'>
                                            {/* <span>{isRotated ? "Click here to open Overall Averages " : "Overall Averages"}</span> */}
                                            <span>{"Overall Averages"}</span>
                                        </Grid>
                                    </Grid>

                                </Box>

                            </Grid>
                            <Grid item>
                                <Box
                                    onClick={() => navigate("/reports/summaryreport")}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    sx={{
                                        width: "150px",
                                        height: "150px",
                                        backgroundColor: "#609966",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "white",
                                        fontSize: "1rem",
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                        margin: "40px",
                                        transition: "transform 5.0s ease",
                                        // transform: isRotated ? "rotateY(180deg)" : "rotateY(0)",
                                        backfaceVisibility: "hidden",
                                    }}>
                                    <Grid container justifyContent={'center'} alignItems={'center'}>
                                        <Grid item xs={12} justifyContent={'center'} marginLeft='40%'>
                                            <SummarizeIcon />
                                        </Grid>
                                        <Grid item xs={12} justifyContent={'center'} marginLeft='30%'>
                                            {/* <span>{isRotated ? "Click here to open Summary Report " : "Summary Report"}</span> */}
                                            <span>{"Summary Report"}</span>
                                        </Grid>
                                    </Grid>

                                </Box>

                            </Grid>
                        </Grid>
                    </>) :
                (<></>
                )
            }

        </>
    )
}
export default MainScreen