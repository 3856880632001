import React, { useEffect, useState } from 'react';
import Dashboard from "../../Dashboard"
//import axios from "axios";
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionList, getSections, saveQuestion } from '../../store/questionSlice.js';
import { saveAuditTypeQuestion, GetAuditType } from '../../store/auditTypeSlice1';
//import { getSectionsList } from '../../store/sectionSlice';
import Navbar from "../../Navigation/Navbar";

function AddQuestion() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.questionid;
  const sectionid1 = params.sectionId;
  const questiondata = useSelector(({ questions }) => questions.questionsData);
  const sectionlist = useSelector(({ questions }) => questions.questionSection);
  const savedaudittype1 = useSelector(({ audittypewithSections }) => audittypewithSections.audittype);
  const [savedaudittype, setAuditType] = useState(savedaudittype1)
  const [sectionid, setSectionid] = useState(sectionid1)
  const requiredFields = ["sectionId", "question", "points"];
  //const [sections, setSections] = useState(questionSection);
  const [formErrors, setFormErrors] = useState({});
  const [sectionDisabled, setDisabled] = useState(false);
  const [question, setQuestion] = useState({
    _id: "",
    sectionId: "",
    question: "",
    remarks: "",
    points: "",
    heading: "Add Question",
  })

  function updateForm(value) {

    return setQuestion((prev) => {
      return { ...prev, ...value };
    });
  }

  useEffect(() => {

    dispatch(getQuestionList(0, 100));
    dispatch(getSections());
    setAuditType(savedaudittype1);
    editQuesdata();
  }, []);
  // useEffect(() => {
  //    
  //   setSectionquestionlist(SectionQuestionlist);
  //   editQuesdata();
  // }, [sectionid]);
  async function editQuesdata() {

    if (id !== "0" && sectionid === "0") {
      let quesrecord = questiondata.filter(questiondata => questiondata._id === id);
      setQuestion({
        _id: quesrecord[0]._id,
        question: quesrecord[0].question,
        points: quesrecord[0].points,
        remarks: quesrecord[0].remarks,
        sectionId: quesrecord[0].sectionId._id,
        heading: "Edit Question",
      });
    }
    /**added for audittype screen question add for given section By RJ */
    if ((sectionid !== "0") && savedaudittype._id !== "0") {
      setDisabled(true);
      const sectiondata = savedaudittype.SectionQuestionList.find(section => section._id === sectionid);
      const questiondata = sectiondata.questions.find(question => question._id === id);
      if (questiondata !== undefined && questiondata !== null && questiondata !== "") {
        setQuestion({
          _id: questiondata._id,
          question: questiondata.question,
          points: questiondata.points,
          remarks: questiondata.remarks,
          sectionId: sectionid,
          heading: "Add Question",
        });
      }
      else {
        setQuestion({
          _id: "",
          question: "",
          points: "",
          remarks: "",
          sectionId: sectionid,
          heading: "Add Question",
        });
      }
    }
    // setQuestion({
    //   _id: "",
    //   question: "",
    //   points: "",
    //   remarks: "",
    //   sectionId: sectionid,
    //   heading: "Add Question",
    // });
    // }
  }

  async function editQuestion() {

    dispatch(saveQuestion(question));
  };

  async function saveQuesHandle() {

    if (savedaudittype._id !== "0" && (sectionid !== "0")) {
      const errors = validateRequiredFields(question, requiredFields);
      setFormErrors(errors);
      if (Object.keys(errors).length === 0) {
        setFormErrors({});
        dispatch(saveAuditTypeQuestion(question, savedaudittype));
        const idvalue = savedaudittype._id;
        let URL = `../../audit/audittypes/addaudittype/auditTypeSectionQuestion/${idvalue}`;
        navigate(URL);
      }
    } else {
      const errors = validateRequiredFields(question, requiredFields);
      setFormErrors(errors);
      if (Object.keys(errors).length === 0) {
        setFormErrors({});
        if (id === "0") {
          saveQuesData();

          navigate("/administarion/questions");
        } else {
          editQuestion();
          navigate("/administarion/questions");
        }
      }
    }
  }
  async function saveQuesData() {

    dispatch(saveQuestion(question));
  }
  async function cancelHandle() {

    if (savedaudittype._id !== "0" && (sectionid !== "0")) {
      const data = savedaudittype;
      const idvalue = data._id;
      navigate(`../../audit/audittypes/addaudittype/auditTypeSectionQuestion/${idvalue}`);
    } else {
      navigate("/administarion/questions");
    }
  }
  const validateRequiredFields = (values, requiredFields) => {
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!values[field] || values[field].trim() === '') {
        newErrors[field] = 'This field is required';
      }
    });

    return newErrors;
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  return (

    <div>
      <Navbar />
      <Box
        sx={{
          marginTop: 1,
          marginLeft: '15%',
          width: '70vw',
          height: '100%',
          backgroundColor: 'white',
          borderStyle: 'solid',
          borderColor: '#213555',
          borderWidth: 0.5
        }}
      >
        <Box
          sx={{
            mt: 0,
            width: '100%',
            height: 40,
            backgroundColor: '#22A699',
            borderStyle: '',
          }}
        >
          <Grid container>

            <Grid item><InputLabel id="addquestion"
              sx={{
                ml: 1,
                mt: 1,
                color: 'white',
              }}
            >{question.heading}</InputLabel>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            marginTop: 0,
            width: '100%',
            height: '100%',
            borderBlockColor: 'black',
            backgroundColor: 'white',
            display: "flex",
            flexDirection: "column",
          }}

        >
          <form autoComplete="off">
            <Grid container sx={{ mt: 2, ml: 1 }}>
              <Grid item xs lg>
                <FormControl
                  fullWidth
                  required
                  disabled={sectionDisabled}
                  error={!!formErrors.sectionId}
                  sx={{
                    mb: 3,
                    width: '33vw',
                    height: 50
                  }}>
                  <InputLabel id="Section">Select Section</InputLabel>
                  <Select
                    labelId="section"
                    required
                    id="sectionId"
                    label="Select Section"
                    name="sectionId"
                    value={question.sectionId}
                    onChange={(e) => updateForm({ sectionId: e.target.value })}
                    MenuProps={MenuProps}
                  >
                    {sectionlist.map((row) => (
                      <MenuItem value={row._id} key={row._id} >{row.section}</MenuItem>
                    ))
                    }

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs lg sx={{ mr: 1 }}>
                <TextField
                  label="Points"
                  placeholder='Enter Points'
                  required
                  variant="outlined"
                  color="primary"
                  value={question.points}
                  onChange={(e) => updateForm({ points: e.target.value })}
                  sx={{
                    mb: 3,
                    width: '33vw',
                    height: 50
                  }}
                  error={!!formErrors.points}
                />
              </Grid>

            </Grid>
            <Grid container sx={{ mt: 2, ml: 1 }}>
              <Grid item xs lg sx={{ mr: 1 }}>
                <TextField
                  label="Question"
                  placeholder='Enter Question'
                  required
                  variant="outlined"
                  color="primary"
                  multiline
                  rows={3}
                  name="name"
                  value={question.question}
                  onChange={(e) => updateForm({ question: e.target.value })}
                  sx={{
                    mb: 3,
                    width: '99%',
                    height: 40
                  }}
                  error={!!formErrors.question}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 8, ml: 1 }}>
              <Grid item xs lg sx={{ mr: 1 }}>
                <TextField
                  label="Remarks"
                  placeholder='Remarks'
                  variant="outlined"
                  color="primary"
                  multiline
                  rows={3}
                  value={question.remarks}
                  onChange={(e) => updateForm({ remarks: e.target.value })}
                  sx={{
                    mb: 3,
                    width: '99%',
                    height: 40
                  }}
                />
              </Grid>

            </Grid>
            <br /><br />
            <Box
              sx={{
                mt: 2,
                width: '100%',
                height: 50,
                backgroundColor: '#f5f5f5',
                borderStyle: '',
              }}
            >
              <Grid container justifyContent={'flex-end'}>
                <Grid item>
                  <Button variant="contained"
                    type='submit'
                    sx={{
                      mt: 1,
                      mb: -1,
                      backgroundColor: ' #213555 !important',
                    }}
                    onClick={() => saveQuesHandle()}
                  >Save</Button>&nbsp;&nbsp;
                  <Button variant="contained"
                    type='submit'
                    sx={{
                      mt: 1,
                      mb: -1,
                      mr: 1,
                      backgroundColor: ' #CFD2CF !important',
                      color: "black"
                    }}
                    onClick={() => cancelHandle()}
                  >Cancel</Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Box>
    </div>

  )
}
export default AddQuestion;