import React, { useEffect, useState, useRef } from 'react';
//import Dashboard from "../../Dashboard"
//import axios from "axios";
import { Select, MenuItem, InputLabel, FormControl, FormControlLabel, Checkbox, Paper, Radio, Typography, Dialog, DialogTitle, DialogActions } from '@mui/material';
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { getSurveyList, saveSurvey, getAuditTypesList, searchAuditTypes } from '../../store/surveySlice.js';
import DownloadIcon from '@mui/icons-material/Download';
import Navbar from "../../Navigation/Navbar";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function AddSurvey() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.surveyid;
  const surveydata = useSelector(({ surveys }) => surveys.surveysData);
  const auditTypeList = useSelector(({ surveys }) => surveys.auditTypeData);
  const auditnameslist = useSelector(({ surveys }) => surveys.auditnamelist);
  const reviewerslist = useSelector(({ surveys }) => surveys.reviewers);
  const [sectionquestionlist, setSectionQtnlist] = useState([]);
  const [selectedRadioValues, setSelectedRadioValues] = useState({});
  const [showTotal, setShowTotalvisible] = useState(false);
  const [TotalScore, setTotalScore] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [defaultChecked, setdefaultChecked] = React.useState(false)
  const [RoleId, setRoleId] = useState(localStorage.getItem('AccessId'))
  const [reviewerId, setReviewerid] = useState(localStorage.getItem('UserId'));
  const [drpdDisabled, setDisabled] = useState(false);
  const pdfRef = useRef();
  const pdfRef2 = useRef();
  const doc = new jsPDF();
  const [open, setOpen] = React.useState(false);
  const [pdfbtnDispaly, setPDFbtn] = React.useState(false);
  const [survey, setSurvey] = useState({
    _id: "",
    businessunit: "",
    claimnumber: "",
    lossdate: "",
    claimant: "",
    client: "",
    losscause: "",
    majorperil: "",
    examiner: "",
    assigndate: "",
    reviewer: "",
    audittypeId: "",
    sectionquestionlist: {},
    auditnameId: "",
    serviceprovider: "",
    totalScore: 0,
    reviewerId: "",
    heading: "Complete Survey",

  })

  function updateForm(value) {

    return setSurvey((prev) => {
      return { ...prev, ...value };
    });
  }
  const requiredFields = ["claimnumber", "claimant", "examiner", "audittypeId", "lossdate", 'auditnameId', 'serviceprovider', 'businessunit', 'client'];


  useEffect(() => {

    //dispatch(getSurveyList());
    dispatch(getAuditTypesList());
    editSurveydata();
    if (RoleId === "5e8fe1e9fb38c9ce40de0009") {
      setDisabled(true);
      updateForm({ reviewerId: reviewerId });
    }
  }, []);


  async function searchClick(audittypeId) {

    const SQlist = auditTypeList.filter(auditTypeList => auditTypeList._id === audittypeId);
    if (SQlist) {
      const SectionQuestionList = SQlist[0].SectionQuestionList;
      const updatedList = SectionQuestionList.map((section) => ({
        ...section,
        score: 0, // Set the initial score to 0 or any other default value
        questions: section.questions.map((question) => ({
          ...question,
          meettype: "", // Set the initial meettype to an empty string or any other default value
        })),
      }));
      setSectionQtnlist(updatedList);
    }
  }

  async function editSurveydata() {
    debugger;
    if (id !== "0") {
      let surveyrecord = surveydata.filter(surveydata => surveydata._id === id);
      // const Rlossdate = mongoDBDateToTextFieldDate(surveyrecord[0].lossdate.toString());
      setSurvey({
        _id: id,
        businessunit: surveyrecord[0].businessunit,
        claimnumber: surveyrecord[0].claimnumber,
        lossdate: mongoDBDateToTextFieldDate(surveyrecord[0].lossdate),
        claimant: surveyrecord[0].claimant,
        client: surveyrecord[0].client,
        losscause: surveyrecord[0].losscause,
        majorperil: surveyrecord[0].majorperil,
        examiner: surveyrecord[0].examiner,
        assigndate: mongoDBDateToTextFieldDate(surveyrecord[0].assigndate),
        reviewer: surveyrecord[0].reviewer,
        audittypeId: surveyrecord[0].audittypeId._id,
        auditnameId: surveyrecord[0].auditnameId ? surveyrecord[0].auditnameId._id : "",
        serviceprovider: surveyrecord[0].serviceprovider,
        reviewerId: surveyrecord[0].reviewerId,
        heading: "Edit Survey",
      });
      setSectionQtnlist(surveyrecord[0].sectionquestionlist)
      /**Code to set radio buttons select on edit mode */
      const initialValues = {};

      surveyrecord[0].sectionquestionlist.forEach((section) => {
        initialValues[section._id] = {};
        section.questions.forEach((question) => {
          initialValues[section._id][question._id] = question.meettype;
        });
      });

      setSelectedRadioValues(initialValues);
      CalculateTotalScore(surveyrecord[0].sectionquestionlist);

    }
  }


  async function saveSurveyHandle(e) {
debugger;
    e.preventDefault();

    if (id === "0") {
      const errors = validateRequiredFields(survey, requiredFields);
      setFormErrors(errors);
      if (Object.keys(errors).length === 0) {
        setFormErrors({});
        saveSurveyData();

        navigate("/administarion/surveys");
      }
    } else {
      // const errors = validateRequiredFields(survey, requiredFields);
      // setFormErrors(errors);
      // if (Object.keys(errors).length === 0) {
      //   setFormErrors({});
      editSurvey();
      navigate("/administarion/surveys");
      // }
    }

  }


  async function saveSurveyData() {

    survey.sectionquestionlist = sectionquestionlist;
    survey.totalScore = TotalScore;
    dispatch(saveSurvey(survey));
  }


  async function editSurvey() {

    survey.sectionquestionlist = sectionquestionlist;
    survey.totalScore = TotalScore;
    dispatch(saveSurvey(survey));
  }


  const validateRequiredFields = (values, requiredFields) => {
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!values[field] || values[field].trim() === '') {
        newErrors[field] = 'This field is required';
      }
    });

    return newErrors;
  };


  const handleAuditNameDropdownChange = (event) => {

    let auditnamereocrd = auditnameslist.filter(auditnamedata => auditnamedata._id === event.target.value);
    updateForm({ audittypeId: auditnamereocrd[0].audittypeId._id });
    updateForm({ serviceprovider: auditnamereocrd[0].serviceprovider });
    updateForm({ client: auditnamereocrd[0].client });
    updateForm({ businessunit: auditnamereocrd[0].businessunit });
    updateForm({ auditnameId: event.target.value });
    handleDropdownChange(auditnamereocrd[0].audittypeId._id)
  };


  const handleDropdownChange = (id) => {
    setSelectedRadioValues({});  // set all radio button false
    setTotalScore(0);  // set total score 0
    updateForm({ audittypeId: id });
    searchClick(id);
  };


  function handleQuestionResponse(sectionId, questionId, selectedValue) {

    // Create a deep copy of the sectionquestionlist object
    const updatedSectionQtnList = JSON.parse(JSON.stringify(sectionquestionlist));

    // Find the section in the array by its ID
    const sectionIndex = updatedSectionQtnList.findIndex(
      (section) => section._id === sectionId
    );
    if (sectionIndex !== -1) {
      // Find the question in the SectionQuestionList array by its ID
      const questionIndex = updatedSectionQtnList[sectionIndex].questions.findIndex(
        (question) => question._id === questionId
      );
      if (questionIndex !== -1) {
        // Update the meettype property for the question with the selected radio button value
        updatedSectionQtnList[sectionIndex].questions[questionIndex].meettype = selectedValue;
      }
      // Calculate the total points for each question in the section where meettype is "Yes"
      const sectionQuestions = updatedSectionQtnList[sectionIndex].questions;
      const totalPoints = sectionQuestions.reduce((total, question) => {
        if (question.meettype === "yes") {
         // return total + parseInt(question.points, 10); // Assuming points is a numeric value
         return total + parseFloat(question.points); // Assuming points is a numeric value
        }
        if (question.meettype === "partiallymeets") {
         // return total + parseInt(question.points, 10) / 2; // Assuming points is a numeric value
            return total + parseFloat(question.points) / 2; // Assuming points is a numeric value
        }
        return total;
      }, 0);

      // Update the score property of the section with the total points
      updatedSectionQtnList[sectionIndex].score = totalPoints;
    }
    // Set the updated copy back to the state
    setSectionQtnlist(updatedSectionQtnList);
    handleradiobuttonselection(sectionId, questionId, selectedValue);
    CalculateTotalScore(updatedSectionQtnList);
  }


  /**Code to hanlde radio button selection */
  function handleradiobuttonselection(sectionId, questionId, selectedValue) {
    // Create a deep copy of the selectedRadioValues object
    const updatedSelectedRadioValues = { ...selectedRadioValues };
    // Update the selected radio button value for the corresponding section and question
    if (!updatedSelectedRadioValues[sectionId]) {
      updatedSelectedRadioValues[sectionId] = {};
    }
    updatedSelectedRadioValues[sectionId][questionId] = selectedValue;
    // Set the updated copy back to the state
    setSelectedRadioValues(updatedSelectedRadioValues);
  }


  /**code to handle date binding issue  */
  const mongoDBDateToTextFieldDate = (mongoDate) => {
    const dateObj = new Date(mongoDate);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`
    //return date;
  };

  function CalculateTotalScore(list) {
    debugger;
    let overallTotal = list.reduce((acc, section) => acc + Math.round(section.score), 0);
  //  setTotalScore(parseInt(overallTotal, 10));
      setTotalScore(parseInt(overallTotal,10));
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // Function to handle notes change
  const handleNotesChange = (sectionId, notes) => {
    // Update the sectionquestionlist with the entered notes
    setSectionQtnlist((prevList) =>
      prevList.map((section) =>
        section._id === sectionId
          ? {
            ...section,
            notes: notes,
          }
          : section
      )
    );
  };
  // Function to handle criticalstatus change
  const handleCriticalStatusChange = (sectionId) => {
    // Update the sectionquestionlist with the entered criticalstatus
    setSectionQtnlist((prevList) =>
      prevList.map((section) =>
        section._id === sectionId
          ? {
            ...section,
            criticalstatus: !section.criticalstatus,
          }
          : section
      )
    );
  };
  /**For pdf */
  function getLabel(selectedValue) {
    switch (selectedValue) {
      case 'yes':
        return <Typography sx={{ fontSize: '8px' }}>Meets</Typography>;
      case 'no':
        return <Typography sx={{ fontSize: '8px' }}>Does Not Meet</Typography>;
      case 'partiallymeets':
        return <Typography sx={{ fontSize: '8px' }}>Partially Meets</Typography>;
      case 'na':
        return <Typography sx={{ fontSize: '8px' }}>N/A</Typography>;
      default:
        return ''; // Default label when no option is selected
    }

  }
  const getAuditName = (id) => {
    const auditnamerecord = auditnameslist.find(auditnamedata => auditnamedata._id === id);
    return auditnamerecord ? auditnamerecord.auditname : null;
  };
  const getAuditTypeName = (id) => {
    const record = auditTypeList.find(data => data._id === id);
    return record ? record.name : null;
  };
  const getReviwerName = (id) => {
    const record = reviewerslist.find(data => data._id === id);
    return record ? record.name : null;
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
 //old pdf generation method
  const generatePDF2 = () => {
    debugger;
    const content = pdfRef.current;
    const doc = new jsPDF({
      format: 'a4', // Page size: 'a4', 'letter', etc.
      orientation: 'portrait', // 'portrait' or 'landscape'
      unit: 'pt',
      lineHeightFactor: 1.5,
    });
    // Adding the fonts.
    doc.setFont('Arial', 'normal');
    doc.setFontSize(12);
    const date = new Date();
    const pdfFileName = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}_${date.getHours()}.${date.getMinutes()}.${date.getSeconds()}_SueveyDetails.pdf`;
    doc.html(content,{
      margin: [10, 4, 10,24], // Equal margins for top, right, bottom, and left
      html2canvas: { 
        scale: 1,
        useCORS: true,
        scrollX: 0,
        scrollY: 0,
        windowWidth: document.body.scrollWidth, // Capture the full width of the content
      },
      callback: function (doc) {
        doc.save(pdfFileName);
      }
    });
  };
// new pdf code
  const  generatePDF = async(e) => {
    /* New code changes for on pdf save survey Start*/
    if (id === "0") {
      const errors = validateRequiredFields(survey, requiredFields);
      setFormErrors(errors);
      if (Object.keys(errors).length === 0) {
        setFormErrors({});
        saveSurveyData();
        pdfgeneration();
      }
    } else {
      editSurvey();
      pdfgeneration();
    }


};
 function pdfgeneration()
 {
        /* New code changes for on pdf save survey end*/
  // Clone the element to avoid manipulating the actual DOM
  const clonedElement = pdfRef2.current.cloneNode(true);
    
  // Query all radio buttons inside the cloned element
  const radioButtons = clonedElement.querySelectorAll('input[type="radio"]');
  
  // Loop through the radio buttons and update their state in the HTML
  radioButtons.forEach(radio => {
    const radioMarker = document.createElement('span');
        
    // Apply common styles for both checked and unchecked states (ensuring the same size)
    radioMarker.style.display = 'inline-block';
    radioMarker.style.width = '10px';
    radioMarker.style.height = '10px';
    radioMarker.style.borderRadius = '50%';
    radioMarker.style.verticalAlign = 'middle'; // Aligns with text properly
    
    if (radio.checked) {
        // If checked, show a filled round (black dot inside)
   //     radioMarker.innerHTML = '●';
        radioMarker.style.border = '1px solid black'; // Hollow circle
        radioMarker.style.fontSize = '8px';
        radioMarker.style.color = 'black';
        radioMarker.style.textAlign = 'center'; // Ensures the dot is centered
        radioMarker.style.lineHeight = '8px'; // Vertically centers the dot
        radioMarker.style.backgroundColor = 'black';
    } else {
        // If unchecked, show a hollow round (only border)
        radioMarker.style.border = '1px solid black'; // Hollow circle
        radioMarker.style.textAlign = 'center';
        radioMarker.style.lineHeight = '8px';
    }
    radioMarker.style.marginRight = '2px';
      // Insert the custom marker after the radio button and remove the radio input
      radio.parentNode.insertBefore(radioMarker, radio.nextSibling); // Insert marker
      radio.remove(); // Remove the original radio input
  });

  // Get the updated inner HTML with custom radio markers
  const printContents = clonedElement.innerHTML;
    const fullPrintContent = `
    <html>
      <head>
        <title>&nbsp</title>
        <style>
          /* Full styling for print content */
          body {
            margin: 5px;
            padding: 0;
            font-size: 12px;
            font-family: 'Arial';
          }
          /* Custom radio button styling for print */
          .custom-radio .radio-mark {
            position: relative;
            display: inline-block;
            height: 12px;
            width: 12px;
            background-color: white;
            border: 2px solid black;
            border-radius: 50%;
            vertical-align: middle;
          }
          /* Checked state for radio buttons */
          .custom-radio input[type="radio"]:checked + .radio-mark {
            background-color: black;
          }
             @media print {
          /* Prevent content from breaking across pages */
            .no-page-break {
                page-break-inside: avoid;
               
              }
             
          }
        </style>
      </head>
      <body>
        ${printContents}
      </body>
    </html>
  `;


    // Open a new window for printing
    const printWindow = window.open('', '',);
    printWindow.document.write(fullPrintContent);
    printWindow.document.close(); // Close the document to signal that it is ready for printing
    printWindow.focus(); // Focus on the print window
    printWindow.print(); // Open the print dialog
    printWindow.close(); // Close the print window after printing
 }

  return (

    <div>
      <Navbar />
      <Box
        sx={{
          marginTop: 1,
          marginLeft: '10%',
          width: '80vw',
          height: '100%',
          backgroundColor: 'white',
          borderStyle: 'solid',
          borderColor: '#213555',
          borderWidth: 0.5
        }}
      >
        <Box
          sx={{
            mt: 0,
            width: '100%',
            height: 40,
            backgroundColor: '#22A699',
            borderStyle: '',
          }}
        >
          <Grid container>

            <Grid item><InputLabel id="addsurvey"
              sx={{
                ml: 1,
                mt: 1,
                color: 'white',
              }}
            >{survey.heading}</InputLabel>
            </Grid>
            <Grid item xs={12} // Ensures it takes up full width on small screens
              lg={2}
              sx={{
                marginTop: {lg:'7px',md:'-30px'},
                marginLeft: { xs: '0', lg: '70%' } // Margin for different screen sizes
              }}
            >

              <Button variant="contained"
                sx={{
                  backgroundColor: ' #213555 !important',
                  fontColor: 'black',
                  textTransform: 'none',
                  lineHeight: 1,
                  '@media (max-width: 600px)': { // Media query for small screens
                    maxWidth: '1rem',
                    fontSize: '0.1rem', // Adjust the font size for small screens
                  },
                  display: {
                    xs: 'none', // Show on small screens
                    sm: 'block', // Hide on medium screens and larger
                  },
                }}
               // onClick={() => handleClickOpen()} // removed for new pdf function
                 onClick={(e) => generatePDF(e)}
              >Save & Generate PDF</Button>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            marginTop: 0,
            width: '98.5%',
            height: '100%',
            borderBlockColor: 'black',
            backgroundColor: 'white',
            display: "flex",
            flexDirection: "column",
          }}
          disabled={true}
        >
          <div id="pdf-content">
            <form autoComplete="off">
              <Grid container spacing={1} alignItems="center" sx={{ mt: 1, ml: '10%' }}>
                <Grid item sx={{
                  mr: 2,
                  width: '100%', // Set the width to 100% to make it responsive
                  maxWidth: '15rem',
                }}>
                  <FormControl
                    size='small'
                    required
                    sx={{
                      mr: 2,
                      width: '100%', // Set the width to 100% to make it responsive
                      maxWidth: '15rem',
                    }}>
                    <InputLabel id="auditName">Select Audit Name</InputLabel>
                    <Select
                      labelId="auditname"
                      required
                      id="auditnameId"
                      label="Select Audit Type"
                      name="auditnameId"
                      value={survey.auditnameId}
                      onChange={handleAuditNameDropdownChange}
                      error={!!formErrors.audittypeId}
                      MenuProps={MenuProps}
                      sx={{
                        mr: 2,
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem',
                      }}
                    >
                      {auditnameslist.map((row) => (
                        <MenuItem
                          value={row._id}
                          key={row._id}
                          sx={{ padding: '0.1rem', width: '30%', maxWidth: '20vm' }}
                        >
                          {row.auditname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                </Grid>
                <Grid item sx={{ mr: 2 }}>
                  <TextField
                    //disabled
                    size="small"
                    label="Service provider"
                    placeholder='Enter Service provider'
                    type="text"
                    required
                    variant="outlined"
                    color="primary"
                    name="serviceprovider"
                    value={survey.serviceprovider}
                    onChange={(e) => updateForm({ serviceprovider: e.target.value })}
                    sx={{
                      mr: 2,
                      width: '100%', // Set the width to 100% to make it responsive
                      maxWidth: '15rem',
                    }}
                    error={!!formErrors.serviceprovider}
                  // helperText={formErrors.client || ''}
                  />
                </Grid>
                <Grid item sx={{
                  mr: 2,
                  width: '100%', // Set the width to 100% to make it responsive
                  maxWidth: '15rem',
                }}>

                  <FormControl
                    //  disabled
                    size='small'
                    required
                    sx={{
                      mr: 2,
                      width: '100%', // Set the width to 100% to make it responsive
                      maxWidth: '15rem',
                    }}>
                    <InputLabel id="auditType">Select Audit Type</InputLabel>
                    <Select
                      labelId="audittype"

                      id="audittypeId"
                      label="Select Audit Type"
                      name="audittypeId"
                      value={survey.audittypeId}
                      onChange={(e) => handleDropdownChange(e.target.value)}
                      error={!!formErrors.audittypeId}
                      MenuProps={MenuProps}
                      sx={{
                        mr: 2,
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem',
                      }}
                    >
                      {auditTypeList.map((row) => (
                        <MenuItem value={row._id} key={row._id}
                          sx={{ padding: '0.1rem', width: '30%', maxWidth: '30vm' }}
                        >
                          {row.name}
                        </MenuItem>
                      ))
                      }

                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sx={{ mr: 2 }}>
                  <TextField
                    //disabled
                    size="small"
                    label="Client"
                    placeholder='Enter client'
                    type="text"
                    required
                    variant="outlined"
                    color="primary"
                    name="client"
                    value={survey.client}
                    onChange={(e) => updateForm({ client: e.target.value })}
                    sx={{
                      mr: 2,
                      width: '100%', // Set the width to 100% to make it responsive
                      maxWidth: '15rem',
                    }}
                    error={!!formErrors.client}
                  // helperText={formErrors.client || ''}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="center" sx={{ mt: 1, ml: '10%' }}>
                <Grid item sx={{ mr: 2 }}>
                  <TextField
                    //disabled
                    size="small"
                    label="Business Unit"
                    placeholder='Enter Business Unit'
                    required
                    variant="outlined"
                    color="primary"
                    name="businessunit"
                    type="text"
                    value={survey.businessunit}
                    onChange={(e) => updateForm({ businessunit: e.target.value })}
                    sx={{
                      mr: 2,
                      width: '100%', // Set the width to 100% to make it responsive
                      maxWidth: '15rem',
                    }}
                    error={!!formErrors.businessunit}
                  // helperText={formErrors.businessunit || ''}
                  />

                </Grid>
                <Grid item sx={{ mr: 2 }}>
                  <TextField
                    size="small"
                    label="Claimant"
                    placeholder='Enter Claimant Name'
                    type="text"
                    required
                    variant="outlined"
                    color="primary"
                    name="claimant"
                    value={survey.claimant}
                    onChange={(e) => updateForm({ claimant: e.target.value })}
                    sx={{
                      mr: 2,
                      width: '100%', // Set the width to 100% to make it responsive
                      maxWidth: '15rem',
                    }}
                    error={!!formErrors.claimant}
                  // helperText={formErrors.claimant || ''}
                  />
                </Grid>
                <Grid item sx={{
                  mr: 2,
                  width: '100%', // Set the width to 100% to make it responsive
                  maxWidth: '15rem',

                }}>
                  <TextField
                    size="small"
                    label="Loss Date"
                    placeholder=''
                    type="date"
                    required
                    variant="outlined"
                    color="primary"
                    name="lossdate"
                    value={survey.lossdate}
                    onChange={(e) => updateForm({ lossdate: e.target.value })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      mr: 2,
                      width: '100%', // Set the width to 100% to make it responsive
                      maxWidth: '15rem',

                    }}
                    error={!!formErrors.lossdate}
                  // helperText={formErrors.lossdate || ''}
                  />
                </Grid>
                <Grid item sx={{
                  //  mr: 2,
                  width: '100%', // Set the width to 100% to make it responsive
                  maxWidth: '15rem',

                }}>
                  <TextField
                    size="small"
                    label="Assign Date"
                    placeholder=''
                    type="date"
                    //required
                    variant="outlined"
                    color="primary"
                    name="assigndate"
                    value={survey.assigndate}
                    onChange={(e) => updateForm({ assigndate: e.target.value })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      //  mr: 2,
                      width: '100%', // Set the width to 100% to make it responsive
                      maxWidth: '16rem',
                    }}
                    error={!!formErrors.assigndate}
                  // helperText={formErrors.assigndate || ''}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="center" sx={{ mt: 1, ml: '10%' }}>

                <Grid item sx={{ mr: 2 }}>
                  <TextField
                    size="small"
                    label="Loss Cause"
                    placeholder='Enter Loss Cause'
                    type="text"
                    // required
                    variant="outlined"
                    color="primary"
                    name="losscause"
                    value={survey.losscause}
                    onChange={(e) => updateForm({ losscause: e.target.value })}
                    sx={{
                      mr: 2,
                      width: '100%', // Set the width to 100% to make it responsive
                      maxWidth: '15rem',
                    }}
                    error={!!formErrors.losscause}
                  // helperText={formErrors.losscause || ''}
                  />
                </Grid>
                <Grid item sx={{ mr: 2 }}>
                  <TextField
                    size="small"
                    label="Major Peril"
                    placeholder='Enter Major Peril'
                    type="text"
                    //required
                    variant="outlined"
                    color="primary"
                    name="majorperil"
                    value={survey.majorperil}
                    onChange={(e) => updateForm({ majorperil: e.target.value })}
                    sx={{
                      mr: 2,
                      width: '100%', // Set the width to 100% to make it responsive
                      maxWidth: '15rem',

                    }}
                    error={!!formErrors.majorperil}
                  // helperText={formErrors.majorperil || ''}
                  />
                </Grid>
                <Grid item sx={{ mr: 2 }}>
                  <TextField
                    size="small"
                    label="Examiner"
                    placeholder='Enter Examiner'
                    type="text"
                    required
                    variant="outlined"
                    color="primary"
                    name="examiner"
                    value={survey.examiner}
                    onChange={(e) => updateForm({ examiner: e.target.value })}
                    sx={{
                      mr: 2,
                      width: '100%', // Set the width to 100% to make it responsive
                      maxWidth: '15rem',

                    }}
                    error={!!formErrors.examiner}
                  // helperText={formErrors.examiner || ''}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="center" sx={{ mt: 1, ml: '10%' }}>
                <Grid item sx={{
                  mr: 2, width: '100%', // Set the width to 100% to make it responsive
                  maxWidth: '15rem',
                }}>
                  {/* <TextField
                  size="small"
                  label="Reviewer"
                  placeholder='Enter Reviewer'
                  type="text"
                  //required
                  variant="outlined"
                  color="primary"
                  name="reviewer"
                  value={survey.reviewer}
                  onChange={(e) => updateForm({ reviewer: e.target.value })}
                  sx={{
                    mr: 2,
                    width: '100%', // Set the width to 100% to make it responsive
                    maxWidth: '15rem',
                  }}
                  error={!!formErrors.reviewer}
                // helperText={formErrors.reviewer || ''}
                /> */}
                  <FormControl
                    size='small'
                    //required
                    disabled={drpdDisabled}
                    sx={{
                      mr: 2,
                      width: '100%', // Set the width to 100% to make it responsive
                      maxWidth: '15rem',
                    }}>
                    <InputLabel id="reviewer">Select Reviewer</InputLabel>
                    <Select
                      labelId="reviewer"
                      //required
                      id="reviewerId"
                      label="Select Reviewer"
                      name="reviewerId"
                      value={survey.reviewerId}
                      onChange={(e) => updateForm({ reviewerId: e.target.value })}
                      //error={!!formErrors.audittypeId}
                      MenuProps={MenuProps}
                      sx={{
                        mr: 2,
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem',
                      }}
                    >
                      {reviewerslist.map((row) => (
                        <MenuItem
                          value={row._id}
                          key={row._id}
                          sx={{ padding: '0.1rem', width: '30%', maxWidth: '20vm' }}
                        >
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sx={{ mr: 2 }}>
                  <TextField
                    size="small"
                    label="Claim Number"
                    placeholder='Enter Claim Number'
                    type="text"
                    required
                    variant="outlined"
                    color="primary"
                    name="claimnumber"
                    value={survey.claimnumber}
                    onChange={(e) => updateForm({ claimnumber: e.target.value })}
                    sx={{
                      mr: 2,
                      width: '100%', // Set the width to 100% to make it responsive
                      maxWidth: '15rem',

                    }}
                    error={!!formErrors.claimnumber}
                  // helperText={formErrors.claimnumber || ''}
                  />

                </Grid>

                <Grid item xs={3} style={{ display: 'flex', alignItems: 'flex-center', }}>
                  <Typography sx={{
                    color: '#272829', fontFamily: 'Arial', fontWeight: 'bold',
                    borderBlockColor: 'black', ml: '10%', width: '10vw', height: '5vh', mt: 1
                    , alignItems: 'center'
                    , display: 'flex', // Center the text horizontally
                    justifyContent: 'center', // Center the text vertically
                    backgroundColor: '#CFD2CF', // Add the desired background color
                  }}
                    variant="" gutterBottom component="div">
                    Total Score: {TotalScore}</Typography>
                </Grid>

              </Grid>
              <Grid container sx={{ mt: 2, ml: 1 }}>
                {sectionquestionlist.map((row) => (
                  <TableContainer component={Paper}>

                    <Table sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold' }} size='small' aria-label="Survey table">

                      <TableHead sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold', fontSize: '0.975rem !important', backgroundColor: '#898B8A', color: 'white' }}>

                        <TableRow>
                          <TableCell sx={{ color: 'white', }}>{row.sectionname}</TableCell>
                          <TableCell sx={{ color: 'white', }} align='right'>
                            <Typography sx={{ color: 'White', fontFamily: 'Arial', fontWeight: 'bold' }}
                              variant="" gutterBottom component="div">
                              <FormControlLabel
                                sx={{
                                  mr: '20%'
                                }}
                                control={<Checkbox sx={{ padding: '0px' }}
                                  checked={row.criticalstatus || false}
                                  name="criticalstatus"
                                  value={row.criticalstatus}
                                  onChange={(e) => handleCriticalStatusChange(row._id)}
                                />}
                                label="Critical" />
                              {/* Score: {row.score}  */}
                            Score: {row.score ? Math.round(row.score) : "0"}
                             
                            </Typography>

                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.questions.map((questiondata) => (
                          <>
                            <TableRow
                              key={questiondata._id}
                            >
                              <TableCell sx={{ width: '50%' }}>{questiondata.question}</TableCell>
                              <TableCell sx={{ width: '40%' }} align='right'>
                                <FormControlLabel
                                  control={<Radio
                                    name={`mt-${row._id}-${questiondata._id}`}
                                    value="yes"
                                    checked={selectedRadioValues[row._id]?.[questiondata._id] === 'yes'}// Check selected value for this question
                                    onChange={() => handleQuestionResponse(row._id, questiondata._id, 'yes')}
                                  />}
                                  label="Meets"
                                />
                                <FormControlLabel
                                  control={<Radio
                                    name={`mt-${row._id}-${questiondata._id}`}
                                    value="no"
                                    checked={selectedRadioValues[row._id]?.[questiondata._id] === 'no'}// Check selected value for this question
                                    onChange={() => handleQuestionResponse(row._id, questiondata._id, 'no')}
                                  />}
                                  label="Does Not Meet"
                                />
                                <FormControlLabel
                                  control={<Radio
                                    name={`mt-${row._id}-${questiondata._id}`}
                                    value="partiallymeets"
                                    checked={selectedRadioValues[row._id]?.[questiondata._id] === 'partiallymeets'}// Check selected value for this question
                                    onChange={() => handleQuestionResponse(row._id, questiondata._id, 'partiallymeets')}
                                  />}
                                  label="Partially Meets"
                                />
                                <FormControlLabel
                                  control={<Radio
                                    name={`mt-${row._id}-${questiondata._id}`}
                                    value="na"
                                    checked={selectedRadioValues[row._id]?.[questiondata._id] === 'na'}// Check selected value for this question
                                    onChange={() => handleQuestionResponse(row._id, questiondata._id, 'na')}
                                  />}
                                  label="N/A"
                                />
                              </TableCell>

                            </TableRow>

                          </>
                        ))}
                        <TableRow key={`${row._id}-notes`}>
                          <TableCell colSpan={2}>
                            <TextField
                              label="Notes"
                              fullWidth
                              value={row.notes || ''}
                              multiline // Allow the field to be multiline
                              maxRows={10} // Optional: Set a maximum number of rows
                              variant="outlined" // Optional: Specify variant for better appearance
                              onChange={(e) => handleNotesChange(row._id, e.target.value)}
                            />
                          </TableCell>
                        </TableRow>
                        {/* <TableRow key={`${row._id}-critical`}>
                        <TableCell colSpan={2}>
                          <FormControlLabel
                            control={<Checkbox
                              checked={row.criticalstatus || false}
                              name="criticalstatus"
                              value={row.criticalstatus}
                              onChange={(e) => handleCriticalStatusChange(row._id)}
                            />}
                            label="Critical" />
                        </TableCell>
                      </TableRow> */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ))}
              </Grid>

              <br /><br />
              <Box
                sx={{

                  width: '100%',
                  height: 50,
                  backgroundColor: '#f5f5f5',
                  borderStyle: '',
                }}
              >
                <Grid container justifyContent={'flex-end'}>
                  <Grid item>
                    <Button variant="contained"
                      type='submit'
                      sx={{
                        mt: 1,
                        mb: -1,
                        backgroundColor: ' #213555 !important',
                      }}
                      onClick={saveSurveyHandle}
                    >Save</Button>&nbsp;&nbsp;
                    <Button variant="contained"
                      type='submit'
                      sx={{
                        mt: 1,
                        mb: -1,
                        backgroundColor: ' #CFD2CF !important',
                        color: "black"
                      }}
                      onClick={() => navigate("/administarion/surveys")}
                    >Cancel</Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </div>
        </Box>
      </Box>

      {/* PDF HTML old */}
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
         <div> <Button onClick={generatePDF} sx={{
            ml: "70%", color: 'red', textTransform: 'none',
            lineHeight: 1.8,
          }}>Download</Button>
          </div>
          <div style={{ width: '100%', fontSize: '8px', fontFamily: 'Arial'}} id="pdfhtml" ref={pdfRef}>
            <div style={{ marginTop:'5%'}}> 
              <div style={{ marginBottom: '2%' }}> <span style={{ marginLeft: '40%', fontWeight: 'bold', fontSize: '10px' }}>Survey Details</span></div>
              <div style={{ border: '1px solid', marginTop: '1px' ,padding: '8px'}}>
                <Grid container spacing={1} marginTop='1px' marginBottom='2px' marginLeft='2px'>
                  <Grid item xs={12} sm={6} md={4}>
                    <div style={{ display: 'flex', alignItems: 'center' ,flexWrap: 'wrap'}}>
                      <span style={{ fontWeight: 'bold', flexBasis: '35%' }}>Audit Name:</span>
                      <span style={{ flex:1,overflowWrap: 'break-word' ,}}> {getAuditName(survey.auditnameId)}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div style={{ display: 'flex', alignItems: 'center' ,flexWrap: 'wrap'}}>
                      <span style={{ fontWeight: 'bold', flexBasis: '35%' }}>Audit Type:</span>
                      <span style={{ flex:1,overflowWrap: 'break-word' ,}}>{getAuditTypeName(survey.audittypeId)}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div style={{ display: 'flex', alignItems: 'center',flexWrap: 'wrap' }}>
                      <span style={{ fontWeight: 'bold', flexBasis: '35%' }}>Service Provider:</span>
                      <span style={{  flex:1,overflowWrap: 'break-word' , }}>{survey.serviceprovider}</span>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={1}  marginBottom='2px' marginLeft='2px'>
                  <Grid item xs={12} sm={6} md={4}>
                    <div style={{ display: 'flex', alignItems: 'center',flexWrap: 'wrap' }}>
                      <span style={{ fontWeight: 'bold', flexBasis: '35%' }}>Client: </span>
                      <span style={{ flex:1,overflowWrap: 'break-word' ,  }}>{survey.client}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div style={{ display: 'flex', alignItems: 'center',flexWrap: 'wrap' }}>
                      <span style={{ fontWeight: 'bold', flexBasis: '35%' }}>Business Unit:</span>
                      <span style={{ flex:1,overflowWrap: 'break-word' ,  }}>{survey.businessunit}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div style={{ display: 'flex', alignItems: 'center',flexWrap: 'wrap' }}>
                      <span style={{ fontWeight: 'bold', flexBasis: '35%' }}>Claimant:</span>
                      <span style={{flex:1,overflowWrap: 'break-word' ,  }}>{survey.claimant}</span>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={1} marginBottom='2px' marginLeft='2px'>
                  <Grid item xs={12} sm={6} md={4}>
                    <div style={{ display: 'flex', alignItems: 'center',flexWrap: 'wrap' }}>
                      <span style={{ fontWeight: 'bold', flexBasis: '35%' }}>Loss Date:</span>
                      <span style={{ flex: 1 ,overflowWrap: 'break-word' }}>{survey.lossdate}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div style={{ display: 'flex', alignItems: 'center',flexWrap: 'wrap' }}>
                      <span style={{ fontWeight: 'bold', flexBasis: '35%' }}>Assign Date:</span>
                      <span style={{ flex:1,overflowWrap: 'break-word' ,  }}>{survey.assigndate}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div style={{ display: 'flex', alignItems: 'center',flexWrap: 'wrap' }}>
                      <span style={{ fontWeight: 'bold', flexBasis: '35%' }}>Loss Cause:</span>
                      <span style={{  flex:1,overflowWrap: 'break-word' , }}>{survey.losscause}</span>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={1} marginBottom='2px' marginLeft='2px'>
                  <Grid item xs={12} sm={6} md={4}>
                    <div style={{ display: 'flex', alignItems: 'center',flexWrap: 'wrap' }}>
                      <span style={{ fontWeight: 'bold', flexBasis: '35%' }}>Reviewer:</span>
                      <span style={{flex:1,overflowWrap: 'break-word' ,   }}>{getReviwerName(survey.reviewerId)}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div style={{ display: 'flex', alignItems: 'center',flexWrap: 'wrap' }}>
                      <span style={{ fontWeight: 'bold', flexBasis: '35%' }}>Examiner:</span>
                      <span style={{ flex:1,overflowWrap: 'break-word' ,  }}>{survey.examiner}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div style={{ display: 'flex', alignItems: 'center',flexWrap: 'wrap' }}>
                      <span style={{ fontWeight: 'bold', flexBasis: '35%' }}>Claim Number:</span>
                      <span style={{ flex:1,overflowWrap: 'break-word' ,  }}>{survey.claimnumber}</span>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={1}  marginLeft='2px' marginBottom='1%'>
                  <Grid item xs={12} sm={6} md={4}>
                    <div style={{ display: 'flex', alignItems: 'center',flexWrap: 'wrap' }}>
                      <span style={{ fontWeight: 'bold', flexBasis: '35%' }}>Major Peril:</span>
                      <span style={{ flex:1,overflowWrap: 'break-word' , }}>{survey.majorperil}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div style={{ display: 'flex', alignItems: 'center',flexWrap: 'wrap' }}>
                      <span style={{ fontWeight: 'bold', flexBasis: '35%' }}>Total Score:</span>
                      <span style={{ flex:1,overflowWrap: 'break-word'  }}>{TotalScore}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                    </div>
                  </Grid>
                </Grid>
              </div>
              <div style={{ marginTop: '1%' }}>
                {sectionquestionlist.map((row) => (
                  <div key={row.sectionname} style={{ marginTop: '10px' }}>
                    <div style={{ backgroundColor: '#898B8A', color: 'white', display: 'flex', alignItems: 'center', padding: '2px' }}>
                      <div style={{ width: '70%', whiteSpace: 'nowrap', marginLeft: '30px' }}>{row.sectionname}</div>
                      <div style={{ width: '30%', whiteSpace: 'nowrap' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ marginLeft: '20%' }}>
                            Critical: {row.criticalstatus ? 'Yes' : 'No'}
                          </div>
                          <div style={{ marginLeft: '15%' }}>Score: {row.score}</div>
                        </div>
                      </div>
                    </div>

                    <table style={{ width: '100%' ,borderCollapse: 'collapse' }}>
                      <thead>
                        <tr>
                          <th style={{ width: '60%', align: 'center' }}>Questions</th>
                          <th style={{ width: '7%'}}></th>
                          <th style={{ width: '14%' }}></th>
                          <th style={{ width: '13%' }}></th>
                          <th style={{ width: '7%' }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {row.questions.map((questiondata) => (
                          <tr key={questiondata._id} >
                            <td style={{paddingBottom:'5px'}} >{questiondata.question}</td>
                            <td style={{ textAlign: 'center' }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <input
                                  style={{ margin: '0.5px', transform: 'scale(0.70)'}}
                                  type="radio"
                                   name={'op1' + questiondata._id}
                                   value={'op1' + questiondata._id}
                                   checked = {((selectedRadioValues[row._id]?.[questiondata._id]) === 'yes')?true:false}
                                   disabled
                                />
                                <label style={{ marginLeft: '1px' }}>Meet</label>
                              </div>
                            </td>

                            {/* Does Not Meet */}
                            <td style={{ textAlign: 'center' }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <input
                                  style={{ margin: '0.5px', transform: 'scale(0.70)' }}
                                  type="radio"
                                  name={'op2' + questiondata._id}
                                  value={'op2' + questiondata._id}
                                  checked = {((selectedRadioValues[row._id]?.[questiondata._id]) === 'no')?true:false}
                                  disabled
                                />
                                <label style={{ marginLeft: '1px' }}>Does Not Meet</label>
                              </div>
                            </td>

                            {/* Partially Meet */}
                            <td style={{ textAlign: 'center' }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <input
                                  style={{ margin: '0.5px', transform: 'scale(0.70)' }}
                                  type="radio"
                                  name={'op3' + questiondata._id}
                                  value={'op3' + questiondata._id}
                                  checked = {((selectedRadioValues[row._id]?.[questiondata._id]) === 'partiallymeets')?true:false}
                                  disabled
                                />
                                <label style={{ marginLeft: '1px' }}>Partially Meet</label>
                              </div>
                            </td>

                            {/* N/A */}
                            <td style={{ textAlign: 'center' }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <input
                                  style={{ margin: '0.5px', transform: 'scale(0.70)' }}
                                  type="radio"
                                  name={'op4' + questiondata._id}
                                  value={'op4' + questiondata._id}
                                  checked = {((selectedRadioValues[row._id]?.[questiondata._id]) === 'na')?true:false}
                                  disabled
                                />
                                <label style={{ marginLeft: '1px' }}>N/A</label>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot style={{border:'1px solid Gray'}}>
                        <tr>
                          <td colSpan="5">
                            <div style={{ display: 'flex', alignItems: 'left' }}>
                              <span style={{ fontWeight: 'bold' }}>Notes:</span>
                              <span style={{marginLeft:'2px', overflowWrap: 'break-word', wordBreak: 'break-word'}}>{row.notes}</span>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

{/* PDF HTML New */} 
 <div style={{ display:'none'}}>
  <div style={{ width: '100%' }} id="pdfhtml" ref={pdfRef2}>
  <div style={{ }}>
    <div style={{ marginBottom: '2%' }}>
      <span style={{ marginLeft: '40%', fontWeight: 'bold', fontSize:14}}>Survey Details</span>
    </div>
    <div style={{ border: '1px solid', marginTop: '1px', padding: '8px' }}>
      {/* Row 1 */}
            <div style={{ display: 'flex', flexWrap: 'nowrap', marginBottom: '8px' }}>

              <div style={{ width: '33.33%', display: 'flex', alignItems: 'baseline', paddingRight: '8px', boxSizing: 'border-box' }}>
                <span style={{ fontWeight: 'bold', flexShrink: 0, whiteSpace: 'nowrap' }}>Audit Name:</span>
                <span style={{ flex: 1, overflowWrap: 'break-word', marginLeft: '4px' }}>{getAuditName(survey.auditnameId)}</span>
              </div>


              <div style={{ width: '33.33%', display: 'flex', alignItems: 'baseline', paddingRight: '8px', boxSizing: 'border-box' }}>
                <span style={{ fontWeight: 'bold', flexShrink: 0, whiteSpace: 'nowrap' }}>Audit Type:</span>
                <span style={{ flex: 1, overflowWrap: 'break-word', marginLeft: '4px' }}>{getAuditTypeName(survey.audittypeId)}</span>
              </div>


              <div style={{ width: '33.33%', display: 'flex', alignItems: 'baseline', boxSizing: 'border-box' }}>
                <span style={{ fontWeight: 'bold', flexShrink: 0, whiteSpace: 'nowrap' }}>Service Provider:</span>
                <span style={{ flex: 1, overflowWrap: 'break-word', marginLeft: '4px' }}>{survey.serviceprovider}</span>
              </div>
            </div>



      {/* Row 2 */}
            <div style={{ display: 'flex', flexWrap: 'nowrap', marginBottom: '8px' }}>
              <div style={{ width: '33.33%', display: 'flex', alignItems: 'baseline', paddingRight: '8px', boxSizing: 'border-box' }}>
                <span style={{ fontWeight: 'bold', flexShrink: 0 }}>Client :</span>
                <span style={{ flex: 1, overflowWrap: 'break-word' ,marginLeft: '4px'}}>{survey.client}</span>
              </div>
              <div style={{ width: '33.33%', display: 'flex', alignItems: 'baseline', paddingRight: '8px', boxSizing: 'border-box' }}>
                <span style={{ fontWeight: 'bold', flexShrink: 0 }}>Business Unit :</span>
                <span style={{ flex: 1, overflowWrap: 'break-word' ,marginLeft: '4px'}}>{survey.businessunit}</span>
              </div>
              <div style={{ width: '33.33%', display: 'flex', alignItems: 'baseline', boxSizing: 'border-box' }}>
                <span style={{ fontWeight: 'bold', flexShrink: 0 }}>Claimant :</span>
                <span style={{ flex: 1, overflowWrap: 'break-word',marginLeft: '4px' }}>{survey.claimant}</span>
              </div>
            </div>
      {/* Row 3 */}
            <div style={{ display: 'flex', flexWrap: 'nowrap', marginBottom: '8px' }}>
              <div style={{ width: '33.33%', display: 'flex', alignItems: 'baseline', paddingRight: '8px', boxSizing: 'border-box' }}>
                <span style={{ fontWeight: 'bold', flexShrink: 0 }}>Loss Date :</span>
                <span style={{ flex: 1, overflowWrap: 'break-word' ,marginLeft: '4px'}}>{survey.lossdate}</span>
              </div>
              <div style={{ width: '33.33%', display: 'flex', alignItems: 'baseline', paddingRight: '8px', boxSizing: 'border-box' }}>
                <span style={{ fontWeight: 'bold', flexShrink: 0 }}>Assign Date :</span>
                <span style={{ flex: 1, overflowWrap: 'break-word',marginLeft: '4px' }}>{survey.assigndate}</span>
              </div>
              <div style={{ width: '33.33%', display: 'flex', alignItems: 'baseline', boxSizing: 'border-box' }}>
                <span style={{ fontWeight: 'bold', flexShrink: 0 }}>Loss Cause :</span>
                <span style={{ flex: 1, overflowWrap: 'break-word',marginLeft: '4px' }}>{survey.losscause}</span>
              </div>
            </div>
      {/* Additional Rows with similar structure... */}

      {/* Row 6 */}
            <div style={{ display: 'flex', flexWrap: 'nowrap', marginBottom: '8px' }}>
              <div style={{ width: '33.33%', display: 'flex', alignItems: 'baseline', paddingRight: '8px', boxSizing: 'border-box' }}>
                <span style={{ fontWeight: 'bold', flexShrink: 0 }}>Reviewer :</span>
                <span style={{ flex: 1, overflowWrap: 'break-word',marginLeft: '4px' }}>{getReviwerName(survey.reviewerId)}</span>
              </div>
              <div style={{ width: '33.33%', display: 'flex', alignItems: 'baseline', paddingRight: '8px', boxSizing: 'border-box' }}>
                <span style={{ fontWeight: 'bold', flexShrink: 0 }}>Examiner :</span>
                <span style={{ flex: 1, overflowWrap: 'break-word' ,marginLeft: '4px'}}>{survey.examiner}</span>
              </div>
              <div style={{ width: '33.33%', display: 'flex', alignItems: 'baseline', boxSizing: 'border-box' }}>
                <span style={{ fontWeight: 'bold', flexShrink: 0 }}>Claim Number :</span>
                <span style={{ flex: 1, overflowWrap: 'break-word',marginLeft: '4px' }}>{survey.claimnumber}</span>
              </div>
            </div>

            <div style={{ display: 'flex', flexWrap: 'nowrap', marginBottom: '8px' }}>
              <div style={{ width: '33.33%', display: 'flex', alignItems: 'baseline', paddingRight: '8px', boxSizing: 'border-box' }}>
                <span style={{ fontWeight: 'bold', flexShrink: 0 }}>Major Peril :</span>
                <span style={{ flex: 1, overflowWrap: 'break-word',marginLeft: '4px' }}>{getReviwerName(survey.majorperil)}</span>
              </div>
              <div style={{ width: '33.33%', display: 'flex', alignItems: 'baseline', paddingRight: '8px', boxSizing: 'border-box' }}>
                <span style={{ fontWeight: 'bold', flexShrink: 0 }}>Total Score :</span>
                <span style={{ flex: 1, overflowWrap: 'break-word',marginLeft: '4px' }}>{TotalScore}</span>
              </div>
              <div style={{ width: '33.33%', display: 'flex', alignItems: 'baseline', boxSizing: 'border-box' }}>
                <span style={{ fontWeight: 'bold', flexShrink: 0 }}></span>
                <span style={{ flex: 1, overflowWrap: 'break-word' ,marginLeft: '4px'}}></span>
              </div>
            </div>
      
    </div>

    {/* Section for Additional Content */}
    <div style={{ marginTop: '1%' }} className=''>
      {sectionquestionlist.map((row) => (
        <div key={row.sectionname} style={{ marginTop: '10px' }}>
          <div style={{ backgroundColor: 'gray', color: 'Black', display: 'flex', alignItems: 'center', padding: '2px' }}>
            <div style={{ width: '65%', whiteSpace: 'nowrap', marginLeft: '30px' }}>{row.sectionname}</div>
            <div style={{ width: '35%', whiteSpace: 'nowrap' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{  }}>Critical: {row.criticalstatus ? 'Yes' : 'No'}</div>
                <div style={{ marginLeft: '15%' }}>Score: {row.score ? Math.round(row.score) : "0"}</div>
              </div>
            </div>
          </div>
          <div className="section">
          <table style={{ width: '100%', borderCollapse: 'collapse' , fontSize:11, fontFamily:'Arial'}}>
            <thead>
              <tr>
                <th style={{ width: '60%', textAlign: 'center' }}>Questions</th>
                <th style={{ width: '7%' }}></th>
                <th style={{ width: '14%' }}></th>
                <th style={{ width: '13%' }}></th>
                <th style={{ width: '7%' }}></th>
              </tr>
            </thead>
            <tbody>
              {row.questions.map((questiondata) => (
                <tr key={questiondata._id}>
                  <td style={{ paddingBottom: '5px' }} className='no-page-break'>{questiondata.question}</td>
                  <td style={{ textAlign: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <input
                        style={{ margin: '0.5px', transform: 'scale(0.70)',border: '2px solid Red' }}
                        type="radio"
                        name={'op1' + questiondata._id}
                        value={'op1' + questiondata._id}
                        checked={((selectedRadioValues[row._id]?.[questiondata._id]) === 'yes')?true:false}
                       // disabled
                      />
                      <label style={{ marginLeft: '1px' }}>Meet</label>
                    </div>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <input
                        style={{ margin: '0.5px', transform: 'scale(0.70)',color:'red' }}
                        type="radio"
                        name={'op2' + questiondata._id}
                        value={'op2' + questiondata._id}
                        checked={((selectedRadioValues[row._id]?.[questiondata._id]) === 'no')?true:false}
                       // disabled
                      />
                      <label style={{ marginLeft: '1px' }}>Does Not Meet</label>
                    </div>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <input
                        style={{ margin: '0.5px', transform: 'scale(0.70)' }}
                        type="radio"
                        name={'op3' + questiondata._id}
                        value={'op3' + questiondata._id}
                        checked={((selectedRadioValues[row._id]?.[questiondata._id]) === 'partiallymeets')?true:false}
                        //disabled
                      />
                      <label style={{ marginLeft: '1px' }}>Partially Meet</label>
                    </div>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <input
                        style={{ margin: '0.5px', transform: 'scale(0.70)' }}
                        type="radio"
                        name={'op4' + questiondata._id}
                        value={'op4' + questiondata._id}
                        checked={((selectedRadioValues[row._id]?.[questiondata._id]) === 'na')?true:false}
                        //disabled
                      />
                      <label style={{ marginLeft: '1px' }}>NA</label>
                    </div>
                  </td>
                  {/* Repeat for other options (Does Not Meet, Partially Meet, N/A) */}
                </tr>
                
              ))}
              <tr style={{ border: '1px solid Gray' }}>
                <td colSpan="5">
                  <div style={{ display: 'flex', alignItems: 'left' }}>
                    <span style={{ fontWeight: 'bold' }}>Notes:</span>
                    <span style={{ marginLeft: '2px', overflowWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>{row.notes}</span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot  className='notes-footer'>
              
            </tfoot>
          </table>
          </div>
        </div>
      ))}
    </div>
  </div>
  </div>
</div>
 
         </div >
  )
}
export default AddSurvey;