import { Button, DialogContent, DialogTitle, Dialog, DialogActions } from '@mui/material';
import React from 'react';



function ConfirmationDialog(props) {
    const { onClose, value: valueProp, open, ...other } = props;
    //const { t } = useTranslation("ComponentPage");
    const handleCancel = () => {
        onClose(false);
    };

    const handleOk = () => {
        onClose(true);
    };

    return (
        <Dialog style={{ marginBottom: props.marginBottom }}
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}>
            <DialogTitle id="confirmation-dialog-title" color="primary">{("CONFIRM")}</DialogTitle>
            <DialogContent dividers>
                <div className='m-16'>
                    {props.text}
                </div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} variant="contained" color="primary">
                    {("NO")}
                </Button>
                <Button onClick={handleOk} variant="contained" color="primary">
                    {("YES")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;
