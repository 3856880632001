import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Grid, Paper, InputLabel, TextField, FormControl, Select, MenuItem, FormControlLabel, Tooltip, Fab } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { getauditNamesList, deleteauditNames, searchauditNames } from '../../store/auditNameSlice';
import { getAuditTypesList } from '../../store/surveySlice';
import AddIcon from '@mui/icons-material/Add';
import StorageIcon from '@mui/icons-material/Storage';
import ConfirmationDialog from '../../commonui/confirmation';
import Navbar from "../../Navigation/Navbar";
import Message from '../../commonui/snackbar';
function AuditNames() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auditnameslist = useSelector(({ auditnames }) => auditnames.auditNamessData);
    const TotalCount = useSelector(({ auditnames }) => auditnames.TotalCount);
    const auditTypeList = useSelector(({ surveys }) => surveys.auditTypeData);
    const [open, setOpen] = useState(false);
    const [AuditNameid, setAuditNameid] = useState(0);

    const [searchData, setSearch] = useState({
        searchAudittypeId: "All",
        searchAuditName: "",
        searchClient: "",
        searchServiceProvider: "",
        searchBusinessUnit: "",
        rowsPerPage: 25,
        page: 0,
    })

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    useEffect(() => {
        dispatch(getAuditTypesList());

        dispatch(getauditNamesList(page * rowsPerPage, rowsPerPage,));
    }, []);

    useEffect(() => {
        dispatch(getauditNamesList(page * rowsPerPage, rowsPerPage,));
    }, [page, rowsPerPage, open]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
    };

    function updateForm(value) {

        return setSearch((prev) => {
            return { ...prev, ...value };
        });
    }
    async function editAuditName(id) {

        navigate(`/audit/auditnames/addauditname/${id}`)
    }

    async function searchClick() {

        setSearch({
            searchAudittypeId: searchData.searchAudittypeId,
            searchAuditName: searchData.searchAuditName,
            searchClient: searchData.searchClient,
            searchServiceProvider: searchData.searchServiceProvider,
            searchBusinessUnit: searchData.searchBusinessUnit,
            page: page * rowsPerPage,
            rowsPerPage: rowsPerPage
        });
        dispatch(searchauditNames(searchData));
    }
    async function searchClear() {
        setSearch({
            searchAuditName: "",
            searchClient: "",
            searchServiceProvider: "",
            searchAudittypeId: "All",
            searchBusinessUnit: "",
            rowsPerPage: 25,
            page: 0,
        });
        setRowsPerPage(25);
        setPage(0);
        dispatch(getauditNamesList(page * rowsPerPage, rowsPerPage));
    }

    async function deleteauditNamesData(id) {

        setOpen(true);
        setAuditNameid(id);

        // setOpen(false);
    }
    const handleClose = (newValue) => {
        setOpen(false);
        if (newValue) {
            dispatch(deleteauditNames(AuditNameid));
        }
    };
    const CovertDateFormat = (mongoDate) => {
        const lossDateObject = new Date(mongoDate);

        // Format the date as "MM/dd/yyyy"
        const formattedLossDate = `${(lossDateObject.getMonth() + 1).toString().padStart(2, '0')}/${lossDateObject.getDate().toString().padStart(2, '0')}/${lossDateObject.getFullYear()}`;
        return formattedLossDate;
    };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    return (
        <div>
            <Navbar /><Message></Message>
            <Box
                sx={{
                    marginTop: 1,
                    marginLeft: '5%',
                    width: '90vw',
                    height: '100%',
                    backgroundColor: 'whitesmoke',
                    borderStyle: 'solid',
                    borderColor: '#213555',
                    borderWidth: 0.5
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: 40,
                        backgroundColor: '#609EA2',
                        borderStyle: 'solid',
                        borderColor: '#213555',
                        borderWidth: 0.5,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Grid container direction="row" sx={{ mt: 1 }}>
                        <Grid item>
                            <StorageIcon sx={{
                                fill: 'white',
                                width: '2.5em',
                                height: '1em',
                            }} fontSize='small' />
                        </Grid>
                        <Grid item xs>
                            <InputLabel id="Add"
                                sx={{ color: 'white' }}
                            >Audit Names Management</InputLabel>
                        </Grid>

                        <Grid item xs
                            lg={1.5}>
                            <Button variant="contained"
                                onClick={() => navigate(`/audit/auditnames/addauditname/${0}`)}
                                sx={{
                                    backgroundColor: ' #213555 !important',
                                    fontColor: 'black',
                                    textTransform: 'none',
                                    lineHeight: 1,
                                    '@media (max-width: 600px)': { // Media query for small screens
                                        maxWidth: '1rem',
                                        fontSize: '0.1rem', // Adjust the font size for small screens
                                    },
                                    display: {
                                        xs: 'none', // Show on small screens
                                        sm: 'block', // Hide on medium screens and larger
                                    },
                                }}>Add Audit Name</Button></Grid>
                        <Grid item
                            sx={{
                                display: {
                                    xs: 'block', // Show on small screens
                                    sm: 'none', // Hide on medium screens and larger
                                }
                            }}>

                            <Tooltip
                                title="Add Audit Name"
                                sx={{
                                    position: 'absolute',
                                    top: '50%', // Adjust the vertical position to center
                                    right: 0,
                                    transform: 'translateY(-50%)', // Center the element vertically
                                    //position: 'relative',
                                    // top: '10%', // Center vertically
                                    //left: '50%', // Center horizontally
                                    //transform: 'translate(-50%, -50%)', // Center the element
                                    display: {
                                        xs: 'block', // Show on small screens
                                        sm: 'none', // Hide on medium screens and larger
                                    },
                                }}
                            >
                                <Fab color="primary" aria-label="add" size="small"
                                    sx={{
                                        display: 'block', // Display on small screens
                                        margin: '0 auto', // Center horizontally
                                        marginTop: '0rem', // Add spacing from above
                                        mt: -1,
                                    }}

                                    onClick={() => navigate(`/audit/auditnames/addauditname/${0}`)}>
                                    <AddIcon sx={{ mt: 1 }} />
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        marginTop: 1,
                        marginLeft: 1,
                        width: '100%',
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Grid container spacing={2} alignItems="center" marginLeft='5%'>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                label="Audit Name"
                                placeholder='Search'
                                variant="outlined"
                                color="primary"
                                type="text"
                                name="searchAuditName"
                                size="small"
                                sx={{
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                    mt: 1, mr: 2
                                }}
                                value={searchData.searchAuditName}
                                onChange={(e) => updateForm({ searchAuditName: e.target.value })}
                            />
                            <FormControl
                                size='small'
                                sx={{
                                    mr: 2,
                                    mt: 1,
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                }}
                            >
                                <InputLabel id="auditType">Select Audit Type</InputLabel>
                                <Select
                                    labelId="audittype"
                                    required
                                    id="searchAudittypeId"
                                    label="Select Audit Type"
                                    name="searchAudittypeId"
                                    value={searchData.searchAudittypeId}
                                    onChange={(e) => updateForm({ searchAudittypeId: e.target.value })}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value="All" key="All">All</MenuItem>
                                    {auditTypeList.map((row) => (
                                        <MenuItem
                                            value={row._id}
                                            key={row._id}
                                            sx={{ padding: '0.1rem', width: '30%', maxWidth: '20vm' }}
                                        >
                                            {row.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* </Grid>
                        <Grid item xs={12} sm={7} md={3}> */}
                            <TextField
                                label="Service Provider"
                                placeholder='Search'
                                variant="outlined"
                                color="primary"
                                type="text"
                                name="searchServiceProvider"
                                size="small"
                                sx={{
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                    mt: 1, mr: 2
                                }}
                                value={searchData.searchServiceProvider}
                                onChange={(e) => updateForm({ searchServiceProvider: e.target.value })}
                            />
                            {/* </Grid>
                        <Grid item xs={12} sm={7} md={3}> */}
                            <TextField
                                label="Client"
                                placeholder='Search'
                                variant="outlined"
                                color="primary"
                                type="text"
                                name="searchClient"
                                size="small"
                                sx={{
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                    mt: 1, mr: 2
                                }}
                                value={searchData.searchClient}
                                onChange={(e) => updateForm({ searchClient: e.target.value })}
                            />
                            <TextField
                                label="Business Unit"
                                placeholder='Search'
                                variant="outlined"
                                color="primary"
                                type="text"
                                name="searchBusinessUnit"
                                size="small"
                                sx={{
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                    mt: 1, mr: 2
                                }}
                                value={searchData.searchBusinessUnit}
                                onChange={(e) => updateForm({ searchBusinessUnit: e.target.value })}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center" >
                        <Grid item xs={12} sm={12} md={12} sx={{ ml: { xs: '15%', sm: '80%' } }}>
                            <Button
                                variant="contained"
                                sx={{
                                    mt: 1,
                                    mr: 1,
                                    backgroundColor: '#213555 !important',
                                    fontColor: 'black',
                                    textTransform: 'none',
                                    lineHeight: 1.8,
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '5rem', // Set a maximum width to limit the size
                                }}
                                onClick={() => searchClick()}
                            >
                                Search
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    mt: 1,
                                    //ml: 1,
                                    backgroundColor: 'Gray !important',
                                    fontColor: 'black',
                                    textTransform: 'none',
                                    lineHeight: 1.8,
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '5rem', // Set a maximum width to limit the size
                                }}
                                onClick={() => searchClear()}
                            >
                                Clear
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    sx={{
                        marginTop: 2,
                        marginLeft: 1,
                        marginRight: 1,
                        height: '65%',
                        backgroundColor: 'white',
                    }}
                >
                    <TableContainer component={Paper}>
                        <Table sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold' }} size='small' aria-label="Survey table">
                            <TableHead sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold', fontSize: '0.975rem !important', backgroundColor: '#898B8A', color: 'white' }}>
                                <TableRow>
                                    <TableCell sx={{ color: 'white', width: '5%' }}>Audit Name</TableCell>
                                    <TableCell sx={{ color: 'white', width: '10%' }}>Audit Type</TableCell>
                                    <TableCell sx={{ color: 'white', width: '5%' }}>Service Provider</TableCell>
                                    <TableCell sx={{ color: 'white', width: '10%' }}>Client</TableCell>
                                    <TableCell sx={{ color: 'white', width: '5%' }}>Business Unit</TableCell>
                                    <TableCell sx={{ color: 'white', width: '5%', whiteSpace: 'nowrap' }}>Active Status</TableCell>
                                    <TableCell sx={{ color: 'white', width: '8%' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {auditnameslist.map((row) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell width="5%">{row.auditname}</TableCell>
                                        <TableCell width="10%">{row.audittypeId ? row.audittypeId.name : ""}</TableCell>
                                        <TableCell width="5%">{row.serviceprovider}</TableCell>
                                        <TableCell width="5%">{row.client}</TableCell>
                                        <TableCell width="5%">{row.businessunit}</TableCell>
                                        <TableCell width="10%" sx={{ whiteSpace: 'nowrap' }}>{row.Active ? "Active" : "In-Active"}</TableCell>
                                        <TableCell colSpan={2} sx={{ whiteSpace: 'nowrap' }}>
                                            <IconButton aria-label="edit" onClick={() => editAuditName(row._id)} size="small">
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton aria-label="delete" onClick={() => deleteauditNamesData(row._id)} size="small">
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={TotalCount ? TotalCount : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Box>
            <ConfirmationDialog
                id="ringtone-menu"
                keepMounted
                open={open}
                text={("Are you sure you want to delete this Audit Name?")}
                onClose={handleClose}
                value={AuditNameid}
            >
            </ConfirmationDialog>
        </div >
    );
}

export default AuditNames