import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    snackbarOpen: null,
    options: {
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        },
        autoHideDuration: 4000,
        message: 'Hi',
        severity: null,
    },
};

const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        showMessage: (state, action) => {

            state.snackbarOpen = true;
            state.options = {
                ...initialState.options,
                ...action.payload,
            };

        },
        hideMessage: (state, action) => {
            state.snackbarOpen = false;
        },
    }
})
export const selectMessageState = ({ snack }) => snack.message.snackbarOpen;

export const selectMessageOptions = ({ snack }) => snack.message.options;
export const { hideMessage, showMessage } = messageSlice.actions;
export default messageSlice.reducer;