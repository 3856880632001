import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from "../store/messageSlice";

export const getSectionsList = (pageindex, pagesize) => async dispatch => {
    dispatch(setLoading(true));
    await axios.get(process.env.REACT_APP_URL.concat(`getSectionsList/${pageindex}/${pagesize}`), { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data.data;
            if (res.data.status === true) {
                dispatch(setTotalSectionsCount(x.TotalCount));
                dispatch(setLoading(false));
                return dispatch(sectionListSuccess(x.sections));
            }
            else {
                dispatch(showMessage({
                    message: res.data.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
            dispatch(setLoading(false));
            return dispatch(sectionListError([]));
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            dispatch(setLoading(false));
            return dispatch(sectionListError([]));
        });

};
export const deleteSection = (id) => async dispatch => {
    dispatch(setLoading(true));
    await axios.get(process.env.REACT_APP_URL.concat(`sectiondelete/${id}`), { withCredentials: true })
        .then(res => {
            let x = res.data


            if (x.status === true) {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'success'
                }));
                dispatch(setLoading(false));
                return dispatch(sectionDataSuccess(x.saveSection));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
                dispatch(setLoading(false));
            }
        }
        );

};
export const saveSection = (data) => async dispatch => {
    dispatch(setLoading(true));
    var URL = "";
    if (data._id === '0' || data._id === '') {
        URL = process.env.REACT_APP_URL.concat('sectioncreate');
    }
    else {
        URL = process.env.REACT_APP_URL.concat(`sectionedit`);
    }
    await axios.post(URL, data, { withCredentials: true })
        .then(res => {
            let x = res.data


            if (x.status === true) {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'success'
                }));
                dispatch(setLoading(false));
                return dispatch(sectionDataSuccess(x.savedSection));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 5000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
                dispatch(setLoading(false));
            }
        }
        ).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            dispatch(setLoading(false));
        });

};
const initialState = {
    sectionsuccess: false,
    sectionsData: [],
    sections: [],
    isloading: false,
};

const sectionSlice = createSlice({
    name: 'sections',
    initialState,
    reducers: {
        sectionListSuccess: (state, action) => {
            state.sectionsuccess = true;
            state.sectionsData = action.payload;
        },
        sectionListError: (state, action) => {
            state.sectionsuccess = false;
            state.sectionsData = [];
        },
        sectionDataSuccess: (state, action) => {
            state.sectionsuccess = true;
            state.sections = action.payload;
        },
        setTotalSectionsCount: (state, action) => {
            state.sectionsuccess = true;
            state.TotalCount = action.payload;
        },
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
    }
})
export const {
    sectionListSuccess,
    sectionListError,
    sectionDataSuccess,
    setTotalSectionsCount,
    setLoading
} = sectionSlice.actions;


export default sectionSlice.reducer;