import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function CircularProgressLoader(props) {
    const [open, setOpen] = React.useState(props.loading);
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress size={80} sx={{ color: '#00337C' }} />
            </Backdrop>
        </div>
    );
}