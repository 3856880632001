import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button, FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from '../store/loginSlice';
import logoimg from "../assets/Logo/interwovenicon63.png";
import Avatar from "@mui/material/Avatar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Message from '../commonui/snackbar';
function ForgotPassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorShow, setError] = useState();
    const [user, setUser] = useState({
        username: "",
    })
    function updateForm(value) {
        return setUser((prev) => {
            return { ...prev, ...value };
        });
    }
    async function onSubmit(e) {

        e.preventDefault();
        // When a post request is sent to the create url, we'll add a new record to the database.

        if (validate()) {

            dispatch(forgotPassword(user.username));

            navigate("/");
        }
    }
    function validate() {

        if (user.username === "") {
            setError(true);
            return false;
        }
        setError(false);
        return true;
    }
    return (


        <div>
            <Message></Message>
            <Box
                sx={{
                    boxShadow: 2,
                    borderRadius: 2,
                    px: 2,
                    py: 0,
                    marginTop: '15%',
                    marginLeft: '30%',
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: '90%',
                    width: '35%',
                    bgcolor: "whitesmoke"
                }}
            >
                <Avatar
                    alt="logo"
                    src={logoimg}
                    sx={{ width: 120, height: 120, mt: 1, py: -1 }}
                >
                </Avatar>
                <Typography component="h1" variant="h5">
                    Forgot Password
                </Typography>
                <Box component="form" noValidate="True" sx={{ mt: 1 }}>
                    <TextField className="txtemail"
                        margin="normal"
                        required
                        value={user.username}
                        id="email"
                        label="Username"
                        name="email"
                        error={errorShow}
                        autoComplete="email"
                        autoFocus
                        size="small"
                        onChange={(e) => updateForm({ username: e.target.value })}
                    />
                    <Grid container>
                        <Grid item sx={{ mb: 2, ml: 12 }}>
                            <Button
                                type="Button" className="lgnbtn"

                                variant="contained"
                                sx={{
                                    marginTop: 2,
                                    marginLeft: 1,
                                    backgroundColor: ' #213555 !important',
                                    fontColor: 'black'
                                }}
                                onClick={() => navigate("/")}
                            >
                                <ArrowBackIcon fontSize="medium" sx={{ mr: 2 }} /> Back
                            </Button>
                        </Grid>
                        <Grid item sx={{ mb: 2, ml: 10 }}>
                            <Button className="btn" style={{ marginLeft: '40%' }}
                                type="Button"

                                variant="contained"
                                sx={{
                                    marginTop: 2,
                                    marginLeft: 8,
                                    backgroundColor: ' #213555!important',
                                    fontColor: 'black'
                                }}
                                onClick={onSubmit}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>

                </Box>
            </Box>
        </div>
    )
}
export default ForgotPassword