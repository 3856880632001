import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Login from "./Login/index.js"
import Dashboard from './Dashboard/index.js'
import Navbar from './Navigation/Mainnavbar.js'
import Roles from './Administarion/Roles/All.js'
import Users from './Administarion/Users/All.js'
import UserCreate from './Administarion/Users/Create.js'
import ForgotPassword from './ForgotPassword/forgotPassword.js'
import ResetPassword from './ForgotPassword/resetPassword.js'
import UserContext from './contexts/userContexts';
import Questions from './Administarion/Questions/List';
import AddQuestion from './Administarion/Questions/AddQuestion';
import Sections from './Administarion/Sections/SectionList.js';
import AddSection from './Administarion/Sections/AddSection';
import AuditType from './Audit/AuditType/auditTypeList';
import AddAuditType from './Audit/AuditType/auditTypeAdd';
// import AddAuditSection from './Audit/AuditSection/auditsectionadd';
// import AuditSections from './Audit/AuditSection/auditsectionList';
import Surveys from './Administarion/Surveys/SurveyList';
import AddSurvey from './Administarion/Surveys/AddSurvey';
import AuditTypeSectionQtn from './Audit/AuditType/auditTypeSectionQtn';
import axios from 'axios';
import SummaryReport from './Reports/summaryReport';
import OverallAverages from './Reports/overallaverages';
import AveragaesByExaminar from './Reports/averagesByExaminar';
import AuditNames from './Audit/AuditMaster/auditNamesList';
import AddAuditName from './Audit/AuditMaster/auditNameAdd'

function App() {
  const user = {
    user: {}
  }
  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error.response.status === 401) {
      window.location = `${window.location.origin}`;
    }
    // return error;
    return response
  });
  return (
    <div className="App">
      <Router>
        <UserContext.Provider value={user}>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/forgotpassword" element={<ForgotPassword />} />
            <Route exact path="/resetpassword/:email" element={<ResetPassword />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/administarion/roles" element={<Roles />} />
            <Route path="/administarion/users" element={<Users />} />
            <Route path="/administarion/users/newuser/:userid" element={<UserCreate />} />
            <Route path="/administarion/questions" element={<Questions />} />
            <Route path="/administarion/questions/addquestion/:questionid/:sectionId" element={<AddQuestion />} />
            <Route path="/administarion/sections" element={<Sections />} />
            <Route path="/administarion/sections/addsection/:sectionid" element={<AddSection />} />
            <Route path="/audit/audittypes" element={<AuditType />} />
            <Route path="/audit/audittypes/addaudittype/:id" element={<AddAuditType />} />
            <Route path="/audit/audittypes/addaudittype/auditTypeSectionQuestion/:id" element={<AuditTypeSectionQtn />} />
            <Route path="/administarion/surveys" element={<Surveys />} />
            <Route path="/administarion/surveys/addsurvey/:surveyid" element={<AddSurvey />} />
            <Route path="/reports/summaryreport" element={<SummaryReport />} />
            <Route path="/reports/overallaverages" element={<OverallAverages />} />
            <Route path="/reports/averagesbyexaminar" element={<AveragaesByExaminar />} />
            <Route path="/audit/auditnames" element={<AuditNames />} />
            <Route path="/audit/auditnames/addauditname/:id" element={<AddAuditName />} />
            {/* <Route path="/audit/auditsection" element={<AuditSections />} />
            <Route path="/audit/auditsection/addauditsection/:id" element={<AddAuditSection />} /> */}
          </Routes>
        </UserContext.Provider>
      </Router>
    </div>
  );
}

export default App;
