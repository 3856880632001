import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from "../store/messageSlice";

export const getQuestionList = (pageindex, pagesize) => async dispatch => {
    dispatch(setLoading(true));
    await axios.get(process.env.REACT_APP_URL.concat(`getQuestions/${pageindex}/${pagesize}`), { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data.data;
            if (res.data.status === true) {
                dispatch(setTotalQuestionsCount(x.TotalCount));
                dispatch(setLoading(false));
                return dispatch(questionListSuccess(x.QuestionList));
            }
            else {
                dispatch(showMessage({
                    message: res.data.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
            dispatch(setLoading(false));
            return dispatch(questionListError([]));
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            dispatch(setLoading(false));
            return dispatch(questionListError([]));
        });

};

export const getSections = () => async dispatch => {
    dispatch(setLoading(true));
    await axios.get(process.env.REACT_APP_URL.concat("getSections"), { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data;
            if (x.status === true) {
                dispatch(setLoading(false));
                return dispatch(questionSectionSuccess(x.sections));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
                dispatch(setLoading(false));
            }
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            dispatch(setLoading(false));
            return dispatch(questionSectionListError([]));
        });

};

export const saveQuestion = (data) => async dispatch => {
    dispatch(setLoading(true));
    var URL = "";
    //console.log("in save ques");
    //console.log(data);
    if (data._id === "0" || data._id === "") {
        URL = process.env.REACT_APP_URL.concat('questioncreate');
    }
    else {
        URL = process.env.REACT_APP_URL.concat(`questionedit`);
    }
    await axios.post(URL, data, { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(res => {
            let x = res.data


            if (x.status === true) {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'success'
                }));
                getQuestionList();
                dispatch(setLoading(false));
                return dispatch(questionDataSuccess(x.savedquestion));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
                dispatch(setLoading(false));
            }
        }
        ).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            dispatch(setLoading(false));
        });
};

export const deleteQuestion = (id) => async dispatch => {
    dispatch(setLoading(true));
    await axios.get(process.env.REACT_APP_URL.concat(`questiondelete/${id}`), { withCredentials: true })
        .then(res => {
            let x = res.data

            // 
            if (x.status === true) {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'success'
                }));
                getQuestionList();
                dispatch(setLoading(false));
                return dispatch(questionDataSuccess(x.savedquestion));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
                dispatch(setLoading(false));
            }
        }
        );
};

export const searchQuestions = (data) => async dispatch => {
    dispatch(setLoading(true));
    await axios.post(process.env.REACT_APP_URL.concat('searchQuestions'), data, { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data.data;
            if (res.data.status === true) {
                dispatch(setTotalQuestionsCount(x.TotalCount));
                dispatch(setLoading(false));
                return dispatch(questionListSuccess(x.QuestionList));
            }
            else {
                dispatch(showMessage({
                    message: res.data.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
            dispatch(setLoading(false));
            return dispatch(questionListError([]));
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            dispatch(setLoading(false));
            return dispatch(questionListError([]));
        });

};

const initialState = {
    questionsuccess: false,
    questionsectionsuccess: false,
    questionsData: [],
    questionSection: [],
    question: [],
    isloading: false,
};

const questionSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
        questionListSuccess: (state, action) => {
            state.questionsuccess = true;
            state.questionsData = action.payload;
        },
        questionListError: (state, action) => {
            state.questionsuccess = false;
            state.questionsData = [];
        },
        questionDataSuccess: (state, action) => {
            state.questionsuccess = true;
            state.question = action.payload;
        },
        questionSectionSuccess: (state, action) => {
            state.questionsectionsuccess = true;
            state.questionSection = action.payload;
        },
        questionSectionListError: (state, action) => {
            state.questionsectionsuccess = false;
            state.questionSection = [];
        },
        setTotalQuestionsCount: (state, action) => {
            state.questionsuccess = true;
            state.TotalCount = action.payload;
        },
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
    }
})
export const {
    questionListSuccess,
    questionListError,
    questionSectionSuccess,
    questionSectionListError,
    questionDataSuccess,
    setTotalQuestionsCount,
    setLoading
} = questionSlice.actions;


export default questionSlice.reducer;