import React, { useEffect, useState } from 'react';
import { Select, MenuItem, InputLabel, FormControl, FormControlLabel, Checkbox, Collapse } from '@mui/material';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom"
import { useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { saveData, GetSectionwiseQtnList, getAuditTypeList, DeleteAuditTypeQuestion, GetAuditType, SetSectionSortOrder } from '../../store/auditTypeSlice1';
import List from '@mui/material/List';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Paper from "@mui/material//Paper";
import AddIcon from '@mui/icons-material/Add';
import { showMessage } from "../../store/messageSlice";
import Navbar from "../../Navigation/Navbar";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function AuditTypeSectionQtn() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [id, setid] = useState(params.id);
    const savedaudittype = useSelector(({ audittypewithSections }) => audittypewithSections.audittype);
    //  
    const [auditType, setAudittype] = useState(savedaudittype);
    const [sections, setsections] = useState([]);
    const [sortno, setsortno] = useState([]);
    useEffect(() => {
        getList();
    }, [savedaudittype._id]);
    async function getList() {
        dispatch(GetAuditType(id));
    }
    async function Backhandle() {
        navigate(`/audit/audittypes/addaudittype/${savedaudittype._id}`);
    }
    /***Changes for accordian */
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {

        setExpanded(isExpanded ? panel : false);
    };
    async function AddQuestionHandle(sectionid) {

        //   alert("in add quetion");
        navigate(`../../../administarion/questions/addquestion/${0}/${sectionid}`);
    }
    async function EditQuestionHandle(questionid, sectionid) {
        //  alert("in edit quetion");
        navigate(`../../../administarion/questions/addquestion/${questionid}/${sectionid}`);
    };
    async function DeleteQuestionHandle(sectionid, quetiontxt) {

        //   alert("in delete quetion");
        dispatch(DeleteAuditTypeQuestion(savedaudittype, sectionid, quetiontxt));
    };
    async function saveTypehandle() {

        dispatch(saveData(savedaudittype));
        navigate("/audit/audittypes");
    }
    /***end */
    // Function to update 'SectionQuestionList' with 'sortorder'
    function updateAuditTypesWithSortOrder(auditType) {
        const updatedSectionQuestionList = auditType.SectionQuestionList.map(section => ({
            ...section,
            sortorder: 0,
        }));

        // Create a new object with the updated 'SectionQuestionList'
        const updatedAuditType = {
            ...auditType,
            SectionQuestionList: updatedSectionQuestionList,
        };

        return updatedAuditType;
    }
    // Function to handle sorting order change for a section
    function handleSortOrder(newSortOrder, sectionname) {
        // Find the section within SectionQuestionList by sectionname
        debugger;
        dispatch(SetSectionSortOrder(newSortOrder, sectionname, savedaudittype));
    }
    return (

        <div>
            <Navbar />
            <Box
                sx={{
                    marginTop: 1,
                    marginLeft: '15%',
                    width: '70vw',
                    height: '100%',
                    sx: { height: '100vh' },
                    backgroundColor: 'white',
                    borderStyle: 'solid',
                    borderColor: '#213555',
                    borderWidth: 0.5,
                }}
            >
                <Box
                    sx={{
                        mt: 0,
                        width: '100%',
                        height: 40,
                        backgroundColor: '#22A699',
                        borderStyle: '',
                    }}
                >
                    <Grid container>
                        <Grid item>
                            <IconButton aria-label="back" size="small">
                                <ArrowBackIcon fontSize='small' sx={{ color: "white" }} onClick={Backhandle} />
                            </IconButton>
                        </Grid>
                        <Grid item><InputLabel id="addaudittype"
                            sx={{
                                ml: 1,
                                mt: 1,
                                color: 'white',
                            }}
                        >Choose Questions for {savedaudittype.name}</InputLabel>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        borderBlockColor: 'black',
                        backgroundColor: 'white',
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <form autoComplete="off" noValidate={true}>
                        {savedaudittype.SectionQuestionList == undefined ? [] : savedaudittype.SectionQuestionList.map(section => (

                            <List
                                sx={{
                                    width: '95%',
                                    bgcolor: 'whitesmoke',
                                    marginLeft: 3,
                                    '@media (max-width: 600px)': {
                                        marginLeft: 0, // Adjust margins for smaller screens
                                    },
                                }}
                            >

                                <Accordion expanded={expanded === section.sectionname} onChange={handleChange(section.sectionname)} >
                                    <ListItem sx={{
                                        backgroundColor: "#898B8A", width: '100%',
                                        height: '5vh', flex: '1 0 auto',
                                        '@media (max-width: 600px)': {
                                            height: '20vh', // Set a specific height for mobile view
                                        },
                                    }}>
                                        <ListItemText sx={{ color: "white" }} primary={section.sectionname} />
                                        <InputLabel
                                            id=""
                                            sx={{
                                                fontSize: '1rem',
                                                //fontWeight: 'bold',
                                                fontFamily: 'Arial',
                                                color: 'white',

                                                display: 'inline-block', // Display inline to keep both parts in one line
                                            }}
                                        >
                                            Sequence:
                                        </InputLabel>
                                        <TextField
                                            size="small"
                                            //  label="Sort No."
                                            // placeholder='Sort No.'
                                            variant="outlined"
                                            color="primary"
                                            inputProps={{
                                                maxLength: 2,
                                                style: { textAlign: 'center', fontSize: '12px', fontWeight: 'bold' }
                                            }}
                                            name="sortno"
                                            value={section.sortorder || 0}
                                            onChange={(e) => {
                                                // Add validation to allow only two digits
                                                const inputValue = e.target.value.replace(/\D/g, 0); // Remove non-digit characters
                                                if (inputValue.length <= 2) {
                                                    // Limit to two digits
                                                    handleSortOrder(e.target.value, section.sectionname)
                                                }
                                            }}
                                            sx={{
                                                mt: 0, ml: 3,
                                                width: '4vw',
                                                height: '4vm',
                                                backgroundColor: 'white',
                                                mt: 1, // Add margin-top
                                                mb: 1, // Add margin-bottom

                                            }}
                                        />
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id={section.sectionname}
                                        >
                                        </AccordionSummary>

                                        <AddIcon fontSize='small' onClick={() => AddQuestionHandle(section._id)}>Add question</AddIcon>
                                    </ListItem>
                                    {/* <Divider component="table" sx={{ borderColor: "#22A699", borderWidth: 1, width: 960 }} /> */}

                                    <AccordionDetails>

                                        <Paper sx={{ width: '100%' }}>

                                            <Table className="table" aria-label="caption table" size='small'>

                                                <TableHead>
                                                    <TableRow>
                                                        {/* <TableCell align="left" />
                                            <TableCell align="left">Question</TableCell> */}

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {section.questions.map((questiondata) => (
                                                        <TableRow key={questiondata._id}>
                                                            <TableCell align="left">{questiondata.question}</TableCell>
                                                            <TableCell align="left">{`Points: ${questiondata.points}`}</TableCell>
                                                            <TableCell align="right" colSpan={2}>
                                                                <IconButton aria-label="edit" size="small">
                                                                    <EditIcon fontSize='small' onClick={() => EditQuestionHandle(questiondata._id, section._id)} />
                                                                </IconButton>
                                                                <IconButton aria-label="edit" size="small">
                                                                    <DeleteIcon fontSize="small" onClick={() => DeleteQuestionHandle(section._id, questiondata.question)} />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Paper>

                                    </AccordionDetails>
                                </Accordion>
                            </List>

                        ))}
                    </form></Box>
                <Box
                    sx={{
                        mt: 5,
                        width: '100%',
                        height: '7vh',
                        backgroundColor: '#f5f5f5',
                        borderStyle: '',
                    }}
                >
                    <Grid container justifyContent={'flex-end'}>
                        <Grid item>
                            {/* <Button variant="contained"
                                type='submit'
                                sx={{
                                    mt: 1,
                                    backgroundColor: ' #213555 !important',
                                }}
                                onClick={Backhandle}
                            >Back</Button>&nbsp;&nbsp; */}
                            <Button variant="contained"
                                type='submit'
                                sx={{
                                    mt: 1,
                                    backgroundColor: ' #213555 !important',
                                }}
                                onClick={() => saveTypehandle()}
                            >Save</Button>&nbsp;&nbsp;
                            <Button variant="contained"
                                type='submit'
                                sx={{
                                    mt: 1,
                                    mr: 1,
                                    backgroundColor: ' #CFD2CF !important',
                                    color: "black"
                                }}
                                onClick={() => navigate("/audit/audittypes")}
                            >Cancel</Button>
                        </Grid>
                    </Grid>
                </Box>


            </Box>
        </div >

    )
}
export default AuditTypeSectionQtn



{/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          
          
        </AccordionSummary>
        <AccordionDetails>
          
        </AccordionDetails>
      </Accordion> */}