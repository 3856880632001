import React, { useEffect, useState } from 'react';
import Dashboard from "../../Dashboard"
//import axios from "axios";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Grid, Paper, InputLabel, Tooltip, Fab } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { getSectionsList, deleteSection } from '../../store/sectionSlice';
import TablePagination from '@mui/material/TablePagination';
import ConfirmationDialog from '../../commonui/confirmation';
import StorageIcon from '@mui/icons-material/Storage';
import Navbar from "../../Navigation/Navbar";
import Message from '../../commonui/snackbar';
import CircularProgressLoader from '../../commonui/circularProgressLoader';
//import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

function Sections() {
    const dispatch = useDispatch();
    const sectionlist = useSelector(({ sections }) => sections.sectionsData);
    const TotalCount = useSelector(({ sections }) => sections.TotalCount);
    const [open, setOpen] = useState(false);
    const [Sectionid, setSectionId] = useState(0);
    const [deleteConfirmed, setDelete] = useState(false);

    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const isloadingvalue = useSelector(({ sections }) => sections.isloading);
    const [loading, setLoading] = useState();
    useEffect(() => {
        dispatch(getSectionsList(page * rowsPerPage, rowsPerPage));
    }, []);

    useEffect(() => {
        dispatch(getSectionsList(page * rowsPerPage, rowsPerPage));
    }, [page, rowsPerPage, open]);
    useEffect(() => {
        setLoading(isloadingvalue)
    }, [isloadingvalue]);
    async function editSection(id) {

        navigate(`/administarion/sections/addsection/${id}`)
    }

    async function delSection(id) {

        setOpen(true);
        setSectionId(id);
    }
    const handleClose = (newValue) => {
        setOpen(false);
        if (newValue) {
            dispatch(deleteSection(Sectionid));
            dispatch(getSectionsList(page * rowsPerPage, rowsPerPage));
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
    };

    // Avoid a layout jump when reaching the last page with empty rows.

    return (

        <div>
            {/* <Dashboard />
            <InputLabel id="Sections Management"
                sx={{
                    ml: 20,
                    mt: 10,
                    mb: 1,
                    mr: 1,
                    color: 'Black',
                    fontFamily: 'Arial',
                    fontStyle: 'Bold',
                    fontSize: '1.5rem'
                }}
            ></InputLabel>
           */}
            <Navbar /><Message></Message>
            {loading && < CircularProgressLoader loading={loading} />}
            <Box
                sx={{
                    marginTop: 1,
                    marginLeft: '5%',
                    width: '90vw',
                    height: '100%',
                    backgroundColor: 'whitesmoke',
                    borderStyle: 'solid',
                    borderColor: '#213555',
                    borderWidth: 0.5
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: 40,
                        backgroundColor: '#609EA2',
                        borderStyle: 'solid',
                        borderColor: '#213555',
                        borderWidth: 0.5,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >

                    <Grid container direction="row">
                        <Grid item>
                            <StorageIcon sx={{
                                mt: 1,
                                fill: 'white',
                                width: '2.5em',
                                height: '1em',
                            }} fontSize='small' />
                        </Grid>
                        <Grid item xs>
                            <InputLabel id="Create Section"
                                sx={{
                                    mt: 1,
                                    color: 'white',
                                }}
                            >Sections Management</InputLabel>
                        </Grid>
                        <Grid item xs lg={1.5}>
                            <Button variant="contained"
                                onClick={() => navigate(`/administarion/sections/addsection/${0}`)}
                                sx={{
                                    mt: 1,
                                    backgroundColor: ' #213555 !important',
                                    fontColor: 'black',
                                    textTransform: 'none',
                                    lineHeight: 1,
                                    '@media (max-width: 600px)': { // Media query for small screens
                                        maxWidth: '1rem',
                                        fontSize: '0.1rem', // Adjust the font size for small screens
                                    },
                                    display: {
                                        xs: 'none', // Show on small screens
                                        sm: 'block', // Hide on medium screens and larger
                                    },
                                }}>Add Section</Button>
                        </Grid>
                        <Grid item
                            sx={{
                                display: {
                                    xs: 'block', // Show on small screens
                                    sm: 'none', // Hide on medium screens and larger
                                }
                            }}>
                            <Tooltip
                                title="Add Question"
                                sx={{
                                    position: 'absolute',
                                    top: '30%',
                                    right: '25px',
                                    transform: 'translateY(-50%)',
                                    display: {
                                        xs: 'block', // Show on small screens
                                        sm: 'none', // Hide on medium screens and larger
                                    },
                                }}
                            >
                                <Fab color="primary" aria-label="add" size="small"
                                    sx={{
                                        display: 'block', // Display on small screens
                                        margin: '0 auto', // Center horizontally
                                        marginTop: '0rem', // Add spacing from above
                                        // mt: -1,
                                    }}
                                    onClick={() => navigate(`/administarion/sections/addsection/${0}`)}>
                                    <AddIcon sx={{ mt: 1 }} />
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        marginTop: 2,
                        marginLeft: 1,
                        marginRight: 1,
                        height: '65%',
                        backgroundColor: 'white',
                    }}
                >

                    <TableContainer component={Paper}>
                        <Table sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold' }} size='small' aria-label="a dense table">
                            <TableHead sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold', fontSize: '0.975rem !important', backgroundColor: '#898B8A', color: 'white' }}>
                                <TableRow>
                                    {/* <TableCell sx={{ color: 'white' }}>Section Code</TableCell> */}
                                    <TableCell sx={{ color: 'white' }}>Section</TableCell>
                                    <TableCell sx={{ color: 'white' }}>Description</TableCell>
                                    <TableCell sx={{ color: 'white', whiteSpace: "nowrap" }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sectionlist.map((row) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        {/* <TableCell component="th" scope="row">
                                            {row.sectioncode}
                                        </TableCell> */}
                                        <TableCell component="th" scope="row">
                                            {row.section}
                                        </TableCell>
                                        <TableCell >{row.description}</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }} colSpan={2}>
                                            <IconButton aria-label="edit" onClick={() => editSection(row._id)} size="small">
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton aria-label="delete" onClick={() => delSection(row._id)} size="small">
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={TotalCount ? TotalCount : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Box>
            <ConfirmationDialog
                id="ringtone-menu"
                keepMounted
                open={open}
                text={("Are you sure you want to delete this section?")}
                onClose={handleClose}
                value={Sectionid}
            >
            </ConfirmationDialog>
        </div >

    )
}
export default Sections;