import React, { useEffect, useState } from 'react';
//import Dashboard from "../../Dashboard"
//import axios from "axios";
import { Select, MenuItem, InputLabel, FormControl, FormControlLabel, Paper, Radio, Typography } from '@mui/material';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { getAuditTypesList } from '../../store/surveySlice';
import { saveauditNames } from '../../store/auditNameSlice';

import Navbar from "../../Navigation/Navbar";

function AddAuditName() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const id = params.id;
    const auditnamedata = useSelector(({ auditnames }) => auditnames.auditNamessData);
    const auditTypeList = useSelector(({ surveys }) => surveys.auditTypeData);

    const [formErrors, setFormErrors] = useState({});
    const [auditname, setAuditName] = useState({
        _id: "",
        businessunit: "",
        client: "",
        audittypeId: "",
        auditname: "",
        serviceprovider: "",
        heading: "Add Audit Name",

    })

    function updateForm(value) {

        return setAuditName((prev) => {
            return { ...prev, ...value };
        });
    }
    const requiredFields = ["auditname", "businessunit", "serviceprovider", "audittypeId",];

    useEffect(() => {

        dispatch(getAuditTypesList());
        editauditnamedata();
    }, []);



    async function editauditnamedata() {

        if (id !== "0") {
            let auditNamesrecord = auditnamedata.filter(auditnamedata => auditnamedata._id === id);
            setAuditName({
                _id: id,
                businessunit: auditNamesrecord[0].businessunit,
                client: auditNamesrecord[0].client,
                audittypeId: auditNamesrecord[0].audittypeId ? auditNamesrecord[0].audittypeId._id : "",
                auditname: auditNamesrecord[0].auditname,
                serviceprovider: auditNamesrecord[0].serviceprovider,
                heading: "Edit Audit Name",
            });

        }
    }
    async function saveauditnameHandle(e) {

        e.preventDefault();

        if (id === "0") {
            const errors = validateRequiredFields(auditname, requiredFields);
            setFormErrors(errors);
            if (Object.keys(errors).length === 0) {
                setFormErrors({});
                saveauditnamedata();

                navigate("/audit/auditNames");
            }
        } else {
            const errors = validateRequiredFields(auditname, requiredFields);
            setFormErrors(errors);
            if (Object.keys(errors).length === 0) {
                setFormErrors({});
                editAuditName();
                navigate("/audit/auditNames");
            }
        }

    }
    async function saveauditnamedata() {

        dispatch(saveauditNames(auditname));
    }
    async function editAuditName() {

        dispatch(saveauditNames(auditname));
    }

    const validateRequiredFields = (values, requiredFields) => {
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!values[field] || values[field].trim() === '') {
                newErrors[field] = 'This field is required';
            }
        });

        return newErrors;
    };





    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    return (

        <div>
            <Navbar />
            <Box
                sx={{
                    marginTop: 1,
                    marginLeft: '25%',
                    width: '50vw',
                    height: '100%',
                    backgroundColor: 'white',
                    borderStyle: 'solid',
                    borderColor: '#213555',
                    borderWidth: 0.5
                }}
            >
                <Box
                    sx={{
                        mt: 0,
                        width: '100%',
                        height: 40,
                        backgroundColor: '#22A699',
                        borderStyle: '',
                    }}
                >
                    <Grid container>

                        <Grid item><InputLabel id="addauditname"
                            sx={{
                                ml: 1,
                                mt: 1,
                                color: 'white',
                            }}
                        >{auditname.heading}</InputLabel>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        marginTop: 0,
                        width: '98.5%',
                        height: '100%',
                        borderBlockColor: 'black',
                        backgroundColor: 'white',
                        display: "flex",
                        flexDirection: "column",
                    }}

                >
                    <form autoComplete="off">
                        <Grid container spacing={1} alignItems="center" sx={{ mt: 1, ml: 1 }}>
                            <Grid item sx={{ mr: 2 }}>
                                <TextField
                                    size="small"
                                    label="Audit Name"
                                    placeholder='Enter Audit Name'
                                    required
                                    variant="outlined"
                                    color="primary"
                                    name="auditname"
                                    type="text"
                                    value={auditname.auditname}
                                    onChange={(e) => updateForm({ auditname: e.target.value })}
                                    sx={{
                                        mr: 2,
                                        width: '100%', // Set the width to 100% to make it responsive
                                        maxWidth: '15rem',
                                    }}
                                    error={!!formErrors.auditname}
                                // helperText={formErrors.businessunit || ''}
                                />

                            </Grid>
                            <Grid item sx={{ mr: 2 }}>
                                <TextField
                                    size="small"
                                    label="Service provider"
                                    placeholder='Enter Service provider'
                                    type="text"
                                    required
                                    variant="outlined"
                                    color="primary"
                                    name="serviceprovider"
                                    value={auditname.serviceprovider}
                                    onChange={(e) => updateForm({ serviceprovider: e.target.value })}
                                    sx={{
                                        mr: 2,
                                        width: '100%', // Set the width to 100% to make it responsive
                                        maxWidth: '15rem',
                                    }}
                                    error={!!formErrors.serviceprovider}
                                // helperText={formErrors.client || ''}
                                />
                            </Grid>
                            <Grid item sx={{
                                mr: 2,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem',
                            }}>

                                <FormControl
                                    size='small'
                                    required
                                    sx={{
                                        mr: 2,
                                        width: '100%', // Set the width to 100% to make it responsive
                                        maxWidth: '15rem',
                                    }}>
                                    <InputLabel id="auditType">Select Audit Type</InputLabel>
                                    <Select
                                        labelId="audittype"

                                        id="audittypeId"
                                        label="Select Audit Type"
                                        name="audittypeId"
                                        value={auditname.audittypeId}
                                        onChange={(e) => updateForm({ audittypeId: e.target.value })}
                                        error={!!formErrors.audittypeId}
                                        MenuProps={MenuProps}
                                        sx={{
                                            mr: 2,
                                            width: '100%', // Set the width to 100% to make it responsive
                                            maxWidth: '15rem',
                                        }}
                                    >
                                        {auditTypeList.map((row) => (
                                            <MenuItem value={row._id} key={row._id}
                                                sx={{ padding: '0.1rem', width: '30%', maxWidth: '30vm' }}
                                            >
                                                {row.name}
                                            </MenuItem>
                                        ))
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                        <Grid container spacing={1} alignItems="center" sx={{ mt: 1, ml: 1 }}>
                            <Grid item sx={{ mr: 2 }}>
                                <TextField
                                    size="small"
                                    label="Client"
                                    placeholder='Enter client'
                                    type="text"
                                    required
                                    variant="outlined"
                                    color="primary"
                                    name="client"
                                    value={auditname.client}
                                    onChange={(e) => updateForm({ client: e.target.value })}
                                    sx={{
                                        mr: 2,
                                        width: '100%', // Set the width to 100% to make it responsive
                                        maxWidth: '15rem',
                                    }}
                                    error={!!formErrors.client}
                                // helperText={formErrors.client || ''}
                                />
                            </Grid>
                            <Grid item sx={{
                                mr: 2,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem',
                            }} >
                                <TextField
                                    size="small"
                                    label="Business Unit"
                                    placeholder='Enter Business Unit'
                                    // required
                                    variant="outlined"
                                    color="primary"
                                    name="businessunit"
                                    type="text"
                                    value={auditname.businessunit}
                                    onChange={(e) => updateForm({ businessunit: e.target.value })}
                                    sx={{
                                        mr: 2,
                                        width: '100%', // Set the width to 100% to make it responsive
                                        maxWidth: '15rem',
                                    }}
                                    error={!!formErrors.businessunit}
                                // helperText={formErrors.businessunit || ''}
                                />

                            </Grid>

                        </Grid>
                        <br /><br />
                        <Box
                            sx={{
                                mt: 2,
                                width: '100%',
                                height: 50,
                                backgroundColor: '#f5f5f5',
                                borderStyle: '',
                            }}
                        >
                            <Grid container justifyContent={'flex-end'}>
                                <Grid item>
                                    <Button variant="contained"
                                        type='submit'
                                        sx={{
                                            mt: 1,
                                            mb: -1,
                                            backgroundColor: ' #213555 !important',
                                        }}
                                        onClick={saveauditnameHandle}
                                    >Save</Button>&nbsp;&nbsp;
                                    <Button variant="contained"
                                        type='submit'
                                        sx={{
                                            mt: 1,
                                            mb: -1,
                                            backgroundColor: ' #CFD2CF !important',
                                            color: "black"
                                        }}
                                        onClick={() => navigate("/audit/auditnames")}
                                    >Cancel</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Box>
            </Box>
        </div >

    )
}
export default AddAuditName;