import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from "../store/messageSlice";
export const getUsersList = (pageindex, pagesize) => async dispatch => {

    await axios.get(process.env.REACT_APP_URL.concat(`getUsers/${pageindex}/${pagesize}`), { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data.data;
            if (res.data.status === true) {
                dispatch(setTotalUsersCount(x.TotalCount));
                return dispatch(userListSuccess(x.UserList));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
            return dispatch(userListError([]));
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            return dispatch(userListError([]));
        });

};
export const searchUsers = (data) => async dispatch => {

    await axios.post(process.env.REACT_APP_URL.concat('searchUser'), data, { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data.data;
            if (res.data.status === true) {
                dispatch(setTotalUsersCount(x.TotalCount));
                return dispatch(userListSuccess(x.UserList));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
            return dispatch(userListError([]));
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            return dispatch(userListError([]));
        });

};
export const getRoles = () => async dispatch => {

    await axios.get(process.env.REACT_APP_URL.concat("getRoles"), { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data;
            if (x.status === true) {
                return dispatch(userRolesSuccess(x.roles));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }


        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            return dispatch(userListError([]));
        });

};
export const getUser = (id) => async dispatch => {

    // let u = this.usersData.filter(function () {
    //     return dispatch(userDataSuccess(this.usersData._id === id));
    // })
    // await axios.get(`http://localhost:9002/user/${id}`, { withCredentials: true })
    //     .then(res => {
    //         let x = res.data
    //         console.log(x.message)

    //         if (x.status === "OK") {

    //             return dispatch(userDataSuccess(x.data));
    //         }
    //         else {
    //             alert("Something went wrong")
    //         }
    //     }
    //     );

};
export const deleteUser = (id) => async dispatch => {

    await axios.get(process.env.REACT_APP_URL.concat(`userdelete/${id}`), { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(res => {
            let x = res.data
            //console.log(x.message)

            if (x.status) {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'success'
                }));
                getUsersList();
                return dispatch(userDataSuccess(x.savedUser));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
        }
        );

};
export const saveUser = (data) => async dispatch => {

    var URL = "";
    if (data._id === "0") {
        URL = process.env.REACT_APP_URL.concat('userscreate');
    }
    else {
        URL = process.env.REACT_APP_URL.concat(`useredit`);
    }
    await axios.post(URL, data, { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(res => {
            let x = res.data


            if (x.status === true) {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'success'
                }));
                getUsersList();
                return dispatch(userDataSuccess(x.savedUser));

            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
        }
        ).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
        });

};
const initialState = {
    usersuccess: false,
    usersData: [],
    userRoles: [],
    user: [],
};

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        userListSuccess: (state, action) => {
            state.usersuccess = true;
            state.usersData = action.payload;
        },
        userListError: (state, action) => {
            state.usersuccess = false;
            state.usersData = [];
        },
        userDataSuccess: (state, action) => {
            state.usersuccess = true;
            state.user = action.payload;
        },
        userRolesSuccess: (state, action) => {
            state.usersuccess = true;
            state.userRoles = action.payload;
        },
        setTotalUsersCount: (state, action) => {
            state.usersuccess = true;
            state.TotalCount = action.payload;
        }

    }
})
export const {
    userListSuccess,
    userListError,
    userRolesSuccess,
    userDataSuccess,
    setTotalUsersCount
} = userSlice.actions;


export default userSlice.reducer;