import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Grid, Paper, InputLabel, TextField, FormControl, Select, MenuItem, FormControlLabel, Tooltip, Fab } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { getSurveyList, deleteSurvey, getAuditTypesList, searchSurvey, getAuditNamesList, getReviewerList } from '../../store/surveySlice';
import AddIcon from '@mui/icons-material/Add';
import StorageIcon from '@mui/icons-material/Storage';
import ConfirmationDialog from '../../commonui/confirmation';
import Navbar from "../../Navigation/Navbar";
import Message from '../../commonui/snackbar';
import CircularProgressLoader from '../../commonui/circularProgressLoader';
import Papa from 'papaparse';
function Surveys() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const surveylist = useSelector(({ surveys }) => surveys.surveysData);
    const TotalCount = useSelector(({ surveys }) => surveys.TotalCount);
    const auditTypeList = useSelector(({ surveys }) => surveys.auditTypeData);
    const auditnameslist = useSelector(({ surveys }) => surveys.auditnamelist);
    const reviewerslist = useSelector(({ surveys }) => surveys.reviewers);
    const [open, setOpen] = useState(false);
    const isloadingvalue = useSelector(({ surveys }) => surveys.isloading);
    const [loading, setLoading] = useState();
    const [Surveyid, setSurveyId] = useState(0);
    const [RoleId, setRoleId] = useState(localStorage.getItem('AccessId'))
    const [reviewerId, setReviewerid] = useState(localStorage.getItem('UserId'));
    const [drpdDisabled, setDisabled] = useState(false);
    const [searchData, setSearch] = useState({
        searchClaimnumber: "",
        searchClaimant: "",
        searchexaminer: "",
        searchAudittypeId: "All",
        searchLossdateFrom: "",
        searchLossdateTo: "",
        searchAuditName: "",
        searchAuditNameId: "All",
        searchLossCause: "",
        searchClient: "",
        searchServiceProvider: "",
        //  searchReviewer: "",
        searchMajorPeril: "",
        searchAssignDate: "",
        searchBusinessUnit: "",
        searchReviewerId: "All",
        rowsPerPage: 25,
        page: 0,
    })

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    useEffect(() => {
        dispatch(getAuditTypesList());
        dispatch(getAuditNamesList());
        dispatch(getReviewerList());
        dispatch(getSurveyList(page * rowsPerPage, rowsPerPage,));
        if (RoleId === "5e8fe1e9fb38c9ce40de0009") {
            setDisabled(true);
            updateForm({ searchReviewerId: reviewerId });
        }
    }, []);

    useEffect(() => {
        dispatch(getSurveyList(page * rowsPerPage, rowsPerPage,));
    }, [page, rowsPerPage, open]);

    useEffect(() => {
        setLoading(isloadingvalue)
    }, [isloadingvalue]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
    };

    function updateForm(value) {

        return setSearch((prev) => {
            return { ...prev, ...value };
        });
    }
    async function editSurvey(id) {

        navigate(`/administarion/surveys/addsurvey/${id}`)
    }
    const handleAuditNameDropdownChange = (event) => {

        if (event.target.value === "All") {
            updateForm({ searchAudittypeId: "All" });
            updateForm({ searchAuditNameId: event.target.value });
        }
        else {
            let auditnamereocrd = auditnameslist.filter(auditnamedata => auditnamedata._id === event.target.value);
            updateForm({ searchAudittypeId: auditnamereocrd[0].audittypeId._id });
            //  updateForm({ searchServiceProvider: auditnamereocrd[0].serviceprovider });
            //updateForm({ searchClient: auditnamereocrd[0].client });
            // updateForm({ searchBusinessUnit: auditnamereocrd[0].businessunit });
            updateForm({ searchAuditNameId: event.target.value });
        }
    };
    async function searchClick() {
        setSearch.page = page * rowsPerPage;
        setSearch.rowsPerPage = rowsPerPage;
        setSearch(searchData);
        dispatch(searchSurvey(searchData));
    }
    async function searchClear() {
        setSearch({
            searchClaimnumber: "",
            searchClaimant: "",
            searchexaminer: "",
            searchAuditName: "",
            searchLossCause: "",
            searchClient: "",
            searchServiceProvider: "",
            //  searchReviewer: "",
            searchAudittypeId: "All",
            searchLossdateFrom: "",
            searchLossdateTo: "",
            searchMajorPeril: "",
            searchAssignDate: "",
            searchAuditNameId: "All",
            searchReviewerId: "All",
            rowsPerPage: 25,
            page: 0,
        });
        setRowsPerPage(25);
        setPage(0);
        dispatch(getSurveyList(page * rowsPerPage, rowsPerPage));
    }

    async function deleteSurveyData(id) {

        setOpen(true);
        setSurveyId(id);

        // setOpen(false);
    }
    const handleClose = (newValue) => {
        setOpen(false);
        if (newValue) {
            dispatch(deleteSurvey(Surveyid));
        }
    };
    const CovertDateFormat = (mongoDate) => {
        const lossDateObject = new Date(mongoDate);

        // Format the date as "MM/dd/yyyy"
        const formattedLossDate = `${(lossDateObject.getMonth() + 1).toString().padStart(2, '0')}/${lossDateObject.getDate().toString().padStart(2, '0')}/${lossDateObject.getFullYear()}`;
        return formattedLossDate;
    };
    //  console.log(surveylist)
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    /**Excel Download */
    const handleDownload = () => {

        setSearch.page = 1 * 99990;
        setSearch.rowsPerPage = 99990;
        setSearch(searchData);
        dispatch(searchSurvey(searchData));
        const tableElement = document.getElementById('SurveyListtblExcel');
        const tableData = extractTableData(tableElement);
        const csvData = Papa.unparse(tableData);

        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'SurveyList.csv';
        a.click();
        URL.revokeObjectURL(url);
        setRowsPerPage(25);
        setPage(0);
        dispatch(getSurveyList(page * rowsPerPage, rowsPerPage));
    };

    const extractTableData = (tableElement) => {
        const tableData = [];
        const headers = [];

        // Extract headers from the first row
        const headerCells = tableElement.querySelectorAll('thead th');
        for (let j = 0; j < headerCells.length; j++) {
            headers.push(headerCells[j].innerText);
        }
        tableData.push(headers);

        const rows = tableElement.querySelectorAll('tbody tr');
        for (let i = 0; i < rows.length; i++) {
            const cells = rows[i].getElementsByTagName('td');
            const rowData = [];

            for (let j = 0; j < cells.length; j++) {
                rowData.push(cells[j].innerText);
            }

            tableData.push(rowData);
        }

        return tableData;
    };
    console.log(reviewerslist);
    return (
        <div>
            <Navbar /><Message></Message>
            {loading && < CircularProgressLoader loading={loading} />}
            <Box
                sx={{
                    marginTop: 1,
                    marginLeft: '5%',
                    width: '90vw',
                    height: '100%',
                    backgroundColor: 'whitesmoke',
                    borderStyle: 'solid',
                    borderColor: '#213555',
                    borderWidth: 0.5
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: 40,
                        backgroundColor: '#609EA2',
                        borderStyle: 'solid',
                        borderColor: '#213555',
                        borderWidth: 0.5,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Grid container direction="row" sx={{ mt: 1 }}>
                        <Grid item>
                            <StorageIcon sx={{
                                fill: 'white',
                                width: '2.5em',
                                height: '1em',
                            }} fontSize='small' />
                        </Grid>
                        <Grid item xs>
                            <InputLabel id="Add"
                                sx={{ color: 'white' }}
                            >Surveys Management</InputLabel>
                        </Grid>

                        <Grid item xs
                            lg={1.5}>
                            <Button variant="contained"
                                onClick={() => navigate(`/administarion/surveys/addsurvey/${0}`)}
                                sx={{
                                    backgroundColor: ' #213555 !important',
                                    fontColor: 'black',
                                    textTransform: 'none',
                                    lineHeight: 1,
                                    '@media (max-width: 600px)': { // Media query for small screens
                                        maxWidth: '1rem',
                                        fontSize: '0.1rem', // Adjust the font size for small screens
                                    },
                                    display: {
                                        xs: 'none', // Show on small screens
                                        sm: 'block', // Hide on medium screens and larger
                                    },
                                }}>Add Survey</Button></Grid>
                        <Grid item
                            sx={{
                                display: {
                                    xs: 'block', // Show on small screens
                                    sm: 'none', // Hide on medium screens and larger
                                }
                            }}>

                            <Tooltip
                                title="Add Survey"
                                sx={{
                                    position: 'absolute',
                                    top: '50%', // Adjust the vertical position to center
                                    right: 0,
                                    transform: 'translateY(-50%)', // Center the element vertically
                                    //position: 'relative',
                                    // top: '10%', // Center vertically
                                    //left: '50%', // Center horizontally
                                    //transform: 'translate(-50%, -50%)', // Center the element
                                    display: {
                                        xs: 'block', // Show on small screens
                                        sm: 'none', // Hide on medium screens and larger
                                    },
                                }}
                            >
                                <Fab color="primary" aria-label="add" size="small"
                                    sx={{
                                        display: 'block', // Display on small screens
                                        margin: '0 auto', // Center horizontally
                                        marginTop: '0rem', // Add spacing from above
                                        mt: -1,
                                    }}

                                    onClick={() => navigate(`/administarion/surveys/addsurvey/${0}`)}>
                                    <AddIcon sx={{ mt: 1 }} />
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        marginTop: 1,
                        marginLeft: 1,
                        width: '100%',
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Grid container spacing={2} alignItems="center" marginLeft='5%'>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl
                                size='small'
                                sx={{
                                    mr: 2,
                                    mt: 1,
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                }}
                            >
                                <InputLabel id="auditName">Select Audit Name</InputLabel>
                                <Select
                                    labelId="auditname"
                                    required
                                    id="searchAuditNameId"
                                    label="Select Audit Type"
                                    name="searchAuditName"
                                    value={searchData.searchAuditNameId}
                                    onChange={handleAuditNameDropdownChange}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value="All" key="All">All</MenuItem>
                                    {auditnameslist.map((row) => (
                                        <MenuItem
                                            value={row._id}
                                            key={row._id}
                                            sx={{ padding: '0.1rem', width: '30%', maxWidth: '20vm' }}
                                        >
                                            {row.auditname}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {/* <TextField
                                label="Audit Name"
                                placeholder='Search'
                                variant="outlined"
                                color="primary"
                                type="text"
                                name="searchAuditName"
                                size="small"
                                sx={{
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                    mt: 1, mr: 2
                                }}
                                value={searchData.searchAuditName}
                                onChange={(e) => updateForm({ searchAuditName: e.target.value })}
                            /> */}
                            <FormControl
                                size='small'
                                sx={{
                                    mr: 2,
                                    mt: 1,
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                }}
                            >
                                <InputLabel id="auditType">Select Audit Type</InputLabel>
                                <Select
                                    labelId="audittype"
                                    required
                                    id="searchAudittypeId"
                                    label="Select Audit Type"
                                    name="searchAudittypeId"
                                    value={searchData.searchAudittypeId}
                                    onChange={(e) => updateForm({ searchAudittypeId: e.target.value })}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value="All" key="All">All</MenuItem>
                                    {auditTypeList.map((row) => (
                                        <MenuItem
                                            value={row._id}
                                            key={row._id}
                                            sx={{ padding: '0.1rem', width: '30%', maxWidth: '20vm' }}
                                        >
                                            {row.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* </Grid>
                        <Grid item xs={12} sm={7} md={3}> */}
                            {/* <TextField
                                label="Service Provider"
                                placeholder='Search'
                                variant="outlined"
                                color="primary"
                                type="text"
                                name="searchServiceProvider"
                                size="small"
                                sx={{
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                    mt: 1, mr: 2
                                }}
                                value={searchData.searchServiceProvider}
                                onChange={(e) => updateForm({ searchServiceProvider: e.target.value })}
                            /> */}
                            {/* </Grid>
                        <Grid item xs={12} sm={7} md={3}> */}
                            {/* <TextField
                                label="Business Unit"
                                placeholder='Search'
                                variant="outlined"
                                color="primary"
                                type="text"
                                name="searchBusinessUnit"
                                size="small"
                                sx={{
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                    mt: 1, mr: 2
                                }}
                                value={searchData.searchBusinessUnit}
                                onChange={(e) => updateForm({ searchBusinessUnit: e.target.value })}
                            /> */}
                            {/* <TextField
                                label="Client"
                                placeholder='Search'
                                variant="outlined"
                                color="primary"
                                type="text"
                                name="searchClient"
                                size="small"
                                sx={{
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                    mt: 1, mr: 2
                                }}
                                value={searchData.searchClient}
                                onChange={(e) => updateForm({ searchClient: e.target.value })}
                            /> */}
                            <FormControl
                                disabled={drpdDisabled}
                                size='small'
                                sx={{
                                    mr: 2,
                                    mt: 1,
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                }}
                            >
                                <InputLabel id="reviewer">Select Reviewer</InputLabel>
                                <Select
                                    labelId="reviewer"
                                    required
                                    id="searchReviewerId"
                                    label="Select Reviewer"
                                    name="searchReviewerId"
                                    value={searchData.searchReviewerId}
                                    onChange={(e) => updateForm({ searchReviewerId: e.target.value })}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value="All" key="All">All</MenuItem>
                                    {reviewerslist.map((row) => (
                                        <MenuItem
                                            value={row._id}
                                            key={row._id}
                                            sx={{ padding: '0.1rem', width: '30%', maxWidth: '20vm' }}
                                        >
                                            {row.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                label="Examiner"
                                placeholder='Search'
                                variant="outlined"
                                color="primary"
                                type="text"
                                name="searchexaminer"
                                size="small"
                                sx={{
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                    mt: 1, mr: 2
                                }}
                                value={searchData.searchexaminer}
                                onChange={(e) => updateForm({ searchexaminer: e.target.value })}
                            />
                            <TextField
                                label="Assign Date"
                                placeholder=''
                                type="date"
                                // required
                                variant="outlined"
                                color="primary"
                                name="searchAssignDate"
                                value={searchData.searchAssignDate}
                                size="small"
                                onChange={(e) => updateForm({ searchAssignDate: e.target.value })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    mt: 1,
                                    mr: 2,
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center" marginLeft='5%'>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                label="Loss Date From"
                                placeholder=''
                                type="date"
                                // required
                                variant="outlined"
                                color="primary"
                                name="searchLossdateFrom"
                                value={searchData.searchLossdateFrom}
                                size="small"
                                onChange={(e) => updateForm({ searchLossdateFrom: e.target.value })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    mt: 1,
                                    mr: 2,
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                }}
                            />
                            {/* </Grid>
                        <Grid item xs={12} sm={7} md={3}> */}
                            <TextField
                                label="Loss Date To"
                                placeholder=''
                                type="date"
                                // required
                                variant="outlined"
                                color="primary"
                                name="searchLossdateTo"
                                value={searchData.searchLossdateTo}
                                size="small"
                                onChange={(e) => updateForm({ searchLossdateTo: e.target.value })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    mt: 1,
                                    mr: 2,
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                }}
                            />
                            <TextField
                                label="Claim Number"
                                placeholder='Search'
                                variant="outlined"
                                color="primary"
                                type="text"
                                name="searchClaimnumber"
                                size="small"
                                sx={{
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                    mt: 1, mr: 2
                                }}
                                value={searchData.searchClaimnumber}
                                onChange={(e) => updateForm({ searchClaimnumber: e.target.value })}
                            />
                            <TextField
                                label="Claimant"
                                placeholder='Search'
                                variant="outlined"
                                color="primary"
                                type="text"
                                name="searchClaimant"
                                size="small"
                                sx={{
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                    mt: 1, mr: 2
                                }}
                                value={searchData.searchClaimant}
                                onChange={(e) => updateForm({ searchClaimant: e.target.value })}
                            />
                            <TextField
                                label="Major Peril"
                                placeholder='Search'
                                variant="outlined"
                                color="primary"
                                type="text"
                                name="searchMajorPeril"
                                size="small"
                                sx={{
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                    mt: 1, mr: 2
                                }}
                                value={searchData.searchMajorPeril}
                                onChange={(e) => updateForm({ searchMajorPeril: e.target.value })}
                            />

                            {/* <TextField
                                label="Reviewer"
                                placeholder='Search'
                                variant="outlined"
                                color="primary"
                                type="text"
                                name="searchReviewer"
                                size="small"
                                sx={{
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                    mt: 1, mr: 2
                                }}
                                value={searchData.searchReviewer}
                                onChange={(e) => updateForm({ searchReviewer: e.target.value })}
                            /> */}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center" >
                        <Grid item xs={12} sm={12} md={12} sx={{ ml: { xs: '15%', sm: '75%' } }}>

                            <Button
                                variant="contained"
                                sx={{
                                    mt: 1,
                                    mr: 1,
                                    backgroundColor: '#213555 !important',
                                    fontColor: 'black',
                                    textTransform: 'none',
                                    lineHeight: 1.8,
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '5rem', // Set a maximum width to limit the size
                                }}
                                onClick={() => searchClick()}
                            >
                                Search
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    mt: 1,
                                    mr: 1,
                                    backgroundColor: '#557A46 !important',
                                    fontColor: 'black',
                                    textTransform: 'none',
                                    lineHeight: 1.8,
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '5rem', // Set a maximum width to limit the size
                                }}
                                onClick={handleDownload}
                            >
                                Excel
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    mt: 1,
                                    //ml: 1,
                                    backgroundColor: 'Gray !important',
                                    fontColor: 'black',
                                    textTransform: 'none',
                                    lineHeight: 1.8,
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '5rem', // Set a maximum width to limit the size
                                }}
                                onClick={() => searchClear()}
                            >
                                Clear
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    sx={{
                        marginTop: 2,
                        marginLeft: 1,
                        marginRight: 1,
                        height: '65%',
                        backgroundColor: 'white',
                    }}
                >
                    <TableContainer component={Paper}>
                        <Table id="SurveyListtbl" sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold' }} size='small' aria-label="Survey table">
                            <TableHead sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold', fontSize: '0.975rem !important', backgroundColor: '#898B8A', color: 'white' }}>
                                <TableRow>
                                    <TableCell sx={{ color: 'white', width: '5%' }}>Audit Name</TableCell>
                                    <TableCell sx={{ color: 'white', width: '5%', whiteSpace: 'nowrap' }}>Claim Number</TableCell>
                                    <TableCell sx={{ color: 'white', width: '5%' }}>Claimant</TableCell>
                                    <TableCell sx={{ color: 'white', width: '5%' }}>Loss Date</TableCell>
                                    <TableCell sx={{ color: 'white', width: '10%' }}>Examiner</TableCell>
                                    <TableCell sx={{ color: 'white', width: '10%' }}>Audit Type</TableCell>
                                    <TableCell sx={{ color: 'white', width: '5%', whiteSpace: 'nowrap' }}>Active Status</TableCell>
                                    <TableCell sx={{ color: 'white', width: '5%', whiteSpace: 'nowrap' }}>Total Score</TableCell>
                                    <TableCell sx={{ color: 'white', width: '8%' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {surveylist.map((row) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell width="10%">{row.auditnameId ? row.auditnameId.auditname : ""}</TableCell>
                                        <TableCell width="5%" sx={{ whiteSpace: 'nowrap' }}>{row.claimnumber}</TableCell>
                                        <TableCell width="5%">{row.claimant}</TableCell>
                                        <TableCell width="5%">{CovertDateFormat(row.lossdate)}</TableCell>
                                        <TableCell width="10%">{row.examiner}</TableCell>
                                        <TableCell width="10%">{row.audittypeId ? row.audittypeId.name : ""}</TableCell>
                                        <TableCell width="10%" sx={{ whiteSpace: 'nowrap' }}>{row.Active ? "Open" : "Closed"}</TableCell>
                                        <TableCell width="10%">{row.totalScore}</TableCell>
                                        <TableCell colSpan={2} sx={{ whiteSpace: 'nowrap' }}>
                                            <IconButton aria-label="edit" onClick={() => editSurvey(row._id)} size="small">
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton aria-label="delete" onClick={() => deleteSurveyData(row._id)} size="small">
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={TotalCount ? TotalCount : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Box>
            <ConfirmationDialog
                id="ringtone-menu"
                keepMounted
                open={open}
                text={("Are you sure you want to close this survey?")}
                onClose={handleClose}
                value={Surveyid}
            >
            </ConfirmationDialog>
            <TableContainer component={Paper} sx={{ display: 'none' }}>
                <Table id="SurveyListtblExcel" sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold' }} size='small' aria-label="Survey table">
                    <TableHead sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold', fontSize: '0.975rem !important', backgroundColor: '#898B8A', color: 'white' }}>
                        <TableRow>
                            <TableCell sx={{ color: 'white', width: '5%' }}>Audit Name</TableCell>
                            <TableCell sx={{ color: 'white', width: '5%', whiteSpace: 'nowrap' }}>Claim Number</TableCell>
                            <TableCell sx={{ color: 'white', width: '5%' }}>Claimant</TableCell>
                            <TableCell sx={{ color: 'white', width: '5%' }}>Loss Date</TableCell>
                            <TableCell sx={{ color: 'white', width: '10%' }}>Examiner</TableCell>
                            <TableCell sx={{ color: 'white', width: '10%' }}>Audit Type</TableCell>
                            <TableCell sx={{ color: 'white', width: '5%', whiteSpace: 'nowrap' }}>Active Status</TableCell>
                            <TableCell sx={{ color: 'white', width: '5%', whiteSpace: 'nowrap' }}>Total Score</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {surveylist.map((row) => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell width="10%">{row.auditnameId ? row.auditnameId.auditname : ""}</TableCell>
                                <TableCell width="5%" sx={{ whiteSpace: 'nowrap' }}>{row.claimnumber}</TableCell>
                                <TableCell width="5%">{row.claimant}</TableCell>
                                <TableCell width="5%">{CovertDateFormat(row.lossdate)}</TableCell>
                                <TableCell width="10%">{row.examiner}</TableCell>
                                <TableCell width="10%">{row.audittypeId ? row.audittypeId.name : ""}</TableCell>
                                <TableCell width="10%" sx={{ whiteSpace: 'nowrap' }}>{row.Active ? "Open" : "Closed"}</TableCell>
                                <TableCell width="10%">{row.totalScore}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    );
}

export default Surveys