import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from "../store/messageSlice";

export const getAuditTypeList = (pageindex, pagesize) => async dispatch => {
    dispatch(setLoading(true));
    await axios.get(process.env.REACT_APP_URL.concat(`getAuditTypeList/${pageindex}/${pagesize}`), { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {
            let x = await res.data;
            if (x.status === true) {
                dispatch(setTotalCount(x.data.TotalCount));
                dispatch(setLoading(false));
                return dispatch(auditTypyListSuccess(x.data.AuditTypeList));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
                dispatch(setLoading(false));
            }
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            dispatch(setLoading(false));
            return dispatch(auditTypyListError([]));
        });

};

export const deleteType = (id) => async dispatch => {
    dispatch(setLoading(true));
    await axios.get(process.env.REACT_APP_URL.concat(`audittypedelete/${id}`), { withCredentials: true })
        .then(res => {
            let x = res.data


            if (x.status === true) {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'success'
                }));
                getAuditTypeList(0, 10);
                dispatch(setLoading(false));
                return dispatch(auditTypyDataSuccess(x.savedType));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                })).catch(error => {
                    dispatch(showMessage({
                        message: error.message, autoHideDuration: 4000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        severity: 'warning'
                    }));
                    dispatch(setLoading(false));
                    return dispatch(auditTypyListError([]));
                });
            }
        }
        );

};
export const GetAuditType = (id) => async dispatch => {

    if (id !== "0") {
        await axios.get(process.env.REACT_APP_URL.concat(`getaudittype/${id}`), { withCredentials: true })
            .then(res => {
                let x = res.data

                if (x.status === true) {
                    return dispatch(auditTypyDataSuccess(x.savedType));
                }

            }).catch(error => {
                dispatch(showMessage({
                    message: error.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
                return dispatch(auditTypyListError([]));
            });
    }
};
export const saveData = (data, secionsChnaged) => async dispatch => {
    dispatch(setLoading(true));
    var URL = "";
    let Savedata = [];
    if (data._id === '0' || data._id === '') { // first time add
        let list = await GetSectionwiseQtnList(data.sections);
        Savedata =
        {
            _id: data._id,
            name: data.name,
            description: data.description,
            sections: data.sections,
            sectionsquestions: list,
        }
        URL = process.env.REACT_APP_URL.concat('auditTypeAdd');
    }
    else { // on edit
        if (secionsChnaged) { // previous and latest sections not same
            let newSectionQuestionlist = await GetSectionwiseQtnList(data.Sections);
            const Orignallist = JSON.parse(JSON.stringify(data.SectionQuestionList))
            let UpdatedSectionquestionlist = await compaireList(Orignallist, newSectionQuestionlist);
            const sectionIdList = data.Sections;

            // // // // Filter array2 to remove elements with _id not in sectionIdList
            let UpdatedSectionquestionlist1 = UpdatedSectionquestionlist.filter(element => sectionIdList.includes(element._id));

            Savedata =
            {
                _id: data._id,
                name: data.name,
                description: data.description,
                sections: data.Sections,
                sectionsquestions: UpdatedSectionquestionlist1,
                active: true,
            }
        }
        else {
            Savedata =
            {
                _id: data._id,
                name: data.name,
                description: data.description,
                sections: data.Sections,
                sectionsquestions: data.SectionQuestionList,
                active: true,
            }
        }
        URL = process.env.REACT_APP_URL.concat(`auditTypeEdit`);
    }

    await axios.post(URL, Savedata, { withCredentials: true })
        .then(res => {
            let x = res.data


            if (x.status === true) {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'success'
                }));
                dispatch(getAuditTypeList(0, 100));
                dispatch(setLoading(false));
                return dispatch(auditTypyDataSuccess(x.savedType));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
                dispatch(setLoading(false));
            }
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            dispatch(setLoading(false));
            return dispatch(auditTypyListError([]));
        });;

};
export const setSortorder = (sortoder, sectionname, AuditType) => async dispatch => {
    debugger;
    dispatch(setSortorder(sortoder, sectionname, AuditType));
};
/**compaire old and new array  */
const compaireList = async (firstArray, secondArray) => {
    secondArray.forEach(secondObj => {
        const matchingObj = firstArray.find(firstObj => firstObj.sectionname === secondObj.sectionname);

        if (matchingObj) {
            secondObj.questions.forEach(question => {
                const duplicateQuestion = matchingObj.questions.find(q => q._id === question._id);
                if (!duplicateQuestion) {
                    matchingObj.questions.push(question);
                }
            });
        } else {
            const newObject = {
                _id: secondObj._id,
                sectionname: secondObj.sectionname,
                //   sectioncode: secondObj.sectioncode,             // added after sectioncode added in sectionmaster
                questions: secondObj.questions
            };
            firstArray.push(newObject);
        }
    });
    return firstArray;
}
export const saveAuditTypeQuestion = (data, audittype) => async dispatch => {

    if (data._id === "") {
        const newData = {
            _id: Date.now().toString(36) + Math.random().toString(36).substring(2),
            question: data.question,
            points: data.points,
            remarks: data.remarks,
            section: data.sectionId
        };

        const sectionId = data.sectionId;

        // Create a deep copy of the audittype object and its nested arrays
        const copiedAudittype = JSON.parse(JSON.stringify(audittype));

        // Find the section by sectionId in the copiedAudittype
        const sectionIndex = copiedAudittype.SectionQuestionList.findIndex(section => section._id === sectionId);

        if (sectionIndex !== -1) {
            // Push the new question into the questions array of the copied section
            copiedAudittype.SectionQuestionList[sectionIndex].questions.push(newData);

            //dispatch(auditTypyDataSuccess(copiedAudittype));
            dispatch(saveData(copiedAudittype));
        }
    }
    else {

        const sectionId = data.sectionId; // Specify the section ID for which you want to add a new question
        const copiedAudittype = JSON.parse(JSON.stringify(audittype));
        // Find the section by sectionId
        const sectionIndex = copiedAudittype.SectionQuestionList.findIndex(section => section._id === sectionId);

        if (sectionIndex !== -1) {
            const section = copiedAudittype.SectionQuestionList[sectionIndex];
            const question = section.questions.find(q => q._id === data._id);

            if (question) {
                question.question = data.question;
                question.points = data.points;
                question.remarks = data.remarks;
                question.section = data.sectionId;
            }
        };
        //dispatch(auditTypyDataSuccess(copiedAudittype));
        dispatch(saveData(copiedAudittype));
    }

};
export const DeleteAuditTypeQuestion = (audittype, sectionid, quetiontxt) => async dispatch => {

    const updatedGroupedData = audittype.SectionQuestionList.map(section => {
        if (section._id === sectionid) {
            const updatedQuestions = section.questions.filter(question => question.question !== quetiontxt);
            return { ...section, questions: updatedQuestions };
        }
        return section;
    });
    const newdata =
    {
        _id: audittype._id,
        name: audittype.name,
        description: audittype.description,
        Sections: audittype.Sections,
        SectionQuestionList: updatedGroupedData,
        active: true,
    }
    // dispatch(auditTypyDataSuccess(newdata));
    dispatch(saveData(newdata));
};
export const SetSectionSortOrder = (newSortOrder, sectionname, savedaudittype) => async dispatch => {
    // Find the section within SectionQuestionList by sectionname
    const sectionToUpdate = savedaudittype.SectionQuestionList.find(section => section.sectionname === sectionname);

    // Create a new array with the updated SectionQuestionList
    const updatedSectionQuestionList = savedaudittype.SectionQuestionList.map(section => {
        if (section === sectionToUpdate) {
            // Create a new object with the updated sortorder
            return {
                ...section,
                sortorder: parseInt(newSortOrder, 10),
            };
        }
        return section;
    });
    // Sort the SectionQuestionList based on the sortorder
    const sortedSectionQuestionList = updatedSectionQuestionList.slice().sort((a, b) => a.sortorder - b.sortorder);
    // Create a new object with the updated SectionQuestionList and other properties
    const newdata = {
        ...savedaudittype,
        SectionQuestionList: sortedSectionQuestionList,
    };

    // Dispatch the action with the updated data
    dispatch(saveData(newdata));
};


export const search = (data) => async dispatch => {
    dispatch(setLoading(true));
    await axios.post(process.env.REACT_APP_URL.concat('search'), data, { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data;
            if (res.data.status === true) {
                dispatch(setTotalCount(x.data.TotalCount));
                dispatch(setLoading(false));
                return dispatch(auditTypyListSuccess(x.data.List));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
            dispatch(setLoading(false));
            return dispatch(auditTypyListError([]));
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            dispatch(setLoading(false));
            return dispatch(auditTypyListError([]));
        });

};
export const GetSections = () => async dispatch => {
    dispatch(setLoading(true));
    await axios.get(process.env.REACT_APP_URL.concat('getsectionlist'), { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data;
            if (res.data.status === true) {
                dispatch(setLoading(false));
                return dispatch(SectionListSuccess(x.list));
            }
            else {
                dispatch(showMessage({
                    message: "Get Sections" + x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
                dispatch(setLoading(false));
            }
        }).catch(error => {
            dispatch(showMessage({
                message: "Get Sections" + error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            dispatch(setLoading(false));
            return dispatch(SectionListError([]));
        });

};
const GetSectionwiseQtnList = async (sections) => {
    let groupedArray = [];
    await axios.post(process.env.REACT_APP_URL.concat('getsectionwiseqtnList'), sections, { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data;
            if (res.data.status === true) {
                const data = x.list;
                // Grouping logic
                const groupedData = data.reduce((result, item) => {
                    const { _id, question, points, remarks, sectionId } = item;
                    const section = sectionId._id;
                    if (!result[section]) {
                        result[section] = {
                            _id: section,
                            sectionname: sectionId.section,
                            //  sectioncode: sectionId.sectioncode,                    //added after sectioncode changes for section master
                            questions: []
                        };
                    }
                    result[section].questions.push({
                        _id,
                        question,
                        points,
                        remarks,
                        section
                    });
                    return result;
                }, {});
                groupedArray = Object.values(groupedData);
            }
        });
    debugger;
    groupedArray = updateAuditTypesWithSortOrder(Object.values(groupedArray));
    return groupedArray;
};

function updateAuditTypesWithSortOrder(SectionQuestionList) {
    const updatedSectionQuestionList = SectionQuestionList.map(section => ({
        ...section,
        sortorder: 0,
    }));
    return updatedSectionQuestionList;
}
const initialState = {
    audittypesuccess: false,
    audittypesuccess1: false,
    audittypeData: [],
    audittype: [],
    sectionList: [],
    isloading: false,
};

const audittypeSlice = createSlice({
    name: 'audittypeithSections',
    initialState,
    reducers: {
        auditTypyListSuccess: (state, action) => {

            state.audittypesuccess = true;
            state.audittypeData = action.payload;
        },
        auditTypyListError: (state, action) => {
            state.audittypesuccess = false;
            state.audittypeData = [];
        },
        auditTypyDataSuccess: (state, action) => {
            state.audittypesuccess = true;
            state.audittype = action.payload;
        },
        auditTypyClear: (state, action) => {
            state.audittypesuccess = true;
            state.audittype = [];
        },
        setTotalCount: (state, action) => {
            state.audittypesuccess = true;
            state.TotalCount = action.payload;
        },
        /**added for sectionwise questions on audittypa */
        SectionListSuccess: (state, action) => {
            state.sectionList = action.payload;
        },
        SectionListError: (state, action) => {
            state.sectionList = [];
        },
        auditTypyEdit: (state, action) => {

            state.audittype = state.audittypeData.filter(x => x._id == action.payload);
            state.audittypesuccess1 = true;
        },
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
    }
})
export const {
    auditTypyListSuccess,
    auditTypyListError,
    setTotalCount,
    auditTypyDataSuccess,
    SectionListSuccess,
    SectionListError,
    SectionQtnListSuccess,
    SectionQtnListError,
    auditTypyEdit,
    auditTypyClear,
    setLoading,
} = audittypeSlice.actions;


export default audittypeSlice.reducer;