import { amber, blue, green } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@mui/material/Typography';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    hideMessage,
    selectMessageOptions,
    selectMessageState,
} from '../store/messageSlice';


const StyledSnackbar = styled(Snackbar)(({ theme, severity }) => ({
    '& .FuseMessage-content': {
        ...(severity === 'success' && {
            backgroundColor: green[600],
            color: '#FFFFFF',
        }),

        ...(severity === 'error' && {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.getContrastText(theme.palette.error.dark),
        }),

        ...(severity === 'info' && {
            backgroundColor: blue[600],
            color: '#FFFFFF',
        }),

        ...(severity === 'warning' && {
            backgroundColor: amber[600],
            color: '#FFFFFF',
        }),
    },
}));

const severityIcon = {
    success: 'check_circle',
    warning: 'warning',
    error: 'error_outline',
    info: 'info',
};

function Message(props) {
    const dispatch = useDispatch();
    const state = useSelector(({ message }) => message.snackbarOpen);
    const options = useSelector(({ message }) => message.options);

    return (
        <StyledSnackbar
            {...options}
            open={state}
            onClose={() => dispatch(hideMessage())}
            ContentProps={{
                severity: 'body2',
                headlineMapping: {
                    body1: 'div',
                    body2: 'div',
                },
            }}
        >
            <SnackbarContent
                className="FuseMessage-content"
                message={
                    <div className="flex items-center">

                        <Typography className="mx-8">{options.message}</Typography>
                    </div>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => dispatch(hideMessage())}
                        size="large"
                    >

                    </IconButton>,
                ]}
            />
        </StyledSnackbar>
    );
}

export default memo(Message);
