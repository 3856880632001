import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import { Select, MenuItem, InputLabel, FormControl, FormControlLabel, Checkbox } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Navbar from "../Navigation/Navbar";
import DescriptionIcon from '@mui/icons-material/Description';
import { getAuditTypesList, getAuditNamesList, getReviewerList } from '../../src/store/surveySlice';
import { getOverallAverage, getExaminernames } from '../../src/store/reportsSlice';
import { getSections } from '../../src/store/questionSlice';
import Autocomplete from '@mui/material/Autocomplete';
import './print.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Papa from 'papaparse';
import Message from '../../src/commonui/snackbar';
import CircularProgressLoader from '../../src/commonui/circularProgressLoader';
function OverallAverages() {
    const dispatch = useDispatch();
    const [showDiv, setShowDiv] = useState(false);
    const navigate = useNavigate();
    const pdfRef = useRef();
    const auditTypeList = useSelector(({ surveys }) => surveys.auditTypeData);
    const reportData1 = useSelector(({ reports }) => reports.summaryReportData);
    const TotalCount = useSelector(({ reports }) => reports.TotalCount);
    const TotalCriticalSections = useSelector(({ reports }) => reports.TotalCriticalSections);
    const eaxminerlist = useSelector(({ reports }) => reports.adjusterlist);
    const auditnameslist = useSelector(({ surveys }) => surveys.auditnamelist);
    const [reportData, setReportData] = React.useState(reportData1);
    const examinerlist = useSelector(({ reports }) => reports.adjusterlist);
    const [examinernames, setExaminerlist] = useState(examinerlist);
    const reviewerslist = useSelector(({ surveys }) => surveys.reviewers);
    const [selectedExaminer, setadjname] = useState('');
    const isloadingvalue = useSelector(({ reports }) => reports.isloading);
    const [loading, setLoading] = useState();
    useEffect(() => {
        setReportData(reportData1);
    }, [reportData1]);
    useEffect(() => {
        setExaminerlist(eaxminerlist);
    }, [eaxminerlist]);
    const [searchData, setSearch] = useState({
        searchClaimnumber: "",
        searchExaminer: "",
        searchAudittypeId: [],
        searchEvaluationDate: "",
        searchServiceProvider: "",
        searchAuditNameId: [],
        searchLossCause: "",
        searchLossdateFrom: "",
        searchLossdateTo: "",
        searchMajorPeril: "",
        searchReviewerId: [],
        searchClaimant: "",
    })
    useEffect(() => {
        dispatch(getAuditTypesList());
        dispatch(getExaminernames());
        dispatch(getAuditNamesList());
        dispatch(getReviewerList());
    }, []);
    useEffect(() => {
        setLoading(isloadingvalue)
    }, [isloadingvalue]);
    /**default functions */
    function updateForm(value) {
        return setSearch((prev) => {
            return { ...prev, ...value };
        });
    }
    function clearSearch() {
        setReportData([]);
        // setSectionlist([]);
        setSearch({
            searchClaimnumber: "",
            searchExaminer: "",
            searchAudittypeId: [],
            searchEvaluationDate: "",
            searchServiceProvider: "",
            searchAuditNameId: [],
            searchLossCause: "",
            searchLossdateFrom: "",
            searchLossdateTo: "",
            searchMajorPeril: "",
            searchReviewerId: [],
            searchClaimant: "",
        })
        setShowDiv(false);
        setadjname('');
    }
    /**default functions */

    const formatYAxis = (tick) => `${tick}%`; // Function to format Y-axis ticks with %
    const handleGenerate = () => {
        //  logic for generating the chart data goes here...
        // Set showDiv to true to show the chart after generating the data
        if (selectedExaminer) { searchData.searchExaminer = selectedExaminer.name; }
        dispatch(getOverallAverage(searchData));
        setShowDiv(true);
    };
    const handlePrint = () => {
        //  logic for generating the chart data goes here...
        // Set showDiv to true to show the chart after generating the data
        window.print();
    };
    const currentDate = new Date();

    // Format the date in "dd/mm/yyyy" format
    const formattedDate = currentDate.toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone: 'UTC' // Set the desired time zone, if needed
    });
    // Remove leading zeros from the time (if present)
    const parts = formattedDate.split(', ');
    const timePart = parts[1];
    const [time, amPm] = timePart.split(' ');
    const [hour, minute] = time.split(':');
    const formattedTime = `${parseInt(hour, 10)}:${minute} ${amPm}`;

    // Combine the date and formatted time
    const ReportTime = `${parts[0]}, ${formattedTime}`;

    // console.log(ReportTime); // Output: "08/08/2023, 12:48 PM"
    const downloadPdf = () => {
        if (showDiv) {
            const input = pdfRef.current;
            html2canvas(input).then((canvas) => {
                const pdf = new jsPDF('p', 'mm', 'a4', true);
                const pdfwidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = canvas.width;
                const imgHeight = canvas.height;
                const leftMargin = 10; // Left margin in mm
                const rightMargin = 10; // Right margin in mm
                const bottomMargin = 10;
                const bottomPadding = 10;
                const usableWidth = pdfwidth - leftMargin - rightMargin;
                const usableHeight = pdfHeight - bottomMargin;
                const ratio = usableWidth / imgWidth;
                const imgX = leftMargin;
                const imgY = bottomMargin + bottomPadding;
                pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
                // Calculate the total number of pages
                const totalPages = Math.ceil((imgHeight * ratio) / usableHeight);

                // Loop through the rest of the pages and add page numbers
                for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
                    if (pageNum > 1) {
                        pdf.addPage();
                    }
                    pdf.addImage(
                        imgData,
                        'PNG',
                        imgX,
                        -(pdfHeight / ratio) * (pageNum - 1) + imgY, // Move the image up for each new page
                        imgWidth * ratio,
                        imgHeight * ratio
                    );
                    pdf.setFontSize(10);
                    // Add the page number at the bottom right corner
                    pdf.text(pdfwidth - 20, pdfHeight - 10, `Page ${pageNum} of ${totalPages}`);
                }
                const currentDate = new Date();

                // Format the date in "dd/mm/yyyy" format
                const DateString = currentDate.toLocaleString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                });
                pdf.save(`OverallcoverageReport-${DateString}.pdf`);
            });
        }
        else {
            alert("Data not found to generate pdf.")
        }
    }
    const handleAutocompleteChange = (event, newValue) => {
        setadjname(newValue); // Update the selected value in state
    };
    const handleInputChange = (event, newInputValue) => {
        // setadjname(newInputValue);
        searchData.searchExaminer = newInputValue;
    };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const handleAuditNameDropdownChange = (event) => {

        if (event.target.value === "All") {
            updateForm({ searchAudittypeId: "All" });
            updateForm({ searchAuditNameId: event.target.value });
        }
        else {
            let auditnamereocrd = auditnameslist.filter(auditnamedata => auditnamedata._id === event.target.value);
            updateForm({ searchAudittypeId: auditnamereocrd[0].audittypeId._id });
            updateForm({ searchAuditNameId: event.target.value });
        }
    };
    // const getAuditName = (idarray) => {
    //     if (id !== "All") {
    //         let auditnamereocrd = auditnameslist.filter(auditnamedata => auditnamedata._id === id);
    //         return auditnamereocrd[0].auditname;
    //     }
    //     else {
    //         return "All"
    //     }
    // };
    const getAuditNames = (idarray) => {
        if (!idarray || idarray.length === 0) {
            return ["All"]; // Return an array with "All" when idarray is blank or has length 0
        }

        // Assuming auditnameslist is an array of audit names with _id and auditname properties
        const auditNameArray = idarray.map(id => {
            const auditnamerecord = auditnameslist.find(auditnamedata => auditnamedata._id === id);
            return auditnamerecord ? auditnamerecord.auditname : null;
        });

        // Filter out null values (if any) and join the names with a comma
        const joinedNames = auditNameArray.filter(name => name !== null).join(', ');
        return joinedNames;
    };



    // const getAuditTypeName = (id) => {

    //     if (id !== "All") {
    //         let audittypenamereocrd = auditTypeList.filter(audittypedata => audittypedata._id === id);
    //         return audittypenamereocrd[0].name;
    //     }
    //     else {
    //         return "All"
    //     }
    // };
    const getAuditTypeName = (idarray) => {
        if (!idarray || idarray.length === 0) {
            return ["All"]; // Return an array with "All" when idarray is blank or has length 0
        }
        const auditTypeNameArray = idarray.map(id => {
            const audittypenamerecord = auditTypeList.find(audittypenamedata => audittypenamedata._id === id);
            return audittypenamerecord ? audittypenamerecord.name : null;
        });

        // Filter out null values (if any) and join the names with a comma
        const joinedNames = auditTypeNameArray.filter(name => name !== null).join(', ');
        return joinedNames;
    };
    const getReviewerName = (idarray) => {
        if (!idarray || idarray.length === 0) {
            return ["All"]; // Return an array with "All" when idarray is blank or has length 0
        }
        const reviewerNameArray = idarray.map(id => {
            const reviewenamerecord = reviewerslist.find(namedata => namedata._id === id);
            return reviewenamerecord ? reviewenamerecord.name : null;
        });

        // Filter out null values (if any) and join the names with a comma
        const joinedNames = reviewerNameArray.filter(name => name !== null).join(', ');
        return joinedNames;
    };
    return (
        <div>
            <div className="no-print">
                <Navbar />  <Message></Message>
                {loading && < CircularProgressLoader loading={loading} />}
            </div>
            <Box
                sx={{
                    marginTop: 1,
                    marginLeft: '15%',
                    marginRight: '2%',
                    width: '70%',
                    height: '100%',
                    backgroundColor: 'white',
                    border: '1px solid #213555',
                    borderRadius: '5px',
                    // padding: '1rem',
                }}
            >
                <Box
                    sx={{
                        mt: 0,
                        width: '100%',
                        height: '2em',
                        backgroundColor: '#22A699',
                        borderBottom: '1px solid #213555',
                        display: 'flex',
                        alignItems: 'center',
                        // paddingLeft: '1rem',
                        color: 'white',
                        fontSize: '1.2rem',
                        // fontWeight: 'bold',
                    }}
                    className='no-print' >
                    <DescriptionIcon />
                    AUDITS - OVERALL AVERAGES
                </Box>
                <Grid container spacing={1} alignItems="center" marginLeft='6%' className='no-print'>
                    <Grid item sx={{
                        mr: 2,
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                    }} >
                        <FormControl
                            size='small'
                            sx={{
                                mr: 2,
                                mt: 1,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                            }}
                        >
                            <InputLabel id="auditName">Select Audit Name</InputLabel>
                            <Select
                                labelId="auditname"
                                required
                                multiple
                                id="searchAuditNameId"
                                label="Select Audit Type"
                                name="searchAuditNameId"
                                value={searchData.searchAuditNameId}
                                //   onChange={handleAuditNameDropdownChange}
                                onChange={(e) => updateForm({ searchAuditNameId: e.target.value })}
                                MenuProps={MenuProps}
                            >
                                {/* <MenuItem value="All" key="All">All</MenuItem> */}
                                {auditnameslist.map((row) => (
                                    <MenuItem
                                        value={row._id}
                                        key={row._id}
                                        sx={{ padding: '0.1rem', width: '30%', maxWidth: '20vm' }}
                                    >
                                        {row.auditname}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }}>
                        <FormControl
                            size='small'
                            sx={{
                                mt: 1,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '16rem', // Set a maximum width to limit the siz
                            }}
                        >
                            <InputLabel id="auditType" >Select Audit Type</InputLabel>
                            <Select
                                labelId="audittype"
                                required
                                multiple
                                id="searchAudittypeId"
                                label="Select Audit Type"
                                name="searchAudittypeId"
                                value={searchData.searchAudittypeId}
                                sx={{
                                    width: '100%', // Set the width to 100% to make it responsive
                                    maxWidth: '15rem', // Set a maximum width to limit the size
                                }}
                                onChange={(e) => updateForm({ searchAudittypeId: e.target.value })}
                                MenuProps={MenuProps}
                            >
                                {/* <MenuItem value="All" key="All">All</MenuItem> */}
                                {auditTypeList.map((row) => (
                                    <MenuItem
                                        value={row._id}
                                        key={row._id}
                                        sx={{ padding: '0.1rem', width: '30%', maxWidth: '20vm' }}
                                    >
                                        {row.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }}>
                        <TextField
                            label="Loss Cause"
                            placeholder='Search'
                            variant="outlined"
                            color="primary"
                            type="text"
                            name="searchLossCause"
                            size="small"
                            sx={{
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                                mt: 1
                            }}
                            value={searchData.searchLossCause}
                            onChange={(e) => updateForm({ searchLossCause: e.target.value })}
                        />
                    </Grid>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }}>
                        <Autocomplete
                            size="small"
                            freeSolo
                            options={examinernames}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Examiner"
                                    placeholder='Search'
                                    variant="outlined"
                                    color="primary"
                                    type="text"
                                    name="searchExaminer"
                                    size="small"
                                //   value={searchData.searchExaminer}
                                // onChange={(e) => updateForm({ searchExaminer: e.target.value })}
                                />
                            )}

                            onChange={handleAutocompleteChange} // Handle selection change
                            onInputChange={handleInputChange}
                            sx={{
                                width: '100%',
                                maxWidth: '15rem',
                                mt: 1,
                                //mr: 3,
                            }}
                        />
                    </Grid>

                </Grid>
                <Grid container spacing={1} alignItems="center" marginLeft='6%' className='no-print'>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }} >
                        <TextField
                            label="Claimant"
                            placeholder='Claimant'
                            variant="outlined"
                            color="primary"
                            type="text"
                            name="searchClaimant"
                            size="small"
                            sx={{
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                                mt: 1,
                            }}
                            value={searchData.searchClaimant}
                            onChange={(e) => updateForm({ searchClaimant: e.target.value })}
                        />
                    </Grid>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }} >
                        <TextField
                            label="Evaluation date"
                            placeholder=''
                            type="date"
                            // required
                            variant="outlined"
                            color="primary"
                            name="searchEvaluationDate"
                            value={searchData.searchEvaluationDate}
                            size="small"
                            onChange={(e) => updateForm({ searchEvaluationDate: e.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{
                                mt: 1,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                            }}
                        />
                    </Grid>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }} >
                        <TextField
                            label="Claim Number"
                            placeholder='Search'
                            variant="outlined"
                            color="primary"
                            type="text"
                            name="searchClaimnumber"
                            size="small"
                            sx={{
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                                mt: 1,
                            }}
                            value={searchData.searchClaimnumber}
                            onChange={(e) => updateForm({ searchClaimnumber: e.target.value })}
                        /></Grid>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        //  mr: 2,
                    }} >
                        <TextField
                            label="Major peril"
                            placeholder='Major Peril'
                            variant="outlined"
                            color="primary"
                            type="text"
                            name="searchMajorPeril"
                            size="small"
                            sx={{
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                                mt: 1,

                            }}
                            value={searchData.searchMajorPeril}
                            onChange={(e) => updateForm({ searchMajorPeril: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center" marginLeft='6%' className='no-print'>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }} >
                        <TextField
                            label="Loss Date From"
                            placeholder=''
                            type="date"
                            // required
                            variant="outlined"
                            color="primary"
                            name="searchLossdateFrom"
                            value={searchData.searchLossdateFrom}
                            size="small"
                            onChange={(e) => updateForm({ searchLossdateFrom: e.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{
                                mt: 1,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                            }}
                        />
                    </Grid>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }} >
                        <TextField
                            label="Loss Date To"
                            placeholder=''
                            type="date"
                            // required
                            variant="outlined"
                            color="primary"
                            name="searchLossdateTo"
                            value={searchData.searchLossdateTo}
                            size="small"
                            onChange={(e) => updateForm({ searchLossdateTo: e.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{
                                mt: 1,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                            }}
                        />
                    </Grid>
                    <Grid item sx={{
                        width: '100%', // Set the width to 100% to make it responsive
                        maxWidth: '15rem', // Set a maximum width to limit the size
                        mr: 2,
                    }} >
                        <FormControl

                            size='small'
                            sx={{
                                mr: 2,
                                mt: 1,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '15rem', // Set a maximum width to limit the size
                            }}
                        >
                            <InputLabel id="reviewer">Select Reviewer</InputLabel>
                            <Select
                                labelId="reviewer"
                                required
                                multiple
                                id="searchReviewerId"
                                label="Select Reviewer"
                                name="searchReviewerId"
                                value={searchData.searchReviewerId}
                                onChange={(e) => updateForm({ searchReviewerId: e.target.value })}
                                MenuProps={MenuProps}
                            >
                                {/* <MenuItem value="All" key="All">All</MenuItem> */}
                                {reviewerslist.map((row) => (
                                    <MenuItem
                                        value={row._id}
                                        key={row._id}
                                        sx={{ padding: '0.1rem', width: '30%', maxWidth: '20vm' }}
                                    >
                                        {row.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center" marginLeft='6%' className='no-print'>
                    <Grid item xs={12} sm={12} md={12} sx={{ ml: { xs: '15%', sm: '60%', }, mt: 1, mb: 2 }}>
                        <Button
                            variant="contained"
                            sx={{
                                mr: 1,
                                backgroundColor: '#213555 !important',
                                fontColor: 'black',
                                textTransform: 'none',
                                lineHeight: 1.8,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '5rem', // Set a maximum width to limit the size
                            }}
                            onClick={handleGenerate}
                        >
                            Generate
                        </Button>
                        {/* <Button
                            variant="contained"
                            sx={{
                                mt: 2,
                                mr: 1,
                                backgroundColor: '#213555 !important',
                                fontColor: 'black',
                                textTransform: 'none',
                                lineHeight: 1.8,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '5rem', // Set a maximum width to limit the size
                            }}
                            onClick={handlePrint}
                        >
                            Print
                        </Button> */}
                        <Button
                            variant="contained"
                            sx={{

                                mr: 1,
                                backgroundColor: '#557A46',
                                fontColor: 'black',
                                textTransform: 'none',
                                lineHeight: 1.8,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '5rem', // Set a maximum width to limit the size
                            }}
                            onClick={downloadPdf}
                        >
                            PDF
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                mt: { xs: 2, sm: 0 },
                                backgroundColor: 'Gray !important',
                                fontColor: 'black',
                                textTransform: 'none',
                                lineHeight: 1.8,
                                width: '100%', // Set the width to 100% to make it responsive
                                maxWidth: '5rem', // Set a maximum width to limit the size
                            }}
                            onClick={() => clearSearch()}
                        >
                            Clear
                        </Button>
                    </Grid>
                </Grid>


                {showDiv && (
                    <Grid container
                        justifyContent="center"

                        sx={{
                            width: '100%', height: '100%',
                            // maxWidth: '50rem', maxHeight: '',
                            //   flexDirection: "column",

                        }}
                        className="print-only print-chart-grid"
                        ref={pdfRef}
                        id="Summaryreporttbl">

                        <Grid item container justifyContent={'center'} md={12}>
                            <InputLabel id="" sx={{
                                fontSize: '1.1rem', fontWeight: 'bold', fontFamily: 'Arial', color: 'Black',
                            }}
                            >   Audits - Overall Average Report</InputLabel>
                        </Grid>
                        <Grid item container justifyContent={'center'} >
                            {/* <Grid item sx={{ width: '80%' }}>
                                <InputLabel
                                    id=""
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        mr: 1,
                                        display: 'inline-block', // Display inline to keep both parts in one line
                                    }}
                                >
                                    Report Date:
                                </InputLabel>
                                <InputLabel
                                    id=""
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'normal', // Set the desired font weight
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        display: 'inline-block', // Display inline to keep both parts in one line
                                        marginRight: '30%',
                                    }}
                                >
                                    {ReportTime}
                                </InputLabel>
                                <InputLabel
                                    id=""
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        //  marginLeft: 5,
                                        display: 'inline-block', // Display inline to keep both parts in one line
                                    }}
                                >
                                    No. of Audits:
                                </InputLabel>
                                <InputLabel
                                    id=""
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'normal', // Set the desired font weight
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        display: 'inline-block', // Display inline to keep both parts in one line
                                        // marginRight: '50%',
                                    }}
                                >
                                    {TotalCount}
                                </InputLabel>
                            </Grid>
                            <Grid item sx={{ width: '80%' }}>
                                <InputLabel
                                    id=""
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        mr: 1,
                                        display: 'inline-block', // Display inline to keep both parts in one line
                                    }}
                                >
                                    Audit Name:
                                </InputLabel>
                                <InputLabel
                                    id=""
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'normal', // Set the desired font weight
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        display: 'inline-block', // Display inline to keep both parts in one line
                                    }}
                                >

                                    {
                                        getAuditNames(searchData.searchAuditNameId)
                                    }
                                </InputLabel>
                            </Grid>
                            <Grid item sx={{ width: '80%' }}>
                                <InputLabel
                                    id=""
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        mr: 1,
                                        display: 'inline-block', // Display inline to keep both parts in one line
                                    }}
                                >
                                    Audit Type:
                                </InputLabel>
                                <InputLabel
                                    id=""
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'normal', // Set the desired font weight
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        display: 'inline-block', // Display inline to keep both parts in one line
                                    }}
                                >
                                    {
                                        getAuditTypeName(searchData.searchAudittypeId)
                                    }
                                </InputLabel>
                            </Grid>
                            <Grid item sx={{ width: '80%' }}>
                                <InputLabel
                                    id=""
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        mr: 1,
                                        display: 'inline-block', // Display inline to keep both parts in one line
                                    }}
                                >
                                    Examiner:
                                </InputLabel>
                                <InputLabel
                                    id=""
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'normal', // Set the desired font weight
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        display: 'inline-block', // Display inline to keep both parts in one line
                                        marginRight: '50%',
                                    }}
                                >
                                    {
                                        searchData.searchExaminer !== "" ? searchData.searchExaminer : " All"
                                    }
                                </InputLabel>
                            </Grid>
                            <Grid item sx={{ width: '80%' }} >
                                <InputLabel
                                    id=""
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        mr: 1,
                                        display: 'inline-block', // Display inline to keep both parts in one line
                                    }}
                                >
                                    Reviewer:
                                </InputLabel>
                                <InputLabel
                                    id=""
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'normal', // Set the desired font weight
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        display: 'inline-block', // Display inline to keep both parts in one line
                                        marginRight: '50%',
                                        wordWrap: "wrap"
                                    }}
                                >
                                    {
                                        getReviewerName(searchData.searchReviewerId)
                                    }
                                </InputLabel>
                            </Grid>

                            <Grid item sx={{ width: '80%' }}>
                                <InputLabel
                                    id=""
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        mr: 1,
                                        display: 'inline-block', // Display inline to keep both parts in one line
                                    }}
                                >
                                    Critical Infractions:
                                </InputLabel>
                                <InputLabel
                                    id=""
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'normal', // Set the desired font weight
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        display: 'inline-block', // Display inline to keep both parts in one line
                                    }}
                                >
                                    {
                                        TotalCriticalSections
                                    }
                                </InputLabel>
                            </Grid> */}
                            <Grid container rowSpacing={1} >
                                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        marginRight: 2,
                                        marginLeft: "15%"
                                    }}>
                                        Report Time:
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '1rem',
                                            fontWeight: 'normal',
                                            fontFamily: 'Arial',
                                            color: 'black',

                                        }}
                                    >
                                        {ReportTime}
                                    </span>
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        marginLeft: "30%",
                                        marginRight: 2,

                                    }}>
                                        No. of Audits:
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '1rem',
                                            fontWeight: 'normal',
                                            fontFamily: 'Arial',
                                            color: 'black',
                                        }}
                                    >
                                        {TotalCount}
                                    </span>
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        marginRight: 2,
                                        marginLeft: "15%"
                                    }}>
                                        Audit Name:
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '1rem',
                                            fontWeight: 'normal',
                                            fontFamily: 'Arial',
                                            color: 'black',

                                        }}
                                    >
                                        {getAuditNames(searchData.searchAuditNameId)}
                                    </span>
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        marginLeft: "30%",
                                        marginRight: 2
                                    }}>
                                        Audit Type:
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '1rem',
                                            fontWeight: 'normal',
                                            fontFamily: 'Arial',
                                            color: 'black',
                                        }}
                                    >
                                        {getAuditTypeName(searchData.searchAudittypeId)}
                                    </span>
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        marginRight: 2,
                                        marginLeft: "15%"
                                    }}>
                                        Examiner Name:
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '1rem',
                                            fontWeight: 'normal',
                                            fontFamily: 'Arial',
                                            color: 'black',

                                        }}
                                    >
                                        {searchData.searchExaminer !== "" ? searchData.searchExaminer : " All"}
                                    </span>
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        marginLeft: "30%",
                                        marginRight: 2
                                    }}>
                                        Reviewer Name:
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '1rem',
                                            fontWeight: 'normal',
                                            fontFamily: 'Arial',
                                            color: 'black',
                                        }}
                                    >
                                        {getReviewerName(searchData.searchReviewerId)}
                                    </span>
                                </Grid>
                                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        fontFamily: 'Arial',
                                        color: 'black',
                                        marginLeft: "15%",
                                        marginRight: 2
                                    }}>
                                        Critical Infractions:
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '1rem',
                                            fontWeight: 'normal',
                                            fontFamily: 'Arial',
                                            color: 'black',
                                        }}
                                    >
                                        {TotalCriticalSections}
                                    </span>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid item
                            justifyContent="center"
                            // marginLeft='20%'
                            sx={{
                                width: '80%', height: '100%', maxWidth: '90rem', maxHeight: '90rem',
                                border: '1px solid black', borderRadius: '1px',
                                //  padding: '3px', 
                                mb: 2, mt: 2
                            }} className="print-only print-chart-container">
                            <ResponsiveContainer
                                justifyContent="center"
                                aspect={4 / 3}
                                className="print-chart-container">
                                <BarChart data={reportData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="sectionname"
                                        interval={0} // Show all X-axis labels, even if they overlap
                                        angle={-45} // Rotate the X-axis labels by -45 degrees
                                        textAnchor="end" // Position the labels at the end of the tick
                                        tickMargin={5} // Add margin between the tick and label to avoid overlapping
                                        height={100} // Increase the height of the X-axis to fit larger labels
                                        fontSize={12}
                                        tick={{ fill: 'black' }} // set color for label 
                                    />
                                    <YAxis
                                        label={{ value: 'Score', angle: -90, position: 'insideLeft' }} // Set the Y-axis label
                                        tickFormatter={formatYAxis}
                                        type="number"
                                        domain={[0, 100]} // Set the Y-axis domain to go up to 120%
                                        tick={{ fill: 'black' }} // set color for label 
                                    />
                                    <Tooltip />
                                    <Legend
                                        // layout="vertical"
                                        // verticalAlign="middle"
                                        // align="bottom"
                                        wrapperStyle={{ paddingLeft: '20px', }} />
                                    <Bar
                                        dataKey="percentage"
                                        fill="fill"
                                        barSize={30}
                                    /> {/* Adjust the barSize here */}
                                </BarChart>
                            </ResponsiveContainer>
                        </Grid>
                    </Grid>


                )}
            </Box>

        </div >
    );
}

export default OverallAverages;
