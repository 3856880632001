import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import websitelogo from "../assets/Logo/interwoven.png";
import Box from '@mui/material/Box';

import "./navbar.css";

import { userLogout } from '../store/loginSlice';

export default function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginuser = useSelector(({ loginuser }) => loginuser.loginData);
  const [accessid, setAccessid] = useState(0);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isAdminDropdownOpen, setAdminDropdownOpen] = useState(false);
  async function handleLogout() {

    dispatch(userLogout());
    const sessionid = localStorage.getItem('session.id');
    if (!sessionid || loginuser.length === 0) {
      navigate("/");
    }
  }
  useEffect(() => {

    const id = localStorage.getItem('UserId')
    if (id === undefined || id === 0 || id === null) {
      navigate("/");
    }
    else {
      const Aid = localStorage.getItem('AccessId')
      setAccessid(Aid);
    }
  }
  );
  const handleToggleReportDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleReportDropdownItemClick = (path) => {
    navigate(path);
    setDropdownOpen(false);
  };

  const reportsdropdownItems = [
    { path: "/reports/summaryreport", label: "Summary Report" },
    { path: "/reports/overallaverages", label: "Overall Averages" },
    { path: "/reports/averagesbyexaminar", label: "Averages by Examiner" },
  ];
  const admindropdownItems = [
    { path: "/administarion/users", label: "Users" },
    { path: "/administarion/sections", label: "Sections" },
    { path: "/administarion/questions", label: "Questions" },
    { path: "/audit/audittypes", label: "Audit Types" },
    { path: "/audit/auditnames", label: "Audit Names" },
  ];
  const handleToggleAdminDropdown = () => {
    setAdminDropdownOpen(!isAdminDropdownOpen);
  };

  const handleAdminDropdownItemClick = (path) => {
    navigate(path);
    setAdminDropdownOpen(false);
  };
  return (
    <nav className="navigation">
      <a href="/dashboard" className="brand-name">
        <Box
          component="img"
          sx={{
            maxHeight: { xs: 250, md: 200 },
            maxWidth: { xs: 150, md: 250 },
            marginBottom: "20px",
            marginTop: "30px",
          }}
          alt="Web site Logo"
          src={websitelogo}
        />
      </a>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        {/* icon from Heroicons.com */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        {accessid !== "5e8fe1e9fb38c9ce40de0009" ?
          (
            <ul>
              <li>
                <div style={{ position: "relative" }}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleToggleAdminDropdown}
                    onMouseEnter={() => setAdminDropdownOpen(true)}
                    onMouseLeave={() => setAdminDropdownOpen(false)}
                  >
                    Admin
                  </span>
                  {isAdminDropdownOpen && (
                    <ul
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        background: "white",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.20)",
                        borderRadius: "4px",
                        padding: "8px",
                        zIndex: 2,
                        display: "flex",
                        flexDirection: "column"
                      }}
                      onMouseEnter={() => setAdminDropdownOpen(true)}
                      onMouseLeave={() => setAdminDropdownOpen(false)}
                    >
                      {admindropdownItems.map((item) => (
                        <li key={item.path} onClick={() => handleAdminDropdownItemClick(item.path)}>
                          <a href={item.path} style={{ width: "100%", whiteSpace: "nowrap", fontSize: "14px", textTransform: 'capitalize', padding: '0.5rem' }}>
                            {item.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </li>
              {/* <li>
                <a href="/administarion/sections">Sections</a>
              </li>

              <li>
                <a href="/administarion/questions">Questions</a>
              </li>
              <li>
                <a href="/audit/audittypes">Audit Types</a>
              </li> */}
              <li>
                <a href="/administarion/surveys">Survey</a>
              </li>

              <li>
                <div style={{ position: "relative" }}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleToggleReportDropdown}
                    onMouseEnter={() => setDropdownOpen(true)}
                    onMouseLeave={() => setDropdownOpen(false)}
                  >
                    Reports
                  </span>
                  {isDropdownOpen && (
                    <ul
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        background: "white",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.20)",
                        borderRadius: "4px",
                        padding: "8px",
                        zIndex: 1,
                        display: "flex",
                        flexDirection: "column"
                      }}
                      onMouseEnter={() => setDropdownOpen(true)}
                      onMouseLeave={() => setDropdownOpen(false)}
                    >
                      {reportsdropdownItems.map((item) => (
                        <li key={item.path} onClick={() => handleReportDropdownItemClick(item.path)}>
                          <a href={item.path} style={{ width: "100%", whiteSpace: "nowrap", fontSize: "14px", textTransform: 'capitalize', padding: '0.5rem' }}>
                            {item.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </li>

              {/* <li>
                <a href="/administarion/users">Users</a>
              </li> */}
              <li>
                <a href="" onClick={handleLogout}>Logout</a>
              </li>
            </ul>

          ) :
          (
            <ul>
              <li>
                <a href="/administarion/surveys">Survey</a>
              </li>
              <li>
                <a href="" onClick={handleLogout}>Logout</a>
              </li>
            </ul>
          )}
      </div>
    </nav>
  );
}
