import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { Button, FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import logoimg from "../assets/Logo/interwovenicon63.png";
import "./custom.css";
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from '../store/loginSlice';
import Message from '../commonui/snackbar';

function Login(props) {
  const dispatch = useDispatch();
  const loginuser = useSelector(({ loginuser }) => loginuser.loginData);
  const loginsuccess = useSelector(({ loginuser }) => loginuser.loginsuccess);
  const navigate = useNavigate();
  const [user, setUser] = useState({
    username: "",
    password: "",
  })
  useEffect(() => {

    if (loginsuccess) {
      navigate("/dashboard")
    }
  }, [loginsuccess]);
  const [errorShow, setError] = useState();
  function updateForm(value) {
    return setUser((prev) => {
      return { ...prev, ...value };
    });
  }
  async function onSubmit(e) {

    e.preventDefault();
    // When a post request is sent to the create url, we'll add a new record to the database.

    if (validate()) {

      dispatch(userLogin(user));

      const d = loginuser;
      if (loginsuccess) {
        navigate("/dashboard")
      }
    }
  }
  function validate() {

    if (user.username === "" || user.password === "") {
      setError(true);
      return false;
    }
    setError(false);
    return true;
  }
  // useEffect(() => {
  //   if (loginsuccess) {
  //      
  //     navigate("/dashboard");
  //   }
  // }, [loginsuccess]);
  return (


    <div className="">

      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 2,
          py: 0,
          marginTop: '12%',
          marginLeft: '32%',
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: '90%',
          width: '36%'
        }}
      >
        <Avatar
          alt="logo"
          src={logoimg}
          sx={{ width: 120, height: 120, mt: 1, py: -1 }}
        >
        </Avatar>
        <Typography component="h1" variant="h4">
          Audit Tool
        </Typography>
        <Box component="form" noValidate="True" sx={{ mt: 1 }}>
          <TextField className="txtemail"
            margin="normal"
            required
            value={user.username}
            id="email"
            label="User Name"
            name="email"
            error={errorShow}
            autoComplete="email"
            autoFocus
            size="small"
            onChange={(e) => updateForm({ username: e.target.value })}
          /><br />
          <TextField className="txtpass"
            margin="normal"
            required
            value={user.password}
            name="password"
            label="Password"
            type="password"
            id="password"
            error={errorShow}
            autoComplete="current-password"
            size="small"
            onChange={(e) => updateForm({ password: e.target.value })}
            inputProps={{
              minLength: 8, // Set the minimum length to 8 characters
              maxLength: 10, // Set the maximum length to 10 characters
            }}
          />
          <Grid container justifyContent={'center'}>
            <Grid item sx={{ mb: 1 }}>
              <Button
                type="Button" className=""
                variant="contained"
                sx={{
                  marginTop: 2,
                  marginLeft: 1,
                  backgroundColor: '#213555 !important',
                  fontColor: 'black',
                  borderRadius: '8px'
                }}
                onClick={onSubmit}
              >
                Login
              </Button>
            </Grid>
            <Grid item sx={{ mb: 1 }}>
              <Button className=""
                type="Button"
                variant="contained"
                sx={{
                  marginTop: 2,
                  marginLeft: 6,
                  backgroundColor: ' #213555!important',
                  fontColor: 'black',
                  borderRadius: '8px'
                }}
                onClick={() => navigate("/forgotpassword")}
              >
                Forgot Password
              </Button>
            </Grid>
          </Grid><br />
        </Box>
      </Box>
      <Message></Message>
    </div>
  )
}
export default Login