import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from "../store/messageSlice";
// import history from "@history";

export const userLogin = (user) => async dispatch => {

    await axios.post(process.env.REACT_APP_URL.concat("login"), user, { withCredentials: true })
        .then(async res => {
            let isActive = false;
            let x = await res.data
            if (x.status === true) {
                isActive = x.user.Active;
                if (isActive) {
                    localStorage.setItem('session.id', res.data.session);
                    localStorage.setItem('UserId', x.user._id);
                    localStorage.setItem('AccessId', x.user.roleId);
                    return dispatch(userloginSucess(x.user));
                }
                else {
                    dispatch(showMessage({
                        message: "Your Acocunt is not active.Contact admin to activate your account.", autoHideDuration: 4000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        severity: 'warning'
                    }));
                }
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            return dispatch(userloginError([]));
        });

};
export const forgotPassword = (username) => async dispatch => {

    await axios.post(process.env.REACT_APP_URL.concat("forgotpassword"), { username }, { withCredentials: true })
        .then(async res => {

            let x = await res.data
            if (x.status === true) {

                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'success'
                }));
                return dispatch(setforgotPasswordSuccess(true));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            return dispatch(forgotPasswordError());
        });
};
export const resettPassword = (user) => async dispatch => {

    await axios.post(process.env.REACT_APP_URL.concat("resetpassword"), user, { withCredentials: true })
        .then(async res => {

            let x = await res.data
            if (x.status === true) {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'success'
                }));
                return dispatch(setResetPasswordSuccess(true));
            }
            else {
                dispatch(showMessage({
                    message: "Error:" + x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            return dispatch(setResetPasswordSuccess(false));
        });
};
export const userLogout = () => async dispatch => {

    // localStorage.removeItem('session.id');
    // localStorage.removeItem('UserId');
    localStorage.clear();
    await axios.get(process.env.REACT_APP_URL.concat("logout"), { withCredentials: true })
        .then(async res => {

            let x = await res.data
            if (x.status === "OK") {
                return dispatch(userlogoutSucess([]));
            }
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            return dispatch(userlogoutSucess([]));
        });

};
const initialState = {
    loginsuccess: false,
    loginData: [],
    logoutsucess: false,
    forgotpasswordsuccess: false,
    resetPasswordsuccess: false,
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        userloginSucess: (state, action) => {
            state.loginsuccess = true;
            state.loginData = action.payload;
        },
        userloginError: (state, action) => {
            state.loginsuccess = false;
            state.loginData = [];
        },
        userlogoutSucess: (state, action) => {
            state.logoutsucess = true;
            state.loginData = action.payload;
        },
        setforgotPasswordSuccess: (state, action) => {
            state.forgotpasswordsuccess = true;
        },
        forgotPasswordError: (state, action) => {
            state.setforgotPasswordSuccess = false;
        },
        setResetPasswordSuccess: (state, action) => {
            state.resetPasswordsuccess = action.payload;
        }
    }
})
export const {
    userloginSucess,
    userlogoutSucess,
    userloginError,
    setforgotPasswordSuccess,
    forgotPasswordError,
    setResetPasswordSuccess,
    loginsuccess
} = loginSlice.actions;

export default loginSlice.reducer;