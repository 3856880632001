import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from "./messageSlice";

export const getSurveyList = (pageindex, pagesize) => async dispatch => {
    dispatch(setLoading(true));
    await axios.get(process.env.REACT_APP_URL.concat(`getSurveys/${pageindex}/${pagesize}`), { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data.data;
            if (res.data.status === true) {
                dispatch(setTotalSurveyCount(x.TotalCount));
                dispatch(setLoading(false));
                return dispatch(surveyListSuccess(x.SurveyList));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
            dispatch(setLoading(false));
            return dispatch(surveyListError([]));
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            dispatch(setLoading(false));
            return dispatch(surveyListError([]));
        });

};

export const getAuditTypesList = () => async dispatch => {

    await axios.get(process.env.REACT_APP_URL.concat("getAuditTypesList"), { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data;
            if (x.status === true) {
                return dispatch(auditTypeSuccess(x.audittypeslist));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            return dispatch(auditTypeListError([]));
        });

};

export const getAuditNamesList = () => async dispatch => {
    dispatch(setLoading(true));
    await axios.get(process.env.REACT_APP_URL.concat("getAuditNameList"), { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data;
            if (x.status === true) {
                dispatch(setLoading(false));
                return dispatch(auditNameListSuccess(x.auditnamelist));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
                dispatch(setLoading(false));
            }
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            dispatch(setLoading(false));
            return dispatch(auditNameListError([]));
        });

};
export const getReviewerList = () => async dispatch => {
    //  dispatch(setLoading(true));
    await axios.get(process.env.REACT_APP_URL.concat("getReviewerList"), { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {

            let x = await res.data;
            if (x.status === true) {
                //  dispatch(setLoading(false));
                return dispatch(reviewersListSuccess(x.reviewersList));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
                //   dispatch(setLoading(false));
            }
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            //  dispatch(setLoading(false));
            return dispatch(reviewersListSuccess([]));
        });

};
export const saveSurvey = (data) => async dispatch => {
    dispatch(setLoading(true));
    var URL = "";
    //console.log("in save ques");
    //console.log(data);
    if (data._id === "0" || data._id === "") {
        URL = process.env.REACT_APP_URL.concat('surveycreate');
    }
    else {
        URL = process.env.REACT_APP_URL.concat("surveyedit");
    }
    await axios.post(URL, data, { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(res => {
            let x = res.data


            if (x.status === true) {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'success'
                }));
                dispatch(setLoading(false));
                return dispatch(surveyDataSuccess(x.savedsurvey));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
                dispatch(setLoading(false));
            }
        }
        ).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            dispatch(setLoading(false));
            return dispatch(surveyDataSuccess([]));
        });;
};
export const searchSurvey = (data) => async dispatch => {
    dispatch(setLoading(true));
    await axios.post(process.env.REACT_APP_URL.concat('searchSurvey'), data, { withCredentials: true, headers: { Pragma: 'no-cache' } })
        .then(async res => {
            let x = await res.data.data;
            if (res.data.status === true) {
                dispatch(setTotalSurveyCount(x.TotalCount));
                dispatch(setLoading(false));
                return dispatch(surveyListSuccess(x.SurveyList));
            }
            else {
                dispatch(showMessage({
                    message: res.data.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
            }
            dispatch(setLoading(false));
            return dispatch(surveyListError([]));
        }).catch(error => {
            dispatch(showMessage({
                message: error.message, autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                severity: 'warning'
            }));
            dispatch(setLoading(false));
            return dispatch(surveyListError([]));
        });
};

export const deleteSurvey = (id) => async dispatch => {
    dispatch(setLoading(true));
    await axios.get(process.env.REACT_APP_URL.concat(`surveydelete/${id}`), { withCredentials: true })
        .then(res => {
            let x = res.data

            if (x.status === true) {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'success'
                }));
                dispatch(setLoading(false));
                return dispatch(surveyDataSuccess(x.savedsurvey));
            }
            else {
                dispatch(showMessage({
                    message: x.message, autoHideDuration: 4000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    severity: 'warning'
                }));
                dispatch(setLoading(false));
            }
        }
        );
};

const initialState = {
    surveysuccess: false,
    audittypesuccess: false,
    //questionSection: [],
    surveysData: [],
    auditTypeData: [],
    survey: [],
    auditnamelist: [],
    isloading: false,
    reviewers: [],
};

const surveySlice = createSlice({
    name: 'surveys',
    initialState,
    reducers: {
        surveyListSuccess: (state, action) => {
            state.surveysuccess = true;

            state.surveysData = action.payload;
        },
        surveyListError: (state, action) => {
            state.surveysuccess = false;
            state.surveysData = [];
        },
        auditTypeSuccess: (state, action) => {
            state.audittypesuccess = true;
            state.auditTypeData = action.payload;
        },
        auditTypeListError: (state, action) => {
            state.audittypesuccess = false;
            state.auditTypeData = [];
        },
        surveyDataSuccess: (state, action) => {
            state.surveysuccess = true;
            state.survey = action.payload;
        },
        setTotalSurveyCount: (state, action) => {
            state.surveysuccess = true;
            state.TotalCount = action.payload;
        },
        auditNameListSuccess: (state, action) => {
            state.auditnamelist = action.payload;
        },
        reviewersListSuccess: (state, action) => {
            state.reviewers = action.payload;
        },
        auditNameListError: (state, action) => {
            state.auditnamelist = [];
        },
        setLoading: (state, action) => {
            state.isloading = action.payload;
        },
    }
})
export const {
    surveyListSuccess,
    surveyListError,
    auditTypeSuccess,
    auditTypeListError,
    surveyDataSuccess,
    setTotalSurveyCount,
    auditNameListSuccess,
    auditNameListError,
    setLoading,
    reviewersListSuccess
} = surveySlice.actions;


export default surveySlice.reducer;