import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Select, MenuItem, FormControl, Tab, Tooltip, Fab } from '@mui/material';
import { Grid, Paper, InputLabel, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionList, deleteQuestion, getSections, searchQuestions } from '../../store/questionSlice';

import StorageIcon from '@mui/icons-material/Storage';
import ConfirmationDialog from '../../commonui/confirmation';
import TextTruncate from 'react-text-truncate';
import Navbar from "../../Navigation/Navbar";
import Message from '../../commonui/snackbar';
import CircularProgressLoader from '../../commonui/circularProgressLoader';
function Questions() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const questionlist = useSelector(({ questions }) => questions.questionsData);
    const TotalCount = useSelector(({ questions }) => questions.TotalCount);
    const [open, setOpen] = useState(false);
    const [Questionid, setQuestionId] = useState(0);
    //const [deleteConfirmed, setDelete] = useState(false);
    const sectionlist = useSelector(({ questions }) => questions.questionSection);
    const isloadingvalue = useSelector(({ questions }) => questions.isloading);
    const [loading, setLoading] = useState();
    const [searchData, setSearch] = useState({
        searchQuestion: "",
        searchsectionId: "All",
        rowsPerPage: 25,
        page: 0,
    })

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    useEffect(() => {
        dispatch(getQuestionList(page * rowsPerPage, rowsPerPage));

        dispatch(getSections());
    }, []);

    useEffect(() => {
        dispatch(getQuestionList(page * rowsPerPage, rowsPerPage));
    }, [page, rowsPerPage]);

    useEffect(() => {
        setLoading(isloadingvalue)
    }, [isloadingvalue]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
    };

    function updateForm(value) {

        return setSearch((prev) => {
            return { ...prev, ...value };
        });
    }

    async function searchClick() {
        const searchQuestion = searchData.searchQuestion;
        const searchSection = searchData.searchsectionId;
        //setSearchSection(id);       
        setSearch({
            searchQuestion: searchQuestion,
            searchsectionId: searchSection,
            page: page * rowsPerPage,
            rowsPerPage: rowsPerPage
        });
        // 

        dispatch(searchQuestions(searchData));
    }

    async function searchClear() {
        //const searchQuestion = searchData.searchQuestion;
        //const searchSection = searchData.searchsectionId;
        setSearch({
            searchQuestion: "",
            searchsectionId: "All",
            page: 0,
            rowsPerPage: 25
        });

        setRowsPerPage(25);
        setPage(0);
        dispatch(getQuestionList(page * rowsPerPage, rowsPerPage));
    }

    async function editQuestion(id) {

        navigate(`/administarion/questions/addquestion/${id}/${0}`)
    }

    async function deleteQues(id) {

        setOpen(true);
        setQuestionId(id);
        //   dispatch(getQuestionList(page * rowsPerPage, rowsPerPage));
        // setOpen(false);
    }
    const handleClose = (newValue) => {
        setOpen(false);
        if (newValue) {
            dispatch(deleteQuestion(Questionid));

        }
    };
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    return (
        <div>
            <Navbar /><Message />    {loading && < CircularProgressLoader loading={loading} />}
            <Box
                sx={{
                    marginTop: 1,
                    marginLeft: '5%',
                    width: '90vw',
                    height: '100%',
                    backgroundColor: 'whitesmoke',
                    borderStyle: 'solid',
                    borderColor: '#213555',
                    borderWidth: 0.5
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: 40,
                        backgroundColor: '#609EA2',
                        borderStyle: 'solid',
                        borderColor: '#213555',
                        borderWidth: 0.5,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Grid container direction="row" sx={{ mt: 1 }}>
                        <Grid item>
                            <StorageIcon sx={{
                                fill: 'white',
                                width: '2.5em',
                                height: '1em',
                            }} fontSize='small' />
                        </Grid>
                        <Grid item xs>
                            <InputLabel id="Add"
                                sx={{ color: 'white' }}
                            >Questions Management</InputLabel>
                        </Grid>

                        <Grid item xs lg={1.5}>
                            <Button variant="contained"
                                onClick={() => navigate(`/administarion/questions/addquestion/${0}/${0}`)}
                                sx={{
                                    backgroundColor: ' #213555 !important',
                                    fontColor: 'black',
                                    textTransform: 'none',
                                    lineHeight: 1,
                                    '@media (max-width: 600px)': { // Media query for small screens
                                        maxWidth: '1rem',
                                        fontSize: '0.1rem', // Adjust the font size for small screens
                                    },
                                    display: {
                                        xs: 'none', // Show on small screens
                                        sm: 'block', // Hide on medium screens and larger
                                    },
                                }}>Add Question</Button>
                        </Grid>
                        <Grid item
                            sx={{
                                display: {
                                    xs: 'block', // Show on small screens
                                    sm: 'none', // Hide on medium screens and larger
                                }
                            }}>
                            <Tooltip
                                title="Add Question"
                                sx={{
                                    position: 'absolute',
                                    top: '30%',
                                    right: '25px',
                                    transform: 'translateY(-50%)',
                                    display: {
                                        xs: 'block', // Show on small screens
                                        sm: 'none', // Hide on medium screens and larger
                                    },
                                }}
                            >
                                <Fab color="primary" aria-label="add" size="small"
                                    sx={{
                                        display: 'block', // Display on small screens
                                        margin: '0 auto', // Center horizontally
                                        marginTop: '0rem', // Add spacing from above
                                        mt: -1,
                                    }}
                                    onClick={() => navigate(`/administarion/questions/addquestion/${0}/${0}`)}>
                                    <AddIcon sx={{ mt: 1 }} />
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        marginTop: 1,
                        marginLeft: 1,
                        width: '80%',
                    }}
                >
                    <Grid container direction="row" >
                        <FormControl
                            fullWidth
                            size="small"
                            sx={{
                                mt: 2,
                                mr: 2,
                                width: 300
                            }}
                        >
                            <InputLabel id="Section">Section</InputLabel>
                            <Select
                                labelWidth={150}
                                labelId="Select Section"
                                id="sectionId"
                                label="Select"
                                name="sectionId"
                                value={searchData.searchsectionId}
                                onChange={(e) => updateForm({ searchsectionId: e.target.value })}
                                MenuProps={MenuProps}
                            >
                                <MenuItem value="All" key="All">All</MenuItem>
                                {sectionlist.map((row) => (
                                    <MenuItem value={row._id} key={row._id} >{row.section}</MenuItem>
                                ))
                                }
                            </Select>

                        </FormControl>

                        <TextField
                            label="Question"
                            placeholder='Search Question'
                            variant="outlined"
                            color="primary"
                            type="text"
                            name="searchQuestion"
                            size="small"
                            sx={{ width: 300, mt: 2, mr: 2 }}
                            value={searchData.searchQuestion}
                            onChange={(e) => updateForm({ searchQuestion: e.target.value })}
                        />

                        <Grid item>
                            <Button variant="contained"
                                sx={{
                                    mt: 2, mr: 2,
                                    backgroundColor: ' #213555 !important',
                                    fontColor: 'black',
                                    textTransform: 'none',
                                    lineHeight: 1.8,
                                }}
                                onClick={() => searchClick()}
                            >Search</Button>

                            <Button variant="contained"
                                sx={{
                                    mt: 2,
                                    mr: 2,
                                    backgroundColor: 'Gray !important',
                                    fontColor: 'black',
                                    textTransform: 'none',
                                    lineHeight: 1.8
                                }}
                                onClick={() => searchClear()}
                            >Clear</Button>
                        </Grid>
                    </Grid>
                </Box>

                {/*questions table*/}
                <Box
                    sx={{
                        marginTop: 2,
                        marginLeft: 1,
                        marginRight: 1,
                        height: '65%',
                        backgroundColor: 'white',
                    }}
                >
                    <TableContainer component={Paper}>
                        <Table sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold' }} size='small' aria-label="sticky table">
                            <TableHead sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold', fontSize: '0.975rem !important', backgroundColor: '#898B8A', color: 'white' }}>
                                <TableRow sx={{ height: '5% !important' }}>
                                    <TableCell sx={{ color: 'white', width: '5%', whiteSpace: 'nowrap' }}>Section</TableCell>
                                    <TableCell sx={{ color: 'white', width: '75%' }}>Question</TableCell>
                                    <TableCell sx={{ color: 'white', width: '10%' }}>Points</TableCell>
                                    <TableCell sx={{ color: 'white', width: '10%' }}>Active Status</TableCell>
                                    <TableCell sx={{ color: 'white', width: '10%', }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {questionlist.map((row) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{row.sectionId.section ? row.sectionId.section : ""}</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                                            <TextTruncate
                                                line={1}
                                                element="span"
                                                truncateText="…"
                                                text={row.question}
                                            />
                                        </TableCell>
                                        <TableCell>{row.points}</TableCell>
                                        <TableCell>{row.Active ? "Active" : "InActive"}</TableCell>
                                        <TableCell colSpan={2} sx={{ whiteSpace: "nowrap" }}>
                                            <IconButton aria-label="edit" onClick={() => editQuestion(row._id)} size="small">
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton aria-label="delete" onClick={() => deleteQues(row._id)} size="small">
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={TotalCount ? TotalCount : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Box>
            <ConfirmationDialog
                id="ringtone-menu"
                keepMounted
                open={open}
                text={("Are you sure you want to delete this question?")}
                onClose={handleClose}
                value={Questionid}
            >
            </ConfirmationDialog>
        </div >
    );
}

export default Questions