import React, { useEffect, useState } from 'react';
import Dashboard from "../../Dashboard"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, Paper, TextField, Select, MenuItem, InputLabel, FormControl, FormControlLabel, Tooltip, Fab } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { getAuditTypeList, deleteType, search } from '../../store/auditTypeSlice1';
import TablePagination from '@mui/material/TablePagination';
import StorageIcon from '@mui/icons-material/Storage';
import ConfirmationDialog from '../../commonui/confirmation'
import Navbar from '../../Navigation/Navbar';
import Message from '../../commonui/snackbar';
import CircularProgressLoader from '../../commonui/circularProgressLoader';
function AuditType() {
    const dispatch = useDispatch();
    const TotalCount = useSelector(({ audittypewithSections }) => audittypewithSections.TotalCount);
    const auditTypeList = useSelector(({ audittypewithSections }) => audittypewithSections.audittypeData);
    const isloadingvalue = useSelector(({ audittypewithSections }) => audittypewithSections.isloading);
    const [loading, setLoading] = useState();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [deleteid, setId] = useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [searchData, setSearch] = useState({
        searchName: "",
        searchIsactive: "All",
        rowsPerPage: 25,
        page: 0,
    })
    useEffect(() => {
        dispatch(getAuditTypeList(page * rowsPerPage, rowsPerPage,));

    }, []);
    useEffect(() => {
        dispatch(getAuditTypeList(page * rowsPerPage, rowsPerPage,));
    }, [page, rowsPerPage]);


    useEffect(() => {
        setLoading(isloadingvalue)
    }, [isloadingvalue]);

    async function deleteData(id) {

        setOpen(true);
        setId(id);

    }
    async function edit(id) {

        navigate(`/audit/audittypes/addaudittype/${id}`);
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
    };
    function updateForm(value) {

        return setSearch((prev) => {
            return { ...prev, ...value };
        });
    }
    async function searchClick() {

        const searchName = searchData.searchName;
        const active = searchData.searchIsactive;
        setSearch({
            searchName: searchName,
            searchIsactive: active,
            page: page * rowsPerPage,
            rowsPerPage: rowsPerPage
        });

        dispatch(search(searchData));
    }
    async function searchClear() {
        setSearch({
            searchName: "",
            searchIsactive: "All",
            page: 0,
            rowsPerPage: 25
        });
        setRowsPerPage(25);
        setPage(0);
        dispatch(getAuditTypeList(page * rowsPerPage, rowsPerPage));
    }

    const handleClose = (newValue) => {
        setOpen(false);
        if (newValue) {
            dispatch(deleteType(deleteid));
        }
    };
    function getSectionNames(sectionQuestionList) {
        // Extract the section names from the array
        const sectionNames = sectionQuestionList.map(item => item.sectionname);

        // Join the section names with a comma separator
        const sectionNamesJoined = sectionNames.join(', ');

        return sectionNamesJoined;
    }


    // Avoid a layout jump when reaching the last page with empty rows.
    return (

        <div>
            <Navbar /><Message />{loading && < CircularProgressLoader loading={loading} />}

            <Box
                sx={{
                    marginTop: 1,
                    marginLeft: '5%',
                    width: '90vw',
                    height: '100%',
                    backgroundColor: 'whitesmoke',
                    borderStyle: 'solid',
                    borderColor: '#213555',
                    borderWidth: 0.5
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: 40,
                        backgroundColor: '#609EA2',
                        borderStyle: 'solid',
                        borderColor: '#213555',
                        borderWidth: 0.5,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Grid container direction="row" sx={{ mt: 1 }}>
                        <Grid item>
                            <StorageIcon sx={{
                                fill: 'white',
                                width: '2.5em',
                                height: '1em',
                            }} fontSize='small' />
                        </Grid>
                        <Grid item xs>
                            <InputLabel id="Create Type"
                                sx={{
                                    color: 'white',
                                }}
                            >Audit Type Management</InputLabel>
                        </Grid>
                        <Grid item xs lg={1.5}>
                            <Button variant="contained"
                                onClick={() => navigate(`/audit/audittypes/addaudittype/${0}`)}
                                sx={{
                                    backgroundColor: ' #213555 !important',
                                    fontColor: 'black',
                                    textTransform: 'none',
                                    lineHeight: 1,
                                    '@media (max-width: 600px)': { // Media query for small screens
                                        maxWidth: '1rem',
                                        fontSize: '0.1rem', // Adjust the font size for small screens
                                    },
                                    display: {
                                        xs: 'none', // Show on small screens
                                        sm: 'block', // Hide on medium screens and larger
                                    },
                                }}>Add Audit Type</Button>
                        </Grid>
                        <Grid item
                            sx={{
                                display: {
                                    xs: 'block', // Show on small screens
                                    sm: 'none', // Hide on medium screens and larger
                                }
                            }}>
                            <Tooltip
                                title="Add Question"
                                sx={{
                                    position: 'absolute',
                                    top: '30%',
                                    right: '25px',
                                    transform: 'translateY(-50%)',
                                    display: {
                                        xs: 'block', // Show on small screens
                                        sm: 'none', // Hide on medium screens and larger
                                    },
                                }}
                            >
                                <Fab color="primary" aria-label="add" size="small"
                                    sx={{
                                        display: 'block', // Display on small screens
                                        margin: '0 auto', // Center horizontally
                                        marginTop: '0rem', // Add spacing from above
                                        mt: -1,
                                    }}
                                    onClick={() => navigate(`/audit/audittypes/addaudittype/${0}`)}>
                                    <AddIcon sx={{ mt: 1 }} />
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        marginTop: 1,
                        marginLeft: 1,
                        width: '80%',
                    }}
                >
                    <Grid container direction="row">
                        {/* <InputLabel id="searchName"
                            sx={{
                                ml: 2,
                                mt: 3,
                                color: 'Black',
                                fontFamily: 'Arial',
                                fontSize: '1rem'
                            }}
                        >Audit Type:</InputLabel> */}

                        <TextField
                            label="Audit Type"
                            placeholder='Audit Type'
                            variant="outlined"
                            color="primary"
                            type="text"
                            name="searchName"
                            size="small"
                            sx={{
                                ml: 2,
                                mt: 2,
                                width: 250,
                            }}
                            value={searchData.searchName}
                            onChange={(e) => updateForm({ searchName: e.target.value })}
                        />
                        {/* 
                        <InputLabel id="searchisactive"
                            sx={{
                                ml: 2,
                                mt: 3,
                                color: 'Black',
                                fontFamily: 'Arial',
                                fontSize: '1rem'
                            }}
                        >Active:</InputLabel> */}

                        <FormControl
                            mediumwidth
                            size="small"
                            sx={{
                                mt: 2, mr: 1, ml: 2,
                                width: 250,
                                height: 50
                            }}>
                            <InputLabel id="IsActive">Active</InputLabel>
                            <Select
                                labelId="Active"
                                id="active"
                                label="Select"
                                name="isActive"
                                value={searchData.searchIsactive}
                                onChange={(e) => updateForm({ searchIsactive: e.target.value })}
                            >
                                <MenuItem value="All" key="All">All</MenuItem>
                                <MenuItem value="True" key="True">Open</MenuItem>
                                <MenuItem value="False" key="False" >Closed</MenuItem>
                            </Select>
                        </FormControl>
                        <Grid item>
                            <Button variant="contained"
                                sx={{
                                    ml: 1,
                                    mt: 2,
                                    backgroundColor: ' #213555 !important',
                                    fontColor: 'black',
                                    textTransform: 'none',
                                    lineHeight: 1.8
                                }}
                                onClick={() => searchClick()}
                            >Search</Button>
                        </Grid>&nbsp;&nbsp;
                        <Grid item>
                            <Button variant="contained"
                                sx={{
                                    ml: 2,
                                    mt: 2,
                                    backgroundColor: 'Gray !important',
                                    fontColor: 'black',
                                    textTransform: 'none',
                                    lineHeight: 1.8
                                }}
                                onClick={() => searchClear()}
                            >Clear</Button>
                        </Grid>
                    </Grid>

                </Box>
                <Box
                    sx={{
                        marginTop: 2,
                        marginLeft: 1,
                        width: '98%',
                        height: '100%',
                        backgroundColor: 'white',
                    }}
                >

                    <TableContainer component={Paper}>
                        <Table sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold' }} size='small' aria-label="a dense table">
                            <TableHead sx={{ textAlign: 'center', fontFamily: 'Arial', fontStyle: 'bold', fontSize: '0.975rem !important', backgroundColor: '#898B8A', color: 'white' }}>
                                <TableRow>
                                    <TableCell sx={{ color: 'white' }}>Audit Type</TableCell>
                                    <TableCell sx={{ color: 'white' }}>Description</TableCell>
                                    <TableCell sx={{ color: 'white' }}>Sections</TableCell>
                                    <TableCell sx={{ color: 'white', whiteSpace: 'nowrap' }}>Active Status</TableCell>
                                    <TableCell sx={{ color: 'white', whiteSpace: 'nowrap' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {auditTypeList.map((row) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0, height: 1 } }}
                                    >
                                        <TableCell >{row.name}</TableCell>
                                        <TableCell >{row.description}</TableCell>
                                        <TableCell >{getSectionNames(row.SectionQuestionList)}</TableCell>
                                        <TableCell >{row.Active ? 'Open' : 'Closed'}</TableCell>
                                        <TableCell colSpan={2} sx={{ whiteSpace: 'nowrap' }}>
                                            <IconButton aria-label="delete" onClick={() => edit(row._id)} size="small">
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton aria-label="delete" onClick={() => deleteData(row._id)} size="small">
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={TotalCount ? TotalCount : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Box>
            <ConfirmationDialog
                id="ringtone-menu"
                keepMounted
                open={open}
                text={("Are you want to close this Audit?")}
                onClose={handleClose}
                value={deleteid}
            >
            </ConfirmationDialog>  <Message></Message>
        </div >

    )
}
export default AuditType