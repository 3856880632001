import React, { useEffect } from "react"
import Mainnavbar from "../Navigation/Mainnavbar"
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Message from '../commonui/snackbar';
import Navbar from "../Navigation/Navbar";
import MainScreen from "../Navigation/index";

function Dashboard() {
  const navigate = useNavigate();
  useEffect(() => {
    const sessionid = localStorage.getItem('session.id')
    if (!sessionid) {
      navigate("/");
    }
  }, []);
  return (

    <div>
      {/*<Mainnavbar />*/}
      <Navbar />
      <Message />
      <MainScreen></MainScreen>
      {/* Hello {User.name} */}
    </div>

  )
}
export default Dashboard