import React, { useEffect, useState } from 'react';
import { Select, MenuItem, InputLabel, FormControl, FormControlLabel, Checkbox, Collapse } from '@mui/material';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom"
import { useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { saveData, GetSections, GetSectionwiseQtnList, getAuditTypeList, DeleteAuditTypeQuestion, GetAuditType, auditTypyClear } from '../../store/auditTypeSlice1';
import Message from '../../commonui/snackbar';
import Navbar from "../../Navigation/Navbar";
function AddAuditType() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams()
    const [id, setid] = useState(params.id);
    const [loading, setLoading] = useState(false);
    // 
    const savedaudittype = useSelector(({ audittypewithSections }) => audittypewithSections.audittype);
    const sectionlist = useSelector(({ audittypewithSections }) => audittypewithSections.sectionList);
    const audittypesuccess1 = useSelector(({ audittypewithSections }) => audittypewithSections.audittypesuccess1);
    const [audittype, setAudittype] = useState({
        _id: "0",
        name: "",
        description: "",
        sections: [],
        sectionsquestions: [],
        heading: "Add Audit Type",
    })
    const [sections, setsections] = useState(sectionlist);
    const [isdisabled, setDisabled] = useState(false);
    const [sectionChanged, setSectionChanged] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [sectionBlank, setSectionBlank] = useState(false);
    const requiredFields = ["name", "sections"];

    const addeditdata = () => {

        if (id !== "0" && id !== "undefined") {
            dispatch(GetAuditType(id));
            setAudittype({
                _id: savedaudittype._id,
                name: savedaudittype.name,
                description: savedaudittype.description,
                sections: savedaudittype.Sections,
                sectionsquestions: savedaudittype.SectionQuestionList,
                heading: "Edit Audit Type",
            });
            setsections(Array.isArray(savedaudittype.Sections) ? savedaudittype.Sections : []);
            setDisabled(true);
        }
        else {
            setAudittype({
                _id: "0",
                name: "",
                description: "",
                sections: [],
                sectionsquestions: [],
                heading: "Add Audit Type",
            });
            setsections([]);
            dispatch(GetSections());
        }
    };
    useEffect(() => {

        dispatch(GetSections());
        setsections(sectionlist);
    }, []);

    useEffect(() => {

        addeditdata()
    }, [savedaudittype._id]);

    function updateForm(value) {

        return setAudittype((prev) => {
            return { ...prev, ...value };
        });
    }
    const handleSectionChange = (event) => {
        setSectionChanged(true);
        const {
            target: { value },
        } = event;
        setsections(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    async function chooseQtnClick() {
        setLoading(true);
        audittype.sections = sections;
        const errors = validateRequiredFields(audittype, requiredFields);
        const isSectionsEmpty = sections.length === 0;
        if (isSectionsEmpty) {
            setSectionBlank(true)
        }
        setFormErrors(errors);
        if (Object.keys(errors).length === 0 && !isSectionsEmpty) {
            setFormErrors({});
            audittype.active = true;
            audittype.sections = sections;
            if (id === "0") {
                dispatch(saveData(audittype, false));
            } else {
                if (savedaudittype.name !== audittype.name || savedaudittype.description !== audittype.description || sectionChanged && id !== "0") {
                    let newType = ({
                        _id: savedaudittype._id,
                        name: audittype.name,
                        description: audittype.description,
                        Sections: sections,
                        SectionQuestionList: savedaudittype.SectionQuestionList
                    });
                    dispatch(saveData(newType, sectionChanged));
                }
                setAudittype(savedaudittype);
                setLoading(false);
            }
            navigate(`/audit/audittypes/addaudittype/auditTypeSectionQuestion/${audittype._id}`);
        }

    }
    const validateRequiredFields = (values, requiredFields) => {
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!values[field] || values[field] === '') {
                newErrors[field] = 'This field is required';
            }
        });

        return newErrors;
    };
    async function cancelHandle() {
        setid("0");
        dispatch(auditTypyClear());
        navigate("/audit/audittypes");
    }
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (

        <div>
            <Navbar />
            <Box
                sx={{
                    marginTop: 1,
                    marginLeft: '15%',
                    width: '70vw',
                    height: '100%',
                    backgroundColor: 'white',
                    borderStyle: 'solid',
                    borderColor: '#213555',
                    borderWidth: 0.5,
                }}

            >
                <Box
                    sx={{
                        mt: 0,
                        width: '100%',
                        height: 40,
                        backgroundColor: '#22A699',
                        borderStyle: '',
                    }}
                >
                    <Grid container>
                        <Grid item>
                        </Grid>
                        <Grid item><InputLabel id="addaudittype"
                            sx={{
                                ml: 1,
                                mt: 1,
                                color: 'white',
                            }}
                        >{audittype.heading}</InputLabel>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        marginTop: 0,
                        width: '98.5%',
                        height: '100%',
                        borderBlockColor: 'black',
                        backgroundColor: 'white',
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <form autoComplete="off" noValidate="true">
                        <Grid container sx={{ mt: 2, ml: 6 }}>
                            <Grid item>
                                <TextField
                                    size="large"
                                    label="Type"
                                    placeholder='Enter Type'
                                    required
                                    variant="outlined"
                                    color="primary"
                                    type="text"
                                    name="name"
                                    value={audittype.name}
                                    onChange={(e) => updateForm({ name: e.target.value })}
                                    sx={{
                                        mb: 3, mr: 5, ml: 3,
                                        width: '28vw',
                                        height: 50
                                    }}
                                    error={!!formErrors.name}
                                />
                            </Grid>
                            <Grid item>
                                <FormControl
                                    required
                                    error={sectionBlank}
                                    size="small"
                                    // disabled={isdisabled}
                                    sx={{
                                        mb: 3, mr: 6, ml: 3,
                                        width: '28vw',
                                        height: 30
                                    }}>
                                    <InputLabel id="Section">Select Sections</InputLabel>
                                    <Select
                                        size="medium"
                                        labelId="sectionid"
                                        required
                                        multiple
                                        id="section"
                                        label="Select Sections"
                                        MenuProps={MenuProps}
                                        name="sections"
                                        value={sections}
                                        onChange={handleSectionChange}
                                    >
                                        {sectionlist.map((row) => (
                                            <MenuItem value={row._id} key={row._id}>{row.section}</MenuItem>
                                        ))
                                        }
                                    </Select>
                                    {/* {formErrors.sections && <FormHelperText>{formErrors.sections}</FormHelperText>} */}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 0, ml: 6 }}>
                            <Grid item>
                                <TextField
                                    size="small"
                                    label="Description"
                                    placeholder='Enter Description'
                                    variant="outlined"
                                    color="primary"
                                    multiline
                                    rows={3}
                                    name="description"
                                    value={audittype.description}
                                    onChange={(e) => updateForm({ description: e.target.value })}
                                    sx={{
                                        mt: 2, ml: 3,
                                        width: '60vw',
                                        height: 30
                                    }}
                                />

                            </Grid>
                        </Grid>
                    </form>
                    <Box
                        sx={{
                            mt: 9,
                            width: '100%',
                            height: 50,
                            backgroundColor: '#f5f5f5',
                            borderStyle: '',
                        }}
                    >
                        <Grid container justifyContent={'flex-end'}>
                            <Grid item>
                                <Button variant="contained"
                                    type='submit'
                                    sx={{
                                        mt: 1,
                                        backgroundColor: ' #213555 !important',
                                        textTransform: 'none',
                                    }}
                                    onClick={() => chooseQtnClick()}
                                >Choose Questions</Button>&nbsp;&nbsp;
                                <Button variant="contained"
                                    type='submit'
                                    sx={{
                                        mt: 1,
                                        backgroundColor: ' #CFD2CF !important',
                                        color: "black"
                                    }}
                                    onClick={() => cancelHandle()}
                                >Cancel</Button>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>
            </Box>
            <Message></Message>
        </div >

    )
}
export default AddAuditType