import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import { Button, FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import logoimg from "../assets/Logo/interwovenicon63.png";
import { useDispatch, useSelector } from 'react-redux';
import { resettPassword } from '../store/loginSlice';
import Message from '../commonui/snackbar';
function ResetPassword() {
    const dispatch = useDispatch();

    const success = useSelector(({ loginuser }) => loginuser.resetPasswordsuccess);
    //const loginsuccess = useSelector(({ loginuser }) => loginuser.loginsuccess);
    const params = useParams();
    const email = params.email;

    const navigate = useNavigate();
    const [user, setUser] = useState({
        username: email,
        password: "",
        cpassword: "",
    })
    const [errorShow, setError] = useState();
    function updateForm(value) {
        return setUser((prev) => {
            return { ...prev, ...value };
        });
    }
    async function onSubmit(e) {

        e.preventDefault();
        // When a post request is sent to the create url, we'll add a new record to the database.

        if (validate()) {
            dispatch(resettPassword(user));
            if (success) {
                navigate("/");
            }
        }
    }


    useEffect(() => {

        if (success) {

            navigate("/");
        }
    }, [success]);


    function validate() {

        if (user.password === "" || user.cpassword === "") {
            setError(true);
            return false;
        }
        if (user.password !== user.cpassword) {
            setError(true);
            return false;
        }
        setError(false);
        return true;
    }
    return (
        <div>
            <Message></Message>
            <Box
                sx={{
                    boxShadow: 3,
                    borderRadius: 2,
                    px: 2,
                    py: 0,
                    marginTop: '12%',
                    marginLeft: '35%',
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: '80%',
                    width: '25%',
                }}
            >
                <Avatar
                    alt="logo"
                    src={logoimg}
                    sx={{ width: 120, height: 120, mt: 1, py: -1 }}
                >
                </Avatar>
                <Typography component="h6" variant="h5">
                    Reset Password
                </Typography>
                <Box component="form" noValidate="True" sx={{ mt: 1 }}>
                    <Grid item>
                        <TextField
                            margin="normal"
                            required
                            value={user.password}
                            id="password"
                            label="Password"
                            type="password"
                            name="password"
                            error={errorShow}
                            autoComplete="password"
                            autoFocus
                            size="small"
                            onChange={(e) => updateForm({ password: e.target.value })}
                            inputProps={{
                                minLength: 8, // Set the minimum length to 8 characters
                                maxLength: 10, // Set the maximum length to 10 characters
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            margin="normal"
                            required
                            value={user.cpassword}
                            name="cpassword"
                            label="Confirme Password"
                            type="password"
                            id="cpassword"
                            error={errorShow}
                            autoComplete="current-password"
                            size="small"
                            onChange={(e) => updateForm({ cpassword: e.target.value })}
                            inputProps={{
                                minLength: 8, // Set the minimum length to 8 characters
                                maxLength: 10, // Set the maximum length to 10 characters
                            }}
                        />
                    </Grid>
                    <Grid container>
                        <Grid item sx={{ mb: 3 }}>
                            <Button
                                type="Button"
                                variant="contained"
                                sx={{
                                    marginTop: 2,
                                    marginLeft: 1,
                                    backgroundColor: '#213555 !important',
                                    fontColor: 'black'
                                }}
                                onClick={() => navigate("/")}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item sx={{ mb: 3 }}>
                            <Button
                                type="Button"

                                variant="contained"
                                sx={{
                                    marginTop: 2,
                                    marginLeft: 3,
                                    backgroundColor: ' #213555!important',
                                    fontColor: 'black'
                                }}
                                onClick={onSubmit}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    )
}
export default ResetPassword